import type { AssetFilterOptions } from 'modules/analytics/components/AssetFilter/types';
import type { FilterObject } from 'modules/common-ui/components/Filter/FilterObject.type';
import { createContext, useCallback, useContext, useState } from 'react';

const initialFilters = {
  displayActiveFiltersOverview: false,
  setDisplayActiveFiltersOverview: (_display: boolean) => {},
  setPersistedFilters: (_filters: PersistedFilters) => {},
  getPersistedFilters: (): AssetFilterOptions => ({}),
  getSelectedSegmentId: () => null,
  isDefaultContext: true,
};

type PersistedFilters = {
  filters: AssetFilterOptions;
  selectedSegmentId: number | null;
};

// Keep in mind that filters are workspace-dependent. Today, changing the workspace reloads the page.
// This causes the context to reset but if we change this behavior, we'll need to manage filters lifecycle.
const PersistentReportFiltersContext = createContext<{
  displayActiveFiltersOverview: boolean;
  setDisplayActiveFiltersOverview: (display: boolean) => void;
  setPersistedFilters: (filterCtx: PersistedFilters) => void;
  getPersistedFilters: () => AssetFilterOptions;
  getSelectedSegmentId: () => number | null;
  isDefaultContext: boolean;
}>(initialFilters);

export const PersistentReportFiltersProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [persistedFilters, setPersistedFilters] = useState<PersistedFilters>({
    filters: {},
    selectedSegmentId: null,
  });

  const getPersistedFilters = useCallback(() => {
    return persistedFilters.filters;
  }, [persistedFilters]);

  const getSelectedSegmentId = useCallback(() => {
    return persistedFilters.selectedSegmentId;
  }, [persistedFilters]);

  const [displayActiveFiltersOverview, setDisplayActiveFiltersOverview] =
    useState(false);

  return (
    <PersistentReportFiltersContext.Provider
      value={{
        setPersistedFilters,
        getPersistedFilters,
        displayActiveFiltersOverview,
        setDisplayActiveFiltersOverview,
        getSelectedSegmentId,
        isDefaultContext: false,
      }}
    >
      {children}
    </PersistentReportFiltersContext.Provider>
  );
};

export const usePersistedReportFilters = () => {
  return useContext(PersistentReportFiltersContext);
};

export const filterAssetFilters = (
  assetFilters: AssetFilterOptions,
  filterOptions: FilterObject[],
) => {
  const availableOptionFilters = filterOptions.map((f) => f.type);
  // Action bar needs to have period as a filter option
  const availableFilters = availableOptionFilters.concat(['period']);
  const filteredFilters = { ...assetFilters };
  for (const [key] of Object.entries(assetFilters)) {
    const k = key as keyof AssetFilterOptions;
    if (!availableFilters.includes(key)) {
      delete filteredFilters[k];
    }
  }
  return filteredFilters;
};
