import { ShareIcon } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { NavItem } from '../NavItem';

export const NavigationToSharingCenter = () => {
  const [t] = useTranslation('sidenav');

  return (
    <NavItem
      label={t('sidenav.footer.sharing')}
      link="/sharing"
      testId="navigation-sharing"
      id="navigation-sharing"
      icon={ShareIcon}
    />
  );
};
