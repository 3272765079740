import colors from 'constants/colors';

export type ExclamationMarkProps = {
  size?: number;
  color?: string;
};

export const ExclamationMark = ({
  size = 16,
  color = colors.gray850,
}: ExclamationMarkProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00001 14C10.866 14 14 10.866 14 7.00001C14 3.13401 10.866 0 7.00001 0C3.13401 0 0 3.13401 0 7.00001C0 10.866 3.13401 14 7.00001 14ZM6.9534 3.50001C6.65407 3.50001 6.41141 3.74267 6.41141 4.04201L6.41141 6.09895C6.41141 6.39829 6.65406 6.64095 6.9534 6.64095C7.25273 6.64095 7.49539 6.39829 7.49539 6.09895L7.49539 4.04201C7.49539 3.74267 7.25273 3.50001 6.9534 3.50001ZM7.42508 8.76833C7.53055 8.64721 7.58328 8.49875 7.58328 8.32293C7.58328 8.14711 7.53055 8.0006 7.42508 7.88339C7.3157 7.76618 7.15555 7.70757 6.94461 7.70757C6.73367 7.70757 6.57352 7.76618 6.46414 7.88339C6.35477 8.0006 6.30008 8.14711 6.30008 8.32293C6.30008 8.49875 6.35477 8.64721 6.46414 8.76833C6.57352 8.88945 6.73367 8.95001 6.94461 8.95001C7.15555 8.95001 7.3157 8.88945 7.42508 8.76833Z"
      fill={color}
    />
  </svg>
);
