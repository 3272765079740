import type { TFunction } from 'i18next';
import { convertUnitAsString } from 'utils/unit';

// The below commented line is here for i18next-parser
// t('assets:energyTypes.off_road_fuel.label')
// t('assets:energyTypes.off_road_fuel.helper')
// t('assets:energyTypes.compressed_natural_gas.label')
// t('assets:energyTypes.compressed_natural_gas.helper')
// t('assets:energyTypes.bio_compressed_natural_gas.label')
// t('assets:energyTypes.bio_compressed_natural_gas.helper')
// t('assets:energyTypes.liquefied_natural_gas.label')
// t('assets:energyTypes.liquefied_natural_gas.helper')
// t('assets:energyTypes.liquefied_petrol_gas.label')
// t('assets:energyTypes.liquefied_petrol_gas.helper')
// t('assets:energyTypes.unleaded_fuel.label')
// t('assets:energyTypes.unleaded_fuel.helper')
// t('assets:energyTypes.e10_fuel.label')
// t('assets:energyTypes.e10_fuel.helper')
// t('assets:energyTypes.e85_fuel.label')
// t('assets:energyTypes.e85_fuel.helper')
// t('assets:energyTypes.bio_ed95.label')
// t('assets:energyTypes.bio_ed95.helper')
// t('assets:energyTypes.regular_fuel.label')
// t('assets:energyTypes.regular_fuel.helper')
// t('assets:energyTypes.regular_fuel_b100.label')
// t('assets:energyTypes.regular_fuel_b100.helper')
// t('assets:energyTypes.regular_fuel_b30.label')
// t('assets:energyTypes.regular_fuel_b30.helper')
// t('assets:energyTypes.hvo100.label')
// t('assets:energyTypes.hvo100.helper')
// t('assets:energyTypes.electricity.label')
// t('assets:energyTypes.electricity.helper')
// t('assets:energyTypes.not_applicable.label')
// t('assets:energyTypes.not_applicable.helper')
export function generateEnergyTypeTitle(t: TFunction, code: string) {
  return t(`assets:energyTypes.${code}.label`);
}

export function generateEnergyTypeSubTitle(
  t: TFunction,
  code: string,
  emissionFactor: number | null,
) {
  return t(`assets:energyTypes.${code}.helper`, {
    emissionFactor: convertUnitAsString(emissionFactor, null, 2, false),
  });
}
