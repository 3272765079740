/* eslint-disable */
function debounce(a, b, c) {
  let d;
  let e;
  return function () {
    function h() {
      // biome-ignore lint/suspicious/noAssignInExpressions: legacy
      // biome-ignore lint/style/noCommaOperator: <explanation>
      (d = null), c || (e = a.apply(f, g));
    }
    const f = this;
    // biome-ignore lint/style/noArguments: legacy
    const g = arguments;
    return (
      // biome-ignore lint/suspicious/noAssignInExpressions: legacy
      // biome-ignore lint/style/noCommaOperator: <explanation>
      clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e
    );
  };
}

export default debounce;
