import { useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';

import colors from 'constants/colors';
import {
  DropdownMenuItem,
  DropdownMenuWrapper,
  EllipsisIcon,
} from 'modules/common-ui';

import { usePortal } from './usePortal';

const ActionsListContainer = styled(DropdownMenuWrapper)`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 207px;
  z-index: 1;
  padding: 8px 0;
`;

const ActionsListItem = styled(DropdownMenuItem)<{
  variant?: 'danger' | 'primary';
  padding?: string;
  lineHeight?: string;
}>`
  align-items: center;
  background: ${colors.white};
  color: ${(props) =>
    props.variant === 'danger' ? colors.red300 : colors.gray850};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  ${(props) =>
    props.padding ? `padding: ${props.padding};` : 'padding: 8px 12px;'}
  ${(props) =>
    props.lineHeight
      ? `line-height: ${props.lineHeight};`
      : 'line-height: 18px;'}
  transition: background 120ms ease-in 0s;
`;

export type Action = {
  key: string;
  label: string;
  onClick: () => void;
  danger?: boolean;
  lineHeight?: string;
  padding?: string;
};

type ActionsListProps = {
  actions: Action[];
};

const ActionsList = ({ actions }: ActionsListProps) => {
  return (
    <ActionsListContainer>
      {actions.map((action) => (
        <ActionsListItem
          key={action.key}
          onClick={action.onClick}
          variant={action.danger ? 'danger' : 'primary'}
          lineHeight={action.lineHeight}
          padding={action.padding}
        >
          {action.label}
        </ActionsListItem>
      ))}
    </ActionsListContainer>
  );
};

type ActionsCellV2Props = {
  actions: Action[];
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
};

export const ActionsCellV2 = ({
  onClose,
  onOpen,
  isOpen,
  actions,
}: ActionsCellV2Props) => {
  const renderActions = useCallback(() => {
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          onClose();
        }}
      >
        <ActionsList actions={actions} />
      </OutsideClickHandler>
    );
  }, [onClose, actions]);

  const { setReferenceElement, renderPortal } = usePortal(renderActions);

  return (
    <div style={{ position: 'relative' }}>
      <div
        onClick={() => {
          onOpen();
        }}
        ref={setReferenceElement}
      >
        <EllipsisIcon />
      </div>
      {isOpen && renderPortal()}
    </div>
  );
};
