import colors from 'constants/colors';

export type OnboardingIconProps = {
  size?: number;
  color?: string;
};

export const OnboardingIcon = ({
  size = 16,
  color = colors.gray500,
}: OnboardingIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0001 5.80951V10.1426C13.0001 10.9382 12.684 11.7013 12.1215 12.2639C11.5589 12.8264 10.7959 13.1425 10.0002 13.1425C9.20463 13.1425 8.4416 12.8264 7.87902 12.2639C7.31644 11.7013 7.00038 10.9382 7.00038 10.1426V5.47619C7.00038 5.03418 6.8248 4.61028 6.51225 4.29773C6.19971 3.98519 5.7758 3.8096 5.3338 3.8096C4.89179 3.8096 4.46789 3.98519 4.15534 4.29773C3.8428 4.61028 3.66721 5.03418 3.66721 5.47619V9.92264C4.11218 10.0799 4.48723 10.3894 4.72607 10.7965C4.9649 11.2035 5.05214 11.6819 4.97236 12.1471C4.89258 12.6122 4.65092 13.0342 4.2901 13.3384C3.92927 13.6426 3.47252 13.8094 3.00058 13.8094C2.52863 13.8094 2.07188 13.6426 1.71106 13.3384C1.35023 13.0342 1.10858 12.6122 1.0288 12.1471C0.949017 11.6819 1.03625 11.2035 1.27509 10.7965C1.51392 10.3894 1.88897 10.0799 2.33394 9.92264V5.47619C2.33394 4.68058 2.65 3.91755 3.21258 3.35497C3.77516 2.79239 4.53819 2.47633 5.3338 2.47633C6.12941 2.47633 6.89243 2.79239 7.45502 3.35497C8.0176 3.91755 8.33365 4.68058 8.33365 5.47619V10.1426C8.33365 10.5846 8.50924 11.0085 8.82179 11.3211C9.13433 11.6336 9.55823 11.8092 10.0002 11.8092C10.4422 11.8092 10.8661 11.6336 11.1787 11.3211C11.4912 11.0085 11.6668 10.5846 11.6668 10.1426V5.80951H9.66692L12.3335 2.47633L15 5.80951H13.0001ZM3.00058 11.1426C2.82377 11.1426 2.65421 11.2128 2.52919 11.3378C2.40418 11.4629 2.33394 11.6324 2.33394 11.8092C2.33394 11.986 2.40418 12.1556 2.52919 12.2806C2.65421 12.4056 2.82377 12.4759 3.00058 12.4759C3.17738 12.4759 3.34694 12.4056 3.47196 12.2806C3.59698 12.1556 3.66721 11.986 3.66721 11.8092C3.66721 11.6324 3.59698 11.4629 3.47196 11.3378C3.34694 11.2128 3.17738 11.1426 3.00058 11.1426Z"
      fill={color}
    />
  </svg>
);
