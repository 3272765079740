import colors from 'constants/colors';
import { Tooltip } from 'modules/common-ui/components/Tooltip';
import type { TooltipProps } from 'modules/common-ui/components/Tooltip/Tooltip.props';
import type { ReactNode } from 'react';
import styled from 'styled-components';

const TooltipContent = styled.div`
  color: ${colors.gray50};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

type CustomTooltipProps = {
  tooltipId: string;
  tooltip: string | JSX.Element | ReactNode | undefined;
  place?: 'top' | 'bottom' | 'left' | 'right';
};

export const CustomTooltip = ({
  tooltipId,
  tooltip,
  place,
  ...props
}: CustomTooltipProps & TooltipProps) => {
  return (
    <Tooltip
      id={tooltipId}
      place={place || 'bottom'}
      background={colors.gray850}
      padding={8}
      opacity={1}
      {...props}
    >
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};
