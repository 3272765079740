import { useId } from 'react';
import { FatCrossIcon } from '../Icons';
import { Tooltip } from '../Tooltip';
import { TAG_COLORS, type TagColors } from './colors';
import { StyledCross, StyledIcon, StyledLabel, StyledTag } from './styles';

export type TagProps<T> = {
  id: T;
  label: string;
  small?: boolean;
  color?: TagColors;
  icon?: React.ReactNode;
  onDelete?: (id: T) => void;
  onClick?: (id: T) => void;
  suggested?: boolean;
  maxWidth?: number;
  withTooltip?: boolean;
  forwardLabelRef?: React.RefObject<HTMLDivElement>;
};

const DeleteOpts = <T,>({
  id,
  onClick,
}: {
  id: T;
  onClick: (id: T) => void;
}) => {
  return (
    <StyledCross
      onClick={(event) => {
        onClick(id);
        event.stopPropagation();
      }}
      onKeyUp={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick(id);
        }
      }}
    >
      <FatCrossIcon size={8} />
    </StyledCross>
  );
};

export const Tag = <T = undefined>({
  id,
  label,
  small,
  color = 'gray',
  onDelete,
  icon,
  onClick,
  suggested = false,
  maxWidth,
  withTooltip = false,
  forwardLabelRef: forwardRef,
}: TagProps<T>) => {
  const tooltipId = useId();

  return (
    <>
      {withTooltip && <Tooltip id={tooltipId} />}
      <StyledTag
        small={small}
        data-tip={label}
        data-for={tooltipId}
        backgroundColor={TAG_COLORS[color].background}
        borderColor={TAG_COLORS[color].border}
        onClick={() => {
          onClick?.(id);
        }}
        suggested={suggested}
        selectable={!!onClick}
        maxWidth={maxWidth}
      >
        {icon && <StyledIcon>{icon}</StyledIcon>}
        <StyledLabel ref={forwardRef} small={small}>
          {label}
        </StyledLabel>
        {onDelete && <DeleteOpts id={id} onClick={onDelete} />}
      </StyledTag>
    </>
  );
};
