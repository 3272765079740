import type { FetchApi } from '../client';
import type {
  GetGoodyearCredentialResponse,
  IntegrationApiGenericArgs,
} from './types';

export const integrationApi = (fetchApi: FetchApi) => ({
  getTrakmyToken: ({
    token,
    name,
  }: IntegrationApiGenericArgs): Promise<{
    data: any;
  }> => {
    return fetchApi
      .setToken(token)
      .post('/io/trakmy/generate-token', { name })
      .catch((error) => {
        const errorMessage = error?.message
          ? error.message
          : 'Unable to retrieve Trakmy Token';
        throw new Error(errorMessage);
      });
  },
  getGoodyearCredential: ({
    token,
    name,
  }: IntegrationApiGenericArgs): Promise<GetGoodyearCredentialResponse> => {
    return fetchApi
      .setToken(token)
      .post('/io/goodyear/generate-credential', { name })
      .catch((error) => {
        const errorMessage = error?.message
          ? error.message
          : 'Unable to generate Good Year credential';
        throw new Error(errorMessage);
      });
  },
});
