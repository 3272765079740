import colors from 'constants/colors';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 12px;
`;

export const OptionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 0px;
`;

export const CustomLink = styled.a`
  text-decoration: underline;
  color: ${colors.gray500};

  &:hover {
    color: ${colors.gray500};
  }
`;

export const CheckIconWrapper = styled.div<{ visible?: boolean }>`
  padding-top: 4px;
  ${(props) => props.visible === false && 'visibility: hidden'}
`;

export const RolePickerLabel = styled.div<{ isDanger?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  ${(props) => props.isDanger && `color: ${colors.red500}`};
`;

export const HelperText = styled.div`
  color: ${colors.gray500};
  font-size: 12px;
  line-height: 16px;
`;

export const RemoveMemberOption = styled.div`
  color: ${colors.red300};
  font-size: 14px;
  line-height: 18px;
`;
