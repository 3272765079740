export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function ellipsis(s, length) {
  if (typeof s !== 'string') return '';

  if (!s || !length || s.length <= length) return s;

  const newStr = s.slice(0, length);
  return `${newStr}...`;
}
