import type React from 'react';

import { Button, HModal } from 'modules/common-ui';

import { CallOut } from '../CallOut/CallOut';
import { Footer } from './index.css';

type HModalProps = {
  isOpen: boolean;
  title?: string;
  basicContent?: React.ReactNode;
  importantText?: string;
  inputContent?: React.ReactNode;
  onConfirmClick: () => void;
  confirmText: React.ReactNode;
  cancelText: React.ReactNode;
  onCancel: () => void;
  confirmDisabled?: boolean;
};

export const HModalWarning = (props: HModalProps) => {
  const {
    isOpen,
    title,
    basicContent,
    importantText,
    inputContent,
    onConfirmClick,
    confirmText,
    cancelText,
    onCancel,
    confirmDisabled,
  } = props;

  return (
    <HModal
      isOpen={isOpen}
      onRequestClose={onCancel}
      style={{ width: '480px' }}
    >
      {!!title && <HModal.Header>{title}</HModal.Header>}
      <HModal.Body>
        {basicContent}
        {!!importantText && (
          <CallOut
            text={importantText}
            marginTop={16}
            marginBottom={16}
            fontSize={14}
            fullWidth
          />
        )}
        {inputContent}
      </HModal.Body>
      <Footer>
        <Button
          variant="outline-danger"
          block
          onClick={onConfirmClick}
          disabled={confirmDisabled}
        >
          {confirmText}
        </Button>
        <Button variant="outline-neutral" block onClick={onCancel}>
          {cancelText}
        </Button>
      </Footer>
    </HModal>
  );
};
