import { MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import FaAngleUp from 'react-icons/lib/fa/angle-up';
import { Button } from '../Button';
import { StyledMenu } from './index.css';

type ItemType = {
  label: string;
  onClick: () => void;
};

export type MenuProps = {
  buttonLabel?: string;
  items: ItemType[];
};

export const Menu = ({ buttonLabel, items: actions }: MenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation('commonUi');
  const open = Boolean(anchorEl);

  const handleClick = (event?: React.MouseEvent): void => {
    if (event && event.currentTarget instanceof HTMLElement) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="menu-toggle-button"
        variant="neutral"
        onClick={handleClick}
        aria-controls={open ? anchorEl?.id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        iconRight={open ? <FaAngleUp /> : <FaAngleDown />}
      >
        {buttonLabel ? buttonLabel : t('menu.more')}
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-toggle-button',
        }}
      >
        {actions.map((action, index) => (
          <MenuItem
            key={index.toString()}
            onClick={() => {
              action.onClick();
              handleClose();
            }}
          >
            {action.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};
