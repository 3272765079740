import colors from 'constants/colors';
import type React from 'react';

export type ExportIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
};

export const ExportIcon = ({
  size,
  color = colors.gray850,
  style,
}: ExportIconProps) => (
  <svg
    width={size || 16}
    height={size || 17}
    viewBox="0 0 16 17"
    fill={color}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.98535 10.1808V3.11087"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.78125 6.31562L7.98597 3.1109"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.1896 6.31559L7.98486 3.11087"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.35948 13.4494H12.6406"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
