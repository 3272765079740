import type { GraphQLAssetHeader } from '../types';

export type ActionType = {
  type: string;
  data: any;
};

export const ASSET_HEADER = {
  RECEIVED_DATA: 'ASSET_HEADER_RECEIVED_DATA',
  UPDATE_CACHE: 'ASSET_HEADER_UPDATE_CACHE',
};

export function receivedData(data: GraphQLAssetHeader) {
  return {
    type: ASSET_HEADER.RECEIVED_DATA,
    data,
  };
}
export function updateCache(data: any) {
  return {
    type: ASSET_HEADER.UPDATE_CACHE,
    data,
  };
}
