import { FLAGS } from 'constants/flags';
import { useDisplayNotification } from 'modules/common-ui/hooks/notifications/useDisplayNotification';
import { useFeature } from 'modules/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useListUnreadNotification } from './hooks/useListUnreadNotification';
import { useMarkNotificationAsRead } from './hooks/useMarkNotificationAsRead';
import { parseNotification } from './services/NotificationMapper';

export const NotificationManager: React.FC = () => {
  const { t } = useTranslation('notifications');
  const { notifications } = useListUnreadNotification();
  const markNotificationAsRead = useMarkNotificationAsRead();
  const displayNotification = useDisplayNotification();
  const [exportCenterEnabled, flagLoading] = useFeature<boolean>(
    FLAGS.ANALYTICS_EXPORT_CENTER,
  );

  useEffect(() => {
    if (flagLoading || !exportCenterEnabled) {
      return;
    }

    for (const notification of notifications) {
      const parsedNotification = parseNotification(notification, t);
      parsedNotification &&
        displayNotification({
          ...parsedNotification,
          onClick: () => markNotificationAsRead(notification),
        });
    }
  }, [
    notifications,
    displayNotification,
    t,
    flagLoading,
    exportCenterEnabled,
    markNotificationAsRead,
  ]);

  // nothing to display on the interface
  return null;
};
