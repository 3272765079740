import { fromJS } from 'immutable';
import moment from 'moment';

import { PeriodOptions } from 'modules/common-ui';

import { type ActionType, FAULTS_REPORT } from '../actions';

export const defaultFilters = {
  period: {
    label: PeriodOptions.lastMonth.label,
    value: PeriodOptions.lastMonth.value,
    since: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    until: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
  },
};

const initialState = fromJS({
  group: {},
  totalCount: null,
  limit: 25,
  offset: 0,
  search: '',
  sorted: [{ id: 'date', desc: true }],
  categories: [],
  makers: [],
  faultSeverities: [],
  filters: defaultFilters,
  visibleColumns: [
    'name',
    'model',
    'categoryName',
    'label',
    'date',
    'level',
    'description',
    'lastPosition',
  ],
});

export default function EquipmentFaultsReducer(
  // biome-ignore lint/style/useDefaultParameterLast: legacy
  state = initialState,
  action: ActionType,
) {
  switch (action.type) {
    case FAULTS_REPORT.FILTER: {
      return state.set('offset', 0).set('filters', fromJS(action.filters));
    }

    case FAULTS_REPORT.RESET_FILTERS: {
      return state.set('offset', 0).set('filters', fromJS(defaultFilters));
    }

    case FAULTS_REPORT.SET_VISIBLE_COLUMNS: {
      return state.set('visibleColumns', fromJS(action.columns));
    }

    default:
      return state;
  }
}
