import colors from 'constants/colors';

type Theme = {
  typology: {
    font: {
      family: string;
      size: {
        xxs: string;
        xs: string;
        s: string;
        m: string;
        l: string;
        xl: string;
      };
      weight: {
        regular: number;
        medium: number;
        semibold: number;
      };
    };
    lineHeight: {
      reset: number;
      small: number;
      base: number;
      large: number;
      xlarge: number;
    };
    letterSpacing: {
      neg3: string;
      neg2: string;
      neg1: string;
      1: string;
      2: string;
      3: string;
    };
  };
  colors: {
    primary: {
      primary500: string;
    };
    neutral: {
      neutral50: string;
      neutral100: string;
      neutral200: string;
      neutral300: string;
      neutral400: string;
      neutral500: string;
      neutral600: string;
      neutral700: string;
      neutral800: string;
      neutral850: string;
      neutral900: string;
    };
  };
};

export const hiboo: Theme = {
  typology: {
    font: {
      family: 'inter',
      size: {
        xxs: '0.6875rem', // 11px
        xs: '0.75rem', // 12px
        s: '0.875rem', // 14px
        m: '1rem', // 16px
        l: '1.25rem', // 20px
        xl: '1.5rem', // 28px
      },
      weight: {
        regular: 400,
        medium: 500,
        semibold: 600,
      },
    },
    lineHeight: {
      reset: 1,
      small: 1.33,
      base: 1.5,
      large: 1.66,
      xlarge: 2,
    },
    letterSpacing: {
      neg3: '-0.03rem',
      neg2: '-0.02rem',
      neg1: '-0.01rem',
      1: '0.025rem',
      2: '0.1rem',
      3: '0.15rem',
    },
  },
  colors: {
    primary: {
      primary500: colors.blue400,
    },
    neutral: {
      neutral50: colors.gray50,
      neutral100: colors.gray100,
      neutral200: colors.neutral200,
      neutral300: colors.neutral300,
      neutral400: colors.gray400,
      neutral500: colors.gray500,
      neutral600: colors.gray600,
      neutral700: colors.gray700,
      neutral800: colors.gray800,
      neutral850: colors.neutral850,
      neutral900: colors.gray900,
    },
  },
};
