import type React from 'react';
import { createContext, useContext, useReducer } from 'react';

type FeatureFlagsType = Record<string, any>;

const initialFeatureFlags: FeatureFlagsType = {};

const FeatureFlagsContext = createContext(initialFeatureFlags);

const FeatureFlagsDispatchContext = createContext<React.Dispatch<any>>(
  () => null,
);

export function FeatureFlagsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [featureFlags, dispatch] = useReducer(
    featureFlagsReducer,
    initialFeatureFlags,
  );

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      <FeatureFlagsDispatchContext.Provider value={dispatch}>
        {children}
      </FeatureFlagsDispatchContext.Provider>
    </FeatureFlagsContext.Provider>
  );
}

export enum FeatureFlagsActionTypes {
  LOAD_FEATURE_FLAGS = 'LOAD_FEATURE_FLAGS',
  SET_FEATURE_FLAG = 'SET_FEATURE_FLAG',
}

function featureFlagsReducer(
  featureFlags: FeatureFlagsType,
  action: {
    type: FeatureFlagsActionTypes;
    payload: FeatureFlagsType;
  },
) {
  switch (action.type) {
    case FeatureFlagsActionTypes.LOAD_FEATURE_FLAGS: {
      return { ...action.payload };
    }
    case FeatureFlagsActionTypes.SET_FEATURE_FLAG: {
      return {
        ...featureFlags,
        ...action.payload,
      };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}

export function useFeatureFlagsDispatch() {
  return useContext(FeatureFlagsDispatchContext);
}
