import colors from 'constants/colors';
import { Button, HibooLogoLarge, RefreshIcon } from 'modules/common-ui';
import Layout from 'modules/layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ErrorTitle,
  HelpMessage,
  LogoContainer,
  MainContainer,
  OwlImage,
} from './styles.css';

export const ErrorPage = () => {
  const { t } = useTranslation('applicationError');
  const navigate = useNavigate();
  const refreshPage = () => {
    navigate(0);
  };

  return (
    <Layout.Root>
      <LogoContainer>
        <HibooLogoLarge />
      </LogoContainer>
      <Layout.PageContainer>
        <MainContainer>
          <OwlImage src="/images/hoowhat-error.gif" alt="error" />
          <ErrorTitle>{t('title')}</ErrorTitle>
          <HelpMessage>
            {t('description')} <br />
            {t('reload')}
          </HelpMessage>
          <Button
            variant="primary"
            onClick={refreshPage}
            iconRight={<RefreshIcon color={colors.white} />}
          >
            {t('reloadButton')}
          </Button>
        </MainContainer>
      </Layout.PageContainer>
    </Layout.Root>
  );
};
