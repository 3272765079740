import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';

import { TextInput } from 'modules/common-ui';

import type { SearchPropTypes } from './types';

export const Search: React.FC<SearchPropTypes> = ({
  disabled = false,
  fullWidth = false,
  searchWidth,
  onSearch,
  placeholder,
  searchValue = '',
  testId = 'table-global-search-input',
  totalRows,
  tracking = null,
}) => {
  const { t } = useTranslation('tableV7');
  const [value, setValue] = useState(searchValue);

  const onChange = useAsyncDebounce((value) => {
    if (tracking) {
      analytics.track(`${tracking}.search`, {
        searchQuery: value,
      });
    }

    onSearch(value || undefined);
  }, 200);
  const placeholderText = placeholder
    ? placeholder
    : totalRows
      ? t('search.placeholder', { count: totalRows })
      : '';
  return (
    <TextInput
      data-testid={testId}
      fullWidth={fullWidth}
      searchWidth={searchWidth}
      large
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={placeholderText}
      aria-label={placeholderText}
      variant="secondary"
      disabled={disabled}
      icon="search"
      onReset={() => {
        setValue('');
        onChange('');
      }}
    />
  );
};
