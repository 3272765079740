import colors from 'constants/colors';
import { getCommonTheme } from 'modules/common-ui/styles/Select/commonStyle';
import ReactSelect, { type SingleValueProps, components } from 'react-select';
import { CheckIcon } from '../../Icons';
import {
  CheckIconWrapper,
  OptionWrapper,
  buildCustomStyle,
} from '../index.css';
import {
  ClearIndicator,
  DropdownIndicator,
  IndicatorsContainer,
} from './ReactSelectOverrides';
import type { SingleSelectOption } from './SingleSelectOption.type';

export const withReactSelectSingleValue = <T extends SingleSelectOption>(
  Wrapped: (props: T) => JSX.Element,
) => {
  return function SingleValue(props: SingleValueProps<T>) {
    return (
      <components.SingleValue {...props}>
        <Wrapped {...(props.data as T)} />
      </components.SingleValue>
    );
  };
};

export type SingleSelectProps<T extends SingleSelectOption> = {
  id: string;
  options: T[];
  value?: T | T[] | null;
  formatOption: (option: T) => JSX.Element;
  formatValue?: (option: T) => JSX.Element;
  onSelect?: (obj: T) => void;
  onClear?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  config?: {
    isSearchable?: boolean;
    isClearable?: boolean;
    closeMenuOnSelect?: boolean;
  };
  hasError?: boolean;
};

export const SingleSelect = <T extends SingleSelectOption>({
  id,
  options,
  value,
  config = {
    isSearchable: true,
    isClearable: false,
    closeMenuOnSelect: true,
  },
  placeholder,
  formatOption,
  formatValue,
  onBlur,
  onSelect,
  onClear,
  hasError,
}: SingleSelectProps<T>) => {
  const withSelectedCheckFormatOption = (option: T) => {
    const selected = !!(value && option.value === (value as T).value);

    return (
      <OptionWrapper>
        {formatOption(option)}
        <CheckIconWrapper visible={selected}>
          <CheckIcon color={colors.gray850} />
        </CheckIconWrapper>
      </OptionWrapper>
    );
  };

  return (
    <ReactSelect<T, boolean>
      id={id}
      styles={buildCustomStyle({ hasError })}
      options={options}
      closeMenuOnSelect={config.closeMenuOnSelect}
      isClearable={config.isClearable}
      isSearchable={config.isSearchable}
      value={value}
      onChange={(selectedOption, actionMeta) => {
        if (actionMeta.action === 'clear' && onClear) return onClear();
        return onSelect ? onSelect(selectedOption as T) : null;
      }}
      onBlur={onBlur}
      formatOptionLabel={withSelectedCheckFormatOption}
      components={{
        IndicatorsContainer: IndicatorsContainer,
        ClearIndicator: ClearIndicator,
        DropdownIndicator: DropdownIndicator,
        SingleValue: withReactSelectSingleValue(formatValue || formatOption),
      }}
      hideSelectedOptions={false}
      theme={getCommonTheme}
      placeholder={placeholder}
      isMulti={false}
    />
  );
};
