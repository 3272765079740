import { EllipsisIconWrapper } from './index.css';

export type EllipsisIconProps = {
  size?: number;
  color?: string;
  className?: string;
  active?: boolean;
  onClick?: () => void;
};

export const EllipsisIcon = ({
  size,
  color,
  active,
  className = '',
  onClick,
}: EllipsisIconProps) => (
  <EllipsisIconWrapper onClick={onClick ? onClick : () => {}} active={active}>
    <svg
      className={className}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle' }}
    >
      <rect
        x="4.5"
        y="10.5"
        width="3"
        height="3"
        rx="1.5"
        fill={color || '#0A0A0C'}
        stroke="none"
      />
      <rect
        x="10.5"
        y="10.5"
        width="3"
        height="3"
        rx="1.5"
        fill={color || '#0A0A0C'}
        stroke="none"
      />
      <rect
        x="16.5"
        y="10.5"
        width="3"
        height="3"
        rx="1.5"
        fill={color || '#0A0A0C'}
        stroke="none"
      />
    </svg>
  </EllipsisIconWrapper>
);
