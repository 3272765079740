import { CircleIcon } from '../Icons';
import {
  MetricContainer,
  MetricSubtitle,
  MetricTitle,
  MetricWrapper,
} from './style.css';

export type MetricStatusProps = {
  color?: string;
  title: string;
  subtitle?: string;
  size?: 'small' | 'big';
};

export const MetricStatus = ({
  color,
  title,
  subtitle,
  size,
}: MetricStatusProps) => {
  return (
    <MetricContainer size={size}>
      <MetricWrapper>
        {color && <CircleIcon color={color} fill={color} size={8} />}
        <MetricTitle>{title}</MetricTitle>
      </MetricWrapper>
      {subtitle && <MetricSubtitle>{subtitle}</MetricSubtitle>}
    </MetricContainer>
  );
};
