import colors from 'constants/colors';
import fontSizes from 'constants/fontSizes';
import styled from 'styled-components';

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  align-items: left;
  text-align: left;
  font-family: Inter;
  padding-right: 16px;
`;

export const NotificationCoreContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NotificationTimeAgo = styled.div`

  font-size: ${fontSizes.other.caption};
  color: ${colors.gray500};
  line-height: 16px;
`;

export const NotificationCloseButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`;

export const NotificationTitle = styled.div`
  font-size: ${fontSizes.headings.h5};
  font-weight: 500;
  line-height: 18px;
`;

export const NotificationContent = styled.div`
  font-size: ${fontSizes.body.short01};
  font-weight: 400;
`;
