import type { AssetFound } from 'modules/global-search/models/AssetFound.model';
import type { ZoneFound } from 'modules/global-search/models/ZoneFound.model';
import { useEffect, useState } from 'react';
import { globalSearchResultsToEntities } from '../dto/globalSearchResultsToEntities';
import { useQueryGlobalSearchQuery } from './gql.generated';

export const useGetGlobalSearch = (
  debouncedValue: string,
  limit: number,
  skip: boolean,
) => {
  const [results, setResults] = useState<Array<AssetFound | ZoneFound>>([]);

  const { data, refetch, loading } = useQueryGlobalSearchQuery({
    fetchPolicy: 'no-cache',
    skip: skip || debouncedValue === '',
    variables: {
      text: debouncedValue,
      limit,
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    const globalSearchResults = data?.viewer?.workspace?.globalSearch || [];
    setResults(globalSearchResultsToEntities(globalSearchResults));
    return;
  }, [data]);

  return { results, refetch, loading };
};
