import colors from 'constants/colors';

export type TagColors = keyof typeof TAG_COLORS;

export const TAG_COLORS = {
  gray: { background: colors.gray100, border: colors.gray400 },
  red: { background: colors.red100, border: colors.red400 },
  deepRed: { background: colors.red200, border: colors.red400 },
  pink: { background: colors.pink100, border: colors.pink500 },
  purple: { background: colors.purple100, border: colors.purple400 },
  blue: { background: colors.blue100, border: colors.blue400 },
  cyan: { background: colors.cyan100, border: colors.cyan400 },
  green: { background: colors.green100, border: colors.green400 },
  yellow: { background: colors.yellow100, border: colors.yellow400 },
  orange: { background: colors.orange100, border: colors.orange400 },
  brown: { background: colors.brown100, border: colors.brown500 },
};

export const getTagColorFromString = (color: string): TagColors => {
  const defaultColor: TagColors = 'gray';
  return (
    (Object.keys(TAG_COLORS).find(
      (tagColor) => tagColor === color,
    ) as TagColors) || defaultColor
  );
};
