import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useGetNotificationsQuery } from '../gql/gql.generated';
import { Notification } from '../models/Notification';

const POLL_INTERVAL = 10000;

const getSince = () => {
  return moment().subtract(1, 'hour').toISOString();
};

export const useListUnreadNotification = () => {
  const [since, setSince] = useState(getSince());

  const { data, loading } = useGetNotificationsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      since,
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setSince(getSince());
    }, POLL_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const notifications = useMemo(() => {
    return (
      data?.viewer?.notifications?.map(
        (notification) => new Notification(notification),
      ) || []
    );
  }, [data]);

  return {
    notifications,
    loading,
  };
};
