import colors from 'constants/colors';
import styled from 'styled-components';

export const WorkspacePickerButtonContainer = styled.div`
  position: relative;
  display: flex;
  height: 40px;
  margin-top: 6px;
  background: ${colors.gray100};
  border-top: 1px solid ${colors.gray200};
  border-bottom: 1px solid ${colors.gray200};
  align-items: center;
  justify-content: center;
`;

export const WorkspacePickerButtonContent = styled.div<{
  clickable?: boolean;
}>`
  display: flex;

  color: ${colors.gray850};
  padding-left: 16px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;

  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  min-width: 0;
  flex: 1;
  height: 100%;
  align-items: center;

  cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};
  :hover {
    ${(props) => (props.clickable ? `background:${colors.gray200};` : '')};
  }
  transition: background 0.1s;
`;

export const WorkspaceTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  flex: 0.88;
`;

export const ChevronContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const WorkspacePickerButtonRight = styled.div`
  right: 0px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  padding: 4px;

  border-radius: 5px;
  cursor: pointer;

  :hover {
    background: ${colors.gray200};
  }
  transition: background 0.1s;

  a {
    display: flex;
  }
`;
