const defaultConfig = {
  segmentTracking: true,
  SEGMENT_TOKEN: window.SEGMENT_TOKEN,
  sentryTracking: true,
  SENTRY_DSN: window.SENTRY_DSN,
  env: window.ENV,
  publicURL: window.PUBLIC_URL,
  apiURL: window.REACT_APP_API_URL,
  MIXPANEL_TOKEN: window.MIXPANEL_TOKEN,
};

export default { ...defaultConfig };
