import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { SearchAndLoadButton } from './SearchAndLoadButton';
import { SearchAndLoadOptions } from './SearchAndLoadOptions';
import type { SearchAndLoadMultiselectOption } from './types';

type SearchAndLoadMultiselectProps = {
  onUpdate: (value: any) => void;
  options: SearchAndLoadMultiselectOption[];
  values: SearchAndLoadMultiselectOption[];
};

export const SearchAndLoadMultiselect = ({
  options,
  values,
  onUpdate,
}: SearchAndLoadMultiselectProps) => {
  const [display, setDisplay] = useState(false);
  const { t } = useTranslation('commonUi');

  return (
    <div>
      <OutsideClickHandler
        onOutsideClick={() => setDisplay(false)}
        useCapture={false}
      >
        <SearchAndLoadButton
          values={values}
          placeholder={t('searchAndLoadMultiselect.button.placeholder')}
          onClick={() => setDisplay(!display)}
          optionsLength={options.length}
          maxItemDisplayed={1}
        />
        {display && (
          <SearchAndLoadOptions
            options={options}
            values={values}
            onUpdate={onUpdate}
          />
        )}
      </OutsideClickHandler>
    </div>
  );
};
