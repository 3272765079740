import colors from 'constants/colors';
import { TYPEFORM_FREEMIUM_LINKS } from 'constants/externalLinks';
import type { GraphQLUser } from 'modules/types/graphql';
import React from 'react';
import { useSelector } from 'react-redux';
import type { Store } from 'reducers';

import i18n from 'i18n';
import { ArrowUpIconFreemium } from 'modules/common-ui';

import type { FreemiumButtonProps } from './FreemiumButton.props';
import { FreemiumButtonWrapper, StyledButton } from './index.css';

export const FreemiumButton = React.forwardRef(
  (props: FreemiumButtonProps, ref: React.Ref<any>) => {
    const {
      id,
      freemiumTooltipButtonLabel,
      block = false,
      style,
      freeTrialLink = false,
      leftIcon = true,
      icon = <ArrowUpIconFreemium color={colors.purple400} />,
    } = props;

    const userLanguage = i18n.resolvedLanguage;
    const pricingLinkConstant =
      userLanguage === 'fr'
        ? TYPEFORM_FREEMIUM_LINKS.FREEMIUM_GET_IN_TOUCH_FR
        : TYPEFORM_FREEMIUM_LINKS.FREEMIUM_GET_IN_TOUCH_EN;
    const user = useSelector<Store>((state) => state.user) as GraphQLUser & {
      organizationName: string;
    };
    let pricingLink;

    if (user && pricingLinkConstant) {
      if (!freeTrialLink) {
        pricingLink = `${pricingLinkConstant}firstname=${user.firstName}&email=${user.email}&organization=${user.organizationName}`;
      } else {
        pricingLink = '/catalog';
      }
    }

    return (
      <FreemiumButtonWrapper style={style}>
        <a
          href={pricingLink}
          target={pricingLink === '/catalog' ? '_self' : '_blank'}
          rel="noopener noreferrer"
        >
          <StyledButton
            id={`${id}-freemium`}
            block={block}
            ref={ref}
            type="button"
            color={colors.purple500}
            style={style}
          >
            {leftIcon && icon}

            {freemiumTooltipButtonLabel}
          </StyledButton>
        </a>
      </FreemiumButtonWrapper>
    );
  },
);

FreemiumButton.displayName = 'Button';

export default FreemiumButton;
