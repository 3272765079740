type NotificationProperties = {
  id: number;
  type: string;
  message: string;
};

export class Notification {
  public readonly id: number;
  public readonly type: string;
  public readonly message: string;

  constructor(params: NotificationProperties) {
    this.id = params.id;
    this.type = params.type;
    this.message = params.message;
  }
}
