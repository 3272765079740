import type { Placement } from '@popperjs/core';
import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import colors from 'constants/colors';
import { CheckIcon, Drawer } from 'modules/common-ui';
import { usePositionedElement } from 'modules/common-ui/hooks/usePositionedElement';
import type React from 'react';
import { type Key, useRef, useState } from 'react';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import OutsideClickHandler from 'react-outside-click-handler';
import { useMediaQuery } from 'react-responsive';
import {
  Container,
  Ellipsis,
  IconContainer,
  Label,
  Left,
  Option,
  OptionContainer,
  Options,
  Right,
  Select,
} from './index.css';

export type DropdownOption<ValueType = any, ExtraParams = unknown> = {
  dataFor?: string | false;
  tooltip?: JSX.Element | false;
  label: string;
  value: ValueType;
  disabled?: boolean;
  selected?: boolean;
  variant?: 'default' | 'danger';
  renderOption?: (
    option: DropdownOption<ValueType, ExtraParams>,
  ) => JSX.Element;
} & ExtraParams;

export type DropdownProps<ValueType = any, ExtraParams = unknown> = {
  options: DropdownOption<ValueType, ExtraParams>[];
  value: ValueType | null;
  className?: string;
  placeholder?: string;
  onChange: (value: ValueType) => void;
  light?: boolean;
  variant?: 'default' | 'primary';
  selectStyle?: React.CSSProperties;
  menuWidth?: number;
  menuStyle?: React.CSSProperties;
  align?: 'left' | 'right';
  inputType?: boolean;
  ellipsis?: boolean;
  header?: string;
  useRenderOptionForSelect?: boolean;
  useRenderOptionForMenu?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  customSelector?: React.ReactNode;
  openTop?: boolean;
  placement?: Placement;
};

// eslint-disable-next-line max-statements
export const Dropdown = <
  ValueType extends Key,
  ExtraParams extends Object = {},
>({
  options,
  value,
  className,
  placeholder = '',
  onChange,
  light = false,
  variant = 'default',
  selectStyle = {},
  menuWidth,
  menuStyle = {},
  align = 'left',
  inputType = false,
  ellipsis = false,
  header = '',
  useRenderOptionForSelect = false,
  useRenderOptionForMenu = true,
  disabled,
  children,
  customSelector,
  openTop,
  placement = undefined,
}: DropdownProps<ValueType, ExtraParams>) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const [display, setDisplay] = useState(false);

  let text = placeholder;
  let renderedOption: JSX.Element | undefined = undefined;

  if (value !== null && value !== undefined) {
    const idx = options.findIndex((o) => o.value === value);
    if (idx > -1) {
      if (options[idx].renderOption) {
        renderedOption = options[idx].renderOption!(options[idx]);
      }
      text = options[idx].label;
    }
  }

  usePositionedElement(display, selectRef, dropdownRef, align, placement);

  const renderSelect = () => {
    const selected = (
      <Left>
        {ellipsis ? (
          <Ellipsis>{text}</Ellipsis>
        ) : useRenderOptionForSelect && renderedOption !== undefined ? (
          renderedOption
        ) : (
          text
        )}
      </Left>
    );

    const handleSelectClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      setDisplay(!display);
    };

    const hasText = text && text.length > 0;
    return (
      <Select
        ref={selectRef}
        light={light}
        variant={variant}
        className={className}
        onClick={disabled ? undefined : handleSelectClick}
        style={selectStyle}
        inputType={inputType}
        isFocused={display}
        disabled={disabled}
      >
        {hasText && selected}
        {customSelector ? (
          customSelector
        ) : (
          <Right>
            <FaAngleDown />
          </Right>
        )}
      </Select>
    );
  };

  if (isMobile) {
    return (
      <Container>
        {renderSelect()}
        <Drawer
          headerStyle={{ marginBottom: '20px' }}
          isOpen={display}
          onDone={() => setDisplay(false)}
          title={header}
        >
          {options.map((o, index) => (
            <Option
              key={index.toString()}
              onClick={(e) => {
                e.stopPropagation();
                setDisplay(false);
                onChange(o.value);
              }}
            >
              {useRenderOptionForMenu && o.renderOption ? (
                o.renderOption(o)
              ) : (
                <OptionContainer>
                  <Label variant={o.variant}> {o.label} </Label>
                  {o.selected && (
                    <IconContainer>
                      <CheckIcon color={colors.gray850} />
                    </IconContainer>
                  )}
                </OptionContainer>
              )}
            </Option>
          ))}
        </Drawer>
      </Container>
    );
  }

  const renderDropdown = () => {
    if (!display) return null;
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setDisplay(false);
        }}
      >
        <div ref={dropdownRef} style={{ zIndex: 1 }}>
          <Options
            width={menuWidth}
            style={{
              ...menuStyle,
            }}
            openTop={openTop}
          >
            {options.map((o, index) => (
              <div key={index.toString()}>
                {o.tooltip}
                <Option
                  data-for={o.dataFor}
                  data-tip=""
                  disabled={o.disabled}
                  key={o.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (o.disabled) return;
                    setDisplay(false);
                    onChange(o.value);
                  }}
                  variant={variant}
                  style={{ minHeight: !o.renderOption ? 28 : undefined }}
                >
                  {useRenderOptionForMenu && o.renderOption ? (
                    o.renderOption(o)
                  ) : (
                    <OptionContainer>
                      <Label variant={o.variant}> {o.label} </Label>
                      {o.selected && (
                        <IconContainer>
                          <CheckIcon color={colors.gray850} />
                        </IconContainer>
                      )}
                    </OptionContainer>
                  )}
                </Option>
              </div>
            ))}
            {children}
          </Options>
        </div>
      </OutsideClickHandler>
    );
  };

  return (
    <Container>
      {renderSelect()}
      {renderDropdown()}
    </Container>
  );
};
