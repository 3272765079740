import colors from 'constants/colors';

export type InventoryIconProps = {
  size?: number;
  color?: string;
};

export const InventoryIcon = ({
  size = 16,
  color = colors.gray500,
}: InventoryIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="3" width="10" height="1" rx="0.5" fill={color} />
    <rect x="13" y="3" width="2" height="1" rx="0.5" fill={color} />
    <rect x="13" y="6" width="2" height="1" rx="0.5" fill={color} />
    <rect x="13" y="9" width="2" height="1" rx="0.5" fill={color} />
    <rect x="13" y="12" width="2" height="1" rx="0.5" fill={color} />
    <rect x="1" y="6" width="10" height="1" rx="0.5" fill={color} />
    <rect x="1" y="12" width="10" height="1" rx="0.5" fill={color} />
    <rect x="1" y="9" width="10" height="1" rx="0.5" fill={color} />
  </svg>
);
