import colors from 'constants/colors';
import { Cross } from 'modules/common-ui/components/Icons';
import { components } from 'react-select';
import styled from 'styled-components';

const RoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 4px;

  &:hover {
    background-color: ${colors.gray300Hover};
    // background-color: ${({ theme }) => theme.colors.neutral.gray900};
  }
`;

const Indicator = (props: any) => (
  <components.ClearIndicator {...props}>
    <RoundContainer>
      <Cross />
    </RoundContainer>
  </components.ClearIndicator>
);

export const ClearIndicator = (props: any) => {
  const { children = <Indicator {...props} />, innerProps } = props;
  return <div {...innerProps}>{children}</div>;
};
