import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import type { Store } from 'reducers';

export const useOnboarding = () => {
  const hasOwnedAsset = useSelector<Store>((state) => {
    return state.user.hasOwnedAsset;
  });
  const [isOnboarding, setIsOnboarding] = useState<boolean>(
    !(hasOwnedAsset ?? true),
  );

  useEffect(() => {
    if (hasOwnedAsset === undefined) {
      setIsOnboarding(false);
      return;
    }

    setIsOnboarding(!hasOwnedAsset);

    return () => {
      setIsOnboarding(false);
    };
  }, [hasOwnedAsset]);

  return isOnboarding;
};
