import config from 'config';
import qs from 'qs';
import { createCache } from './cache';

type FetchApiOptions = {
  token?: string;
};

const host = config.apiURL;

const cache = createCache();

const generateEndpoint = (path: string) => `${host}${path}`;

const fetchOptions = (options: FetchApiOptions) => {
  const headers: { [key: string]: string } = {
    accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (options.token) {
    headers['x-access-token'] = options.token;
  }

  return {
    headers: new Headers(headers),
  };
};

const setToken = (token: string) => {
  return createFetchApi({ token: token });
};

const validateResponse = (response: Response) => {
  if (response.status >= 200 && response.status < 400) {
    return response.json().catch(() => null);
  }

  if (response.status === 401) {
    return response;
  }
  return response.json().then((r) => {
    throw r;
  });
};

const generateMethod =
  (method: string, options: FetchApiOptions) =>
  (path: string, payload?: { [key: string]: any }) => {
    if (payload) {
      const body = JSON.stringify(payload);
      return fetch(generateEndpoint(path), {
        method,
        body,
        ...fetchOptions(options),
      }).then(validateResponse);
    }
    return fetch(generateEndpoint(path), {
      method,
      ...fetchOptions(options),
    }).then(validateResponse);
  };

export type FetchApi = {
  generateEndpoint: (path: string) => string;
  cache: ReturnType<typeof createCache>;
  get: (
    path: string,
    querystring?: object,
    getOptions?: { cache?: boolean },
  ) => Promise<any>;
  postFile: (
    path: string,
    payload: { [key: string]: any },
    file: File,
  ) => Promise<any>;
  postFiles: (
    path: string,
    payload: { [key: string]: any },
    files: File[],
  ) => Promise<any>;
  post: ReturnType<typeof generateMethod>;
  put: ReturnType<typeof generateMethod>;
  patch: ReturnType<typeof generateMethod>;
  delete: ReturnType<typeof generateMethod>;
  setToken: (token: string) => FetchApi;
};

export const createFetchApi = (options: FetchApiOptions = {}): FetchApi => ({
  generateEndpoint,
  cache,
  get: (path, querystring, getOptions = { cache: true }) => {
    let query = '';
    if (querystring) {
      query = qs.stringify(querystring, { addQueryPrefix: true });
    }

    const cacheKey = `${path}${query}`;

    const url = `${host}${path}${query}`;

    if (getOptions.cache && cache.hasValidCache(cacheKey, 60)) {
      return cache.getCache(cacheKey);
    }

    const promise = fetch(url, {
      ...fetchOptions(options),
    }).then(validateResponse);

    cache.setCache(cacheKey, promise);
    return promise;
  },

  postFile: (path, payload, file) => {
    const formData = new FormData();
    formData.append('file', file);
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    const headers = new Headers();
    if (options.token) {
      headers.append('x-access-token', options.token);
    }
    return fetch(generateEndpoint(path), {
      method: 'POST',
      body: formData,
      headers,
    }).then(validateResponse);
  },

  postFiles: (path, payload, files) => {
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file);
    }
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    const headers = new Headers();
    if (options.token) {
      headers.append('x-access-token', options.token);
    }
    return fetch(generateEndpoint(path), {
      method: 'POST',
      body: formData,
      headers,
    }).then(validateResponse);
  },

  post: generateMethod('POST', options),
  put: generateMethod('PUT', options),
  patch: generateMethod('PATCH', options),
  delete: generateMethod('DELETE', options),
  setToken,
});
