import {
  AssetFound,
  AssetFoundStatus,
} from 'modules/global-search/models/AssetFound.model';
import { buildAssetSharingInfos } from 'modules/sharing/models/AssetSharingInfos/gql.fragment';
import type { AssetSearchFragment } from '../getGlobalSearch/gql.generated';

export const assetSearchResultToAssetFound = (row: AssetSearchFragment) => {
  const fields = {
    id: Number(row.id),
    name: row.name,
    make: row.make || '',
    model: row.model || '',
    status:
      row.status === 'ARCHIVED'
        ? AssetFoundStatus.ARCHIVED
        : AssetFoundStatus.ACTIVE,
    category: row.category?.id
      ? {
          id: Number(row.category.id),
          name: row.category.name || '',
          color: row.category.color || '',
        }
      : null,
    sharingInfos: buildAssetSharingInfos(row),
  };
  return new AssetFound(fields);
};
