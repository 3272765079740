import type {
  Integration,
  Organization,
  User,
  Group as Workspace,
} from 'graphql/types';
import type {
  ProcoreCompanyType,
  ProcoreProjectType,
  ProcoreUserType,
} from 'modules/public/Procore';

import type { FetchApi } from '../client';
import type { OAuthApi } from './types';

type OAuthTokenRequestParamTypes = {
  authorizationCode: string;
  integrationId?: number;
  integrationName: string;
  redirectURI: string;
  token: string;
};

export class GetAccessTokenHitachiError extends Error {
  constructor(public error: string) {
    super(
      `[Hitachi AEMP 2/getAccessTokenHitachi] Call failed with error: ${error}`,
    );
  }
}

export const oauthApi = (fetchApi: FetchApi) => ({
  getClientIdJohnDeere({ token }: { token: string }): Promise<{
    data: { clientId: string };
  }> {
    return fetchApi
      .setToken(token)
      .get('/app/oAuth/johnDeere/getClientId')
      .catch((error) => {
        const errorMessage = error?.message
          ? error.message
          : 'Unable to retrieve JohnDeere Client Id';
        throw new Error(errorMessage);
      });
  },
  getRequestTokensJohnDeere({
    authorizationCode,
    integrationId,
    integrationName,
    redirectURI,
    token,
  }: OAuthTokenRequestParamTypes): Promise<{
    data: { integrationId: number };
  }> {
    return fetchApi
      .setToken(token)
      .get(
        `/app/oAuth/johnDeere/getAccessToken?${
          integrationId ? `id=${integrationId}&` : ''
        }name=${
          integrationName || ''
        }&redirectURI=${redirectURI}&authorizationCode=${authorizationCode}`,
      )
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to get a John Deere request token for this integration.',
        );
      });
  },
  getAccessTokenHitachiAndCreateIntegration(
    clientId: string,
    clientSecret: string,
    name: string,
    authorizationCode: string,
    callbackOriginURL: string,
    token: string,
  ) {
    return fetchApi
      .setToken(token)
      .get(
        `/app/oAuth/hitachi/getAccessTokenAndCreateIntegration?name=${name}&clientId=${clientId}&clientSecret=${clientSecret}&authorizationCode=${authorizationCode}&callbackOriginURL=${callbackOriginURL}`,
      )
      .catch((error) => {
        const errorMessage = error?.message
          ? error.message
          : 'Unable to get a Hitachi access token for this integration.';
        throw new GetAccessTokenHitachiError(errorMessage);
      });
  },
  getAccessTokenHitachiAndEditIntegration(
    integrationId: number,
    authorizationCode: string,
    callbackOriginURL: string,
    token: string,
  ) {
    return fetchApi
      .setToken(token)
      .get(
        `/app/oAuth/hitachi/getAccessTokenAndEditIntegration?integrationId=${integrationId}&authorizationCode=${authorizationCode}&callbackOriginURL=${callbackOriginURL}`,
      )
      .catch((error) => {
        const errorMessage = error?.message
          ? error.message
          : 'Unable to get a Hitachi access token for this integration.';
        throw new GetAccessTokenHitachiError(errorMessage);
      });
  },
  checkExistingHitachiIntegration(
    clientId: string,
    clientSecret: string,
    token: string,
  ) {
    return fetchApi
      .setToken(token)
      .get(
        `/app/oAuth/hitachi/checkExistingIntegration?clientId=${clientId}&clientSecret=${clientSecret}`,
      )
      .catch((error) => {
        const errorMessage = error?.message
          ? error.message
          : 'Credentials are already integrated';
        throw new GetAccessTokenHitachiError(errorMessage);
      });
  },
  getClientIdProcore(
    id: number | null,
    name: string,
    token: string,
  ): Promise<{ clientId: string; integrationId: number }> {
    return fetchApi
      .setToken(token)
      .get(`/app/oAuth/procore/getClientId?id=${id}&name=${name}`)
      .catch((error) => {
        const errorMessage = error?.message
          ? error.message
          : 'Unable to retrieve Procore Client Id';
        throw new Error(errorMessage);
      });
  },
  getClientIdProcorePublic(): Promise<{ clientId: string }> {
    return fetchApi.get('/public/procore/configure/clientId').catch((error) => {
      const errorMessage = error?.message
        ? error.message
        : 'Unable to retrieve Procore Client Id';
      throw new Error(errorMessage);
    });
  },
  getAccessTokenProcore(
    id: number,
    code: string,
    token: string,
  ): Promise<{ success: Promise<OAuthApi> }> {
    return fetchApi
      .setToken(token)
      .get(`/app/oAuth/procore/getAccessToken?id=${id}&code=${code}`)
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to get a Procore access token for this integration.',
        );
      });
  },
  getAccessTokenProcorePublic(
    code: string,
    procoreCompanyId: string,
  ): Promise<{
    access_token: string;
    procoreUser: ProcoreUserType;
    companies: ProcoreCompanyType[];
    projects: ProcoreProjectType[];
    user: User;
    organization: Organization;
    workspaces: Workspace[];
    integration: Integration;
  }> {
    return fetchApi
      .get(
        `/public/procore/configure/accessToken?code=${code}&procoreCompanyId=${procoreCompanyId}`,
      )
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to get a Procore access token.',
        );
      });
  },
  signInWithProcore(
    code: string,
    procoreCompanyId: string | null,
    procoreProjectId: string | null,
  ): Promise<{
    token: string;
    workspaceId: number;
    success: boolean;
  }> {
    return fetchApi
      .get(
        `/public/procore/signin?code=${code}&procoreCompanyId=${procoreCompanyId}${
          procoreProjectId ? `&procoreProjectId=${procoreProjectId}` : ''
        }`,
      )
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to signin with Procore.',
        );
      });
  },
});
