import { useTranslation } from 'react-i18next';

import { INTEGRATION_STATUS } from 'modules/types';

import { IntegrationInProgress } from './IntegrationInProgress';
import { Container, Dot } from './index.css';

export type IntegrationStatusProps = {
  status: keyof typeof INTEGRATION_STATUS;
};

export const IntegrationStatus = ({ status }: IntegrationStatusProps) => {
  const [t] = useTranslation('translation');

  const renderDot = () => {
    switch (status) {
      case INTEGRATION_STATUS.INACTIVE:
        return <Dot gray />;
      case INTEGRATION_STATUS.PENDING:
        return <Dot blue />;
      case INTEGRATION_STATUS.DATA_NOT_FLOWING:
        return <Dot brown />;
      case INTEGRATION_STATUS.DATA_FLOWING:
        return <Dot green />;
      case INTEGRATION_STATUS.ERROR:
        return <Dot red />;
      case INTEGRATION_STATUS.ERROR_CREDENTIALS:
        return <Dot red />;
      case INTEGRATION_STATUS.ERROR_BUT_DATA_FLOWING:
        return <Dot orange />;
      default:
        return '';
    }
  };

  return (
    <Container>
      {status === INTEGRATION_STATUS.IN_PROGRESS ? (
        <IntegrationInProgress />
      ) : (
        <>
          {renderDot()}
          <span>
            {
              // The below commented lines are here for i18next-parser
              /*
              t('integration.status.INACTIVE')
              t('integration.status.PENDING')
              t('integration.status.DATA_NOT_FLOWING')
              t('integration.status.DATA_FLOWING')
              t('integration.status.ERROR')
              t('integration.status.ERROR_CREDENTIALS')
              t('integration.status.ERROR_BUT_DATA_FLOWING')
              */
              t(`integration.status.${status}`)
            }
          </span>
        </>
      )}
    </Container>
  );
};
