import classNames from 'classnames';
import type React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

export type NiceLinkProps = {
  to: string;
  children: React.ReactNode;
  fullWidth?: boolean;
};

export const NiceLink = ({
  to,
  children,
  fullWidth = false,
}: NiceLinkProps) => {
  return (
    <Link
      to={to}
      className={classNames(styles.link, { [styles.fullWidth]: fullWidth })}
    >
      {children}
    </Link>
  );
};
