import { SharingText } from 'modules/sharing/components/SharingText/SharingText';
import type { AssetSharingInfos } from 'modules/sharing/models/AssetSharingInfos/AssetSharingInfos.model';
import { SharingTextContainer } from './index.css';

type SharingCellProps = {
  sharingInfos: AssetSharingInfos;
};

export const SharingCell = ({ sharingInfos }: SharingCellProps) => {
  return (
    <SharingTextContainer>
      <SharingText sharingInfos={sharingInfos} />
    </SharingTextContainer>
  );
};
