import { hiboo } from 'modules/common-ui/styles/theme';
import { ThemeProvider } from 'styled-components';
import { FeatureFlagsProvider } from './feature-flags/FeatureFlagsContext';
import { NavigationMenuProvider } from './navigation-menu/NavigationMenuContext';

// we can nest multiple independent providers here so that App.js remains clean
// example:
// return (
//   <UserProvider>
//     <NavigationMenuProvider>
//       {children}
//     </NavigationMenuProvider>
//   </UserProvider>
// );

export const ContextProviders: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <FeatureFlagsProvider>
      <NavigationMenuProvider>
        <ThemeProvider theme={hiboo}>{children}</ThemeProvider>
      </NavigationMenuProvider>
    </FeatureFlagsProvider>
  );
};
