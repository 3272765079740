import colors from 'constants/colors';
import type React from 'react';

export type BookIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
};

export const BookIcon = ({
  color = colors.gray500,
  size = 16,
  style,
}: BookIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M8 2.64616C8 2.64616 6.5 2 4.75 2C3 2 1.5 2.64616 1.5 2.64616V14.1462C1.5 14.1462 3.25 13.6462 5 13.6462C6.75 13.6462 8 14.1462 8 14.1462M8 2.64616C8 2.64616 9.5 2 11.25 2C13 2 14.5 2.64616 14.5 2.64616V14.1462C14.5 14.1462 13.25 13.6462 11.5 13.6462C9.75 13.6462 8 14.1462 8 14.1462M8 2.64616V14.1462"
      stroke={color}
    />
  </svg>
);
