import { initialize } from '@procore/procore-iframe-helpers';
import { type FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import colors from 'constants/colors';

import { apiClient } from 'modules/api-client';
import layout from 'modules/layout';

import {
  Button,
  Card,
  CircularSpinner,
  HibooIcon,
  HibooLogoLarge,
  NiceLink,
} from 'modules/common-ui';

import { procoreGenerateAuthorizeUrl } from 'modules/public/Procore/utils';
import {
  Footer,
  Form,
  Header,
  LogoWrapper,
  WhatsHibooLink,
} from 'modules/user/containers/index.css';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line max-statements
export const ProcoreSignin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('procore');
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const procoreCompanyId = urlParams.get('procoreCompanyId') || '';
  const procoreProjectId = urlParams.get('procoreProjectId') || '';
  const redirectToConfiguration = urlParams.get('administration') || '';
  const [pending, setPending] = useState(false);
  const [authenticationError, setAuthenticationError] = useState('');

  if (redirectToConfiguration === 'true') {
    navigate(`/procore/configure?procoreCompanyId=${procoreCompanyId}`);
  }

  const context = initialize();

  async function login() {
    const url = await procoreGenerateAuthorizeUrl('/procore/signin', {
      procoreCompanyId,
      procoreProjectId,
    });

    context.authentication.authenticate({
      // Some URL on your domain that will start the authentication process.
      url,

      // A function to run if authentication is successful. Payload, you will see
      // below, is sent by you at the last stage of the authentication flow.
      onSuccess: async (payload) => {
        setPending(true);
        await apiClient.oauth
          .signInWithProcore(
            payload.code,
            payload.procoreCompanyId,
            payload.procoreProjectId,
          )
          .then(({ token, workspaceId, success }) => {
            setPending(false);
            if (success) {
              const redirectUrl = `${window.location.origin}?workspaceId=${workspaceId}&token=${token}`;
              window.open(`${redirectUrl}`, '_self');
            }
          });
      },

      // A function to run if authentication fails
      onFailure: (error) => {
        setAuthenticationError(error);
        setPending(false);
      },
    });
  }

  useEffect(() => {
    if (code && (procoreCompanyId || procoreProjectId)) {
      context.authentication.notifySuccess({
        code,
        procoreCompanyId,
        procoreProjectId,
      });
    }
  }, [code, procoreCompanyId, procoreProjectId, context.authentication]);

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await login();
  };

  return (
    <layout.Page
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LogoWrapper>
        <HibooLogoLarge />
      </LogoWrapper>
      <Card
        style={{
          alignItems: 'center',
          flex: 'unset',
          maxWidth: '400px',
          padding: '24px',
          width: 'calc(100% - 32px)',
        }}
      >
        <HibooIcon />
        <Header>{t('user:user.login.header')}</Header>
        <Form data-testid="login-form" onSubmit={submitForm}>
          <Button
            variant="outline-alternate"
            type="submit"
            disabled={pending}
            style={{ marginTop: '8px', width: '100%' }}
            iconLeft={pending ? <CircularSpinner /> : null}
          >
            {!pending
              ? t('signInWithProcore')
              : t('user:user.login.form.submit.loading')}
          </Button>
          <div>{authenticationError}</div>
        </Form>
        <Footer>
          <WhatsHibooLink href="https://www.hiboo.io" target="_blank">
            <HibooIcon small color={colors.gray500} />
            <span>{t('user:whatsHiboo')}</span>
          </WhatsHibooLink>
          <NiceLink
            to={`/procore/configure?procoreCompanyId=${procoreCompanyId}`}
          >
            <span
              style={{
                color: colors.gray500,
                fontSize: 12,
                lineHeight: '16px',
              }}
            >
              {t('goToConfigure')}
            </span>
          </NiceLink>
        </Footer>
      </Card>
    </layout.Page>
  );
};
