import type { FetchApi } from '../client';
import type { DiagnosticThinxtraOysterItemType } from './types';

export const diagnosticApi = (fetchApi: FetchApi) => ({
  getThinxtraOyster(): Promise<DiagnosticThinxtraOysterItemType[]> {
    return fetchApi
      .get('/public/diagnostic/thinxtra/oyster')
      .then((response) => {
        return response ? response.data : [];
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to get diagnostic for Thinxtra Oyster.',
        );
      });
  },
});
