import { useEffect, useRef, useState } from 'react';

import { Tag, Tooltip } from 'modules/common-ui';
import type { CustomFieldV2SelectValueType } from 'modules/types';
import { useSize } from 'modules/utils';
import { getTagColorFromString } from '../Tag/colors';

export type CustomFieldV2SelectValueProps = {
  value: CustomFieldV2SelectValueType;
  fullSize?: boolean;
  onClear?: () => void;
};

export const CustomFieldV2SelectValue = ({
  value = { label: '', color: 'default' },
  onClear,
  fullSize = false,
}: CustomFieldV2SelectValueProps) => {
  const [isEllipsis, setIsEllipsis] = useState(false);

  const textRef = useRef<HTMLDivElement>(null);

  const { width } = useSize(textRef);

  useEffect(() => {
    if (textRef.current) {
      const isHidden =
        textRef.current.scrollWidth > textRef.current.clientWidth;

      setIsEllipsis(isHidden);
    }
  }, [width]);

  useEffect(() => {
    Tooltip.rebuild();
  }, [isEllipsis]);

  const { label, color } = value;
  if (!label) return null;

  return (
    <Tag
      id={label}
      label={label}
      onDelete={onClear}
      maxWidth={fullSize ? undefined : 80}
      withTooltip={isEllipsis}
      color={getTagColorFromString(color)}
      forwardLabelRef={textRef}
    />
  );
};
