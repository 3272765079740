import colors from 'constants/colors';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

enum DotColorsEnum {
  neutral = 'neutral',
  gray = 'gray',
  blue = 'blue',
  brown = 'brown',
  red = 'red',
  yellow = 'yellow',
  orange = 'orange',
  green = 'green',
  darkGray = 'darkGray',
}

type DotColors = keyof typeof DotColorsEnum;

type DotProps = {
  neutral?: boolean;
  gray?: boolean;
  blue?: boolean;
  brown?: boolean;
  red?: boolean;
  yellow?: boolean;
  orange?: boolean;
  green?: boolean;
  darkGray?: boolean;
};

export const Dot = styled.div<DotProps>`
  background-color: ${(props) => {
    if (props.gray) return colors.gray400;
    if (props.neutral) return colors.neutral300;
    if (props.blue) return colors.blue400;
    if (props.brown) return colors.brown100;
    if (props.red) return colors.red300;
    if (props.orange) return colors.yellow600;
    if (props.yellow) return colors.yellow200;
    if (props.green) return colors.green400;
    if (props.darkGray) return colors.gray600;
    return colors.gray850;
  }};
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  min-width: 8px;
  min-height: 8px;
`;

export type StatusProps = {
  status: string;
  color: DotColors;
};

export const Status = ({ status, color }: StatusProps) => {
  const dotProps: DotProps = {
    [color]: true,
  };

  return (
    <Container>
      <Dot {...dotProps} />
      <span>{status}</span>
    </Container>
  );
};
