import { userLoginSuccess } from 'modules/user/actions';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { type Action, type Dispatch, bindActionCreators } from 'redux';

import colors from 'constants/colors';
import { type UserApi, apiClient } from 'modules/api-client';
import layout from 'modules/layout';

import {
  Button,
  Card,
  Field,
  FieldInputWrapper,
  FieldName,
  FieldValue,
  HibooIcon,
  HibooLogoLarge,
  TextInput,
} from 'modules/common-ui';

import { passwordRegex } from 'constants/password';
import i18n, { FALLBACK_LANGUAGE } from 'i18n';
import { PrivacyPolicyContainer } from 'modules/signup/common';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Footer,
  Form,
  Header,
  LogoWrapper,
  WhatsHibooLink,
} from '../index.css';

export const HelperText = styled.div`
  margin-bottom: 8px;
  color: ${colors.gray500};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export type AcceptInvitationProp = {
  userLoginSuccess: typeof userLoginSuccess;
};

const AcceptInvitationComponent = ({
  userLoginSuccess,
}: AcceptInvitationProp) => {
  const navigate = useNavigate();
  const [t] = useTranslation('user');

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    password: '',
  });
  const [pending, setPending] = useState(false);
  const [error, setError] = useState('');
  const [organization, setOrganization] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const invitationKey = urlParams.get('invitationKey') || '';
  const language = i18n.resolvedLanguage ?? FALLBACK_LANGUAGE;

  const onAcceptInvitationSuccess = (user: UserApi) => {
    const { email, firstName, lastName, organizationId, organizationName } =
      user;

    analytics.track('Signed Up', {
      type: 'Invited',
      first_name: firstName,
      last_name: lastName,
      email,
    });
    analytics.group(`${organizationId}`, {
      organizationName,
    });

    user.signUpInProgress = true;

    userLoginSuccess(user);
    setPending(false);

    navigate('/add-user-job');
  };

  useEffect(() => {
    apiClient.user
      .getInvitation({
        invitationKey,
      })
      .then((invitation) => {
        setOrganization(invitation.organizationName);
      })
      .catch((err: Error) => {
        setError(err.message);
      });
  }, [invitationKey]);

  const submitForm = () => {
    if (form.firstName && form.lastName && form.password && !pending) {
      setPending(true);
      apiClient.user
        .acceptInvitation({
          invitationKey,
          firstName: form.firstName,
          lastName: form.lastName,
          password: form.password,
          language,
        })
        .then((user) => {
          onAcceptInvitationSuccess(user);
        })
        .catch((err: Error) => {
          setError(err.message);
          setPending(false);
        });
    }
  };

  return (
    <layout.Page
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LogoWrapper>
        <HibooLogoLarge />
      </LogoWrapper>
      <Card
        style={{
          alignItems: 'center',
          flex: 'unset',
          maxWidth: '400px',
          padding: '24px',
          width: 'calc(100% - 32px)',
        }}
      >
        <HibooIcon />
        <Header>
          {t('user.acceptInvitation.header', { orgName: organization })}
        </Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <Field>
            <FieldName>
              <label htmlFor="invitation-firstName" className="col-form-label">
                {t('user.acceptInvitation.form.firstName.label')}
              </label>
            </FieldName>
            <FieldValue>
              <FieldInputWrapper width="100%">
                <TextInput
                  variant="secondary"
                  fullWidth
                  id="invitation-firstName"
                  value={form.firstName}
                  onChange={(e) =>
                    setForm({ ...form, firstName: e.target.value })
                  }
                  placeholder={t(
                    'user.acceptInvitation.form.firstName.placeholder',
                  )}
                  autoComplete="off"
                />
              </FieldInputWrapper>
            </FieldValue>
          </Field>
          <Field>
            <FieldName>
              <label htmlFor="invitation-lastName" className="col-form-label">
                {t('user.acceptInvitation.form.lastName.label')}
              </label>
            </FieldName>
            <FieldValue>
              <FieldInputWrapper width="100%">
                <TextInput
                  variant="secondary"
                  fullWidth
                  id="invitation-lastName"
                  value={form.lastName}
                  onChange={(e) =>
                    setForm({ ...form, lastName: e.target.value })
                  }
                  placeholder={t(
                    'user.acceptInvitation.form.lastName.placeholder',
                  )}
                  autoComplete="off"
                />
              </FieldInputWrapper>
            </FieldValue>
          </Field>
          <Field>
            <FieldName>
              <label htmlFor="invitation-password" className="col-form-label">
                {t('user.acceptInvitation.form.password.label')}
              </label>
            </FieldName>
            <FieldValue>
              <FieldInputWrapper width="100%">
                <TextInput
                  variant="secondary"
                  type="password"
                  fullWidth
                  id="invitation-password"
                  value={form.password}
                  pattern={passwordRegex.source}
                  title={t('user.resetPassword.form.help')}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  placeholder={t(
                    'user.acceptInvitation.form.password.placeholder',
                  )}
                  autoComplete="off"
                />
              </FieldInputWrapper>
            </FieldValue>
          </Field>
          <HelperText>{t('user.resetPassword.form.help')}</HelperText>
          <Button
            variant="outline-primary"
            type="submit"
            block
            disabled={
              !form.firstName || !form.lastName || !form.password || pending
            }
            style={{ marginTop: '8px' }}
          >
            {t('user.acceptInvitation.form.submit')}
          </Button>
          {error && <div className="text-danger mt-4">{error}</div>}
        </Form>
        <Footer>
          <WhatsHibooLink href="https://www.hiboo.io" target="_blank">
            <HibooIcon small color={colors.gray500} />
            <span>{t('whatsHiboo')}</span>
          </WhatsHibooLink>
        </Footer>
      </Card>
      <PrivacyPolicyContainer
        dangerouslySetInnerHTML={{
          __html: t('user.acceptInvitation.form.termsOfUse', {
            interpolation: { escapeValue: false },
          }),
        }}
      />
    </layout.Page>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators({ userLoginSuccess }, dispatch);

export const AcceptInvitationPage = connect(
  undefined,
  mapDispatchToProps,
)(AcceptInvitationComponent);
