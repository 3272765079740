import colors from 'constants/colors';

export type BarChartIconProps = {
  color?: string;
  height?: number;
  width?: number;
};

export const BarChartIcon = ({
  color = colors.gray500,
  height = 22,
  width = 25,
}: BarChartIconProps) => (
  <svg
    width={width || 25}
    height={height || 22}
    viewBox={'0 0 25 22'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.75 0.35C17.75 0.156701 17.9067 0 18.1 0H20.9C21.0933 0 21.25 0.1567 21.25 0.35V19.5H23.875C24.3582 19.5 24.75 19.8918 24.75 20.375C24.75 20.8582 24.3582 21.25 23.875 21.25H19.85H18.1H14.25H10.75H7.25H5.15H1.125C0.641751 21.25 0.25 20.8582 0.25 20.375C0.25 19.8918 0.641751 19.5 1.125 19.5H3.75V8.85C3.75 8.6567 3.9067 8.5 4.1 8.5H6.9C7.0933 8.5 7.25 8.6567 7.25 8.85V19.5H10.75V13.1C10.75 12.9067 10.9067 12.75 11.1 12.75H13.9C14.0933 12.75 14.25 12.9067 14.25 13.1V19.5H17.75V0.35Z"
      fill={color}
    />
  </svg>
);
