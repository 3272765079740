import FaAngleDown from 'react-icons/lib/fa/angle-down';
import { FatCrossIcon } from '../Icons';
import {
  ChevronContainer,
  Left,
  Placeholder,
  ResetContainer,
  TextContainer,
  WorkspaceSelectButtonContainer,
  WorkspacesLabel,
} from './WorkspaceSelectButton.css';

type Workspace = {
  id: number;
  name: string;
  parentWorkspaceId?: number & any;
};

type WorkspaceSelectButtonProps = {
  onClick: () => void;
  onReset?: () => void;
  showReset?: boolean;
  workspaces: Workspace[];
  placeholder?: string;
  customClass?: string;
};

const findHighestLevelWorkspace = (
  workspace: Workspace,
  workspaces: Workspace[],
): Workspace => {
  const matchedWorkspace = workspaces.find((ws) => +ws.id === +workspace.id);
  if (!matchedWorkspace) {
    return workspace;
  }
  if (!matchedWorkspace.parentWorkspaceId) {
    return matchedWorkspace;
  }
  const parentWorkspace = workspaces.find(
    (ws) => +ws.id === matchedWorkspace.parentWorkspaceId,
  );
  if (!parentWorkspace) {
    return matchedWorkspace;
  }
  return findHighestLevelWorkspace(parentWorkspace, workspaces);
};

const sortHighestSelectedFirst = (_selectedWorkspaces: Workspace[]) => {
  const highestLevel = findHighestLevelWorkspace(
    _selectedWorkspaces[0],
    _selectedWorkspaces,
  );

  const idx = _selectedWorkspaces.findIndex((ws) => ws.id === highestLevel.id);
  if (idx > -1) {
    const part1 = _selectedWorkspaces.slice(0, idx);
    const part2 = _selectedWorkspaces.slice(
      idx + 1,
      _selectedWorkspaces.length,
    );
    return [highestLevel, ...part1, ...part2];
  }
  return _selectedWorkspaces;
};

export const WorkspaceSelectButton = ({
  onClick,
  workspaces,
  placeholder,
  customClass = '',
  showReset = false,
  onReset,
}: WorkspaceSelectButtonProps) => {
  const workspacesCount = workspaces?.length;

  let workspace1Name = '';
  if (workspacesCount) {
    workspace1Name = sortHighestSelectedFirst(workspaces)[0].name;
  }

  return (
    <WorkspaceSelectButtonContainer onClick={onClick} className={customClass}>
      {workspacesCount > 0 ? (
        <Left className="workspace-label">
          <TextContainer hasResetIcon={showReset}>
            <WorkspacesLabel>{workspace1Name}</WorkspacesLabel>
            {workspacesCount > 1 && (
              <div>
                {', +'}
                {workspacesCount - 1}
              </div>
            )}
          </TextContainer>
          {showReset && (
            <ResetContainer
              onClick={(e) => {
                e.stopPropagation();
                if (typeof onReset === 'function') onReset();
              }}
            >
              <FatCrossIcon color="currentColor" />
            </ResetContainer>
          )}
        </Left>
      ) : (
        <Placeholder className="placeholder">{placeholder || ''}</Placeholder>
      )}

      <ChevronContainer className="chevron-container">
        <FaAngleDown />
      </ChevronContainer>
    </WorkspaceSelectButtonContainer>
  );
};
