import { ApolloProvider } from '@apollo/client';
import {
  type ComponentClass,
  type FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { type Action, type Dispatch, bindActionCreators } from 'redux';

import { apiClient } from 'modules/api-client';
import { useOnboarding } from 'modules/onboarding';
import {
  disconnectUser,
  userLoginSuccess,
  userSwitchToken,
} from 'modules/user/actions';
import type { Store } from 'reducers';

import { createClient } from './client';

type AppoloClientProps = {
  token: string | null;
  disconnectUser: () => void;
  switchToken: typeof userSwitchToken;
  userLoginSuccess: typeof userLoginSuccess;
};

const mapStateToProps = (state: Store) => ({
  token: state.user.token,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators(
    { disconnectUser, switchToken: userSwitchToken, userLoginSuccess },
    dispatch,
  );

const getRedirectURL = (pathname: string) => {
  // TODO: from the asset page, if workspace do not have access redirect to inventory to avoid this
  if (pathname.startsWith('/assets')) return '/inventory?switchWorkspace';
  if (pathname.startsWith('/zones')) return '/zones?switchWorkspace';
  return `${pathname}?switchWorkspace`;
};

const getLoginURLWithRedirect = () => {
  return `/login?r=${window.encodeURI(window.location.pathname + window.location.search)}`;
};

export const withAppoloClient = (
  Component: FunctionComponent<{}> | ComponentClass<{}> | string,
) =>
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    ({
      token,
      switchToken,
      disconnectUser,
      userLoginSuccess,
    }: AppoloClientProps) => {
      const navigate = useNavigate();
      const location = useLocation();
      const isOnboarding = useOnboarding();

      // Set a state if workspace is forced to change
      const urlParams = new URLSearchParams(location.search);
      const workspace = urlParams.get('workspace');
      const urlToken = urlParams.get('token');
      const [workspaceForced, setWorkspaceForced] = useState(!!workspace);

      const [currentToken, setCurrentToken] = useState(token);
      const [client, setClient] = useState(createClient(token, disconnectUser));

      useEffect(() => {
        if (!token) {
          disconnectUser();
          navigate(getLoginURLWithRedirect());
        }
        if (token !== currentToken) {
          setCurrentToken(token);
          const c = createClient(token, disconnectUser);

          setClient(c);
          if (!workspaceForced) {
            // If it's a classic workspace, we clear the store and then we redirect
            c.clearStore().then(() => {
              if (!isOnboarding) {
                const redirectUrl = getRedirectURL(location.pathname);
                navigate(redirectUrl);
              }
              window.location.reload();
            });
          } else {
            // Otherwise, we can redirect directly and remove the flag which avoid render

            setWorkspaceForced(false);
            navigate(location.pathname + location.search);
          }
        }
      }, [token, currentToken]); // eslint-disable-line react-hooks/exhaustive-deps

      useEffect(() => {
        // If `?workspace` is defined, change it
        if (workspace) {
          const workspaceId = +workspace;
          apiClient.user
            .switchWorkspace(token || '', { workspaceId })
            .then((response) => {
              switchToken(
                response.token,
                response.id,
                response.admin,
                response.adminOfOrganization,
                response.workspaceId,
                response.group,
                response.email,
              );
            })
            .catch(() => {
              navigate(getLoginURLWithRedirect());
            });
        }
      }, [workspace]); // eslint-disable-line react-hooks/exhaustive-deps

      useEffect(() => {
        if (urlToken) {
          apiClient.user
            .me(urlToken)
            .then((response) => {
              userLoginSuccess(response);
              if (isOnboarding) {
                navigate('/onboarding');
                return;
              }
              let pathname = location.pathname;
              if (!pathname || pathname === '/') {
                pathname = '/map';
              }
              const params = new URLSearchParams(location.search);
              params.delete('token');
              let search = params.toString();
              if (search) {
                search = `?${search}`;
              }
              navigate(`${pathname}${search}`);
            })
            .catch(() => {});
        }
      }, [urlToken]); // eslint-disable-line react-hooks/exhaustive-deps

      // if we are currently changing token or we don't have token => redirect
      if (!client || !token || token !== currentToken || workspaceForced) {
        return null;
      }

      return (
        <ApolloProvider client={client} key={token}>
          <Component />
        </ApolloProvider>
      );
    },
  );
