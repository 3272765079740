const colors = {
  lightGrey: '#F6F9FC',
  shadyGrey: '#E7EBF0',
  mediumGrey: '#B2B2B2',
  darkGrey: '#757575',
  lightBlue: '#BECDDD',
  blue: '#4164FB',
  darkBlue: '#4B5C7C',

  white: '#FFFFFF',
  offWhite: '#FCFDFD',

  gray50: '#F6F9FC',
  gray100: '#EFF2F5',
  gray200: '#E5E8EB',
  gray300: '#D5D8DB',
  gray300Hover: 'rgba(45,47,48,0.08)',
  gray400: '#B1B4B7',
  gray500: '#929497',
  gray600: '#6A6C6F',
  gray700: '#56595B',
  gray800: '#383A3C',
  gray850: '#2D2F30',
  gray850Hover: 'rgba(45,47,48,0.08)',
  gray900: '#181A1C',
  grayHover: 'rgba(45,47,48,0.16)',
  black: '#000000',
  blackNeutral: '#0A0A0C',
  neutral200: '#E5E8EB',
  neutral300: '#D5D8DB',
  neutral850: '#2D2F30',

  blue25: '#F7F8FF',
  blue50: '#E8EAFE',
  blue100: '#C5CAFC',
  blue200: '#9CA8FA',
  blue300: '#6E85F9',
  blue400: '#4569F7',
  blue500: '#004CF3',
  blue600: '#0044E7',

  brown100: '#DBC2B7',
  brown500: '#6A3F2C',

  cyan50: '#E0F5FE',
  cyan100: '#B1E4FC',
  cyan200: '#7DD3FA',
  cyan300: '#45C2F7',
  cyan400: '#07B4F6',
  cyan500: '#00A7F4',
  cyan700: '#0078C1',

  green50: '#E6F7EE',
  green100: '#C2ECD6',
  green200: '#99DFBC',
  green300: '#6BD3A1',
  green400: '#41C98D',
  green500: '#00BF78',
  green700: '#01915B',

  orange100: '#FBCFBA',
  orange200: '#F8B08D',
  orange400: '#F37D38',
  orange500: '#F16908',

  pink50: '#FEEFF1',
  pink100: '#FECFEB',
  pink500: '#FF68B5',

  purple25: '#FBF9FF',
  purple50: '#F0E6FE',
  purple100: '#D7C2FD',
  purple400: '#8554DB',
  purple500: '#6D35D0',

  red50: '#FFE0E3',
  red100: '#FFB1BA',
  red200: '#F77F8E',
  red300: '#EB4E64',
  red400: '#E12646',
  red500: '#D6002B',

  sand50: '#FAF7F5',

  yellow50: '#FFF4DE',
  yellow100: '#FEE1AC',
  yellow200: '#FECD75',
  yellow400: '#FDAB00',
  yellow500: '#FD9C00',
  yellow600: '#FDAB00',

  magenta: '#F82B60',

  purplePlainLight: 'rgba(133, 84, 219, 1)',
  purplePlainLight50: 'rgba(133, 84, 219, 0.5)',
  purplePlainLight25: 'rgba(133, 84, 219, 0.25)',
  purplePlainLight20: 'rgba(133, 84, 219, 0.2)',
  purplePlainLight10: 'rgba(133, 84, 219, 0.1)',
  purplePlainLight8: 'rgba(133, 84, 219, 0.08)',

  orangePlainLight: 'rgba(243, 125, 56, 1)',
  orangePlainLight50: 'rgba(243, 125, 56, 0.5)',
  orangePlainLight25: 'rgba(243, 125, 56, 0.25)',
  orangePlainLight20: 'rgba(243, 125, 56, 0.2)',
  orangePlainLight10: 'rgba(243, 125, 56, 0.1)',
  orangePlainLight8: 'rgba(243, 125, 56, 0.08)',

  bluePlainLight: 'rgba(69, 105, 247, 1)',
  bluePlainLight50: 'rgba(69, 105, 247, 0.5)',
  bluePlainLight25: 'rgba(69, 105, 247, 0.25)',
  bluePlainLight20: 'rgba(69, 105, 247, 0.2)',
  bluePlainLight10: 'rgba(69, 105, 247, 0.1)',
  bluePlainLight8: 'rgba(69, 105, 247, 0.08)',

  cyanPlainLight: 'rgba(7, 180, 246, 1)',
  cyanPlainLight50: 'rgba(7, 180, 246, 0.5)',
  cyanPlainLight25: 'rgba(7, 180, 246, 0.25)',
  cyanPlainLight20: 'rgba(7, 180, 246, 0.2)',
  cyanPlainLight10: 'rgba(7, 180, 246, 0.1)',
  cyanPlainLight8: 'rgba(7, 180, 246, 0.08)',

  greenPlainLight: 'rgba(65, 201, 141, 1)',
  greenPlainLight50: 'rgba(65, 201, 141, 0.5)',
  greenPlainLight25: 'rgba(65, 201, 141, 0.25)',
  greenPlainLight20: 'rgba(65, 201, 141, 0.2)',
  greenPlainLight10: 'rgba(65, 201, 141, 0.1)',
  greenPlainLight8: 'rgba(65, 201, 141, 0.08)',
} as const;

export default colors;

export const categoryColors: { [k: string]: string } = {
  default: colors.shadyGrey,
  gray: colors.gray100,
  red: colors.red100,
  deepRed: colors.red200,
  pink: colors.pink100,
  purple: colors.purple100,
  blue: colors.blue100,
  cyan: colors.cyan100,
  green: colors.green100,
  yellow: colors.yellow100,
  orange: colors.orange100,
  brown: colors.brown100,
} as const;

export const zoneColors: { [k: string]: string } = {
  default: colors.shadyGrey,
  gray: colors.gray500,
  red: colors.red500,
  pink: colors.pink500,
  purple: colors.purple500,
  blue: colors.blue500,
  cyan: colors.cyan500,
  green: colors.green500,
  yellow: colors.yellow500,
  orange: colors.orange500,
  black: colors.black, // TODO: we dont have black color for zones category
  brown: colors.brown500,
} as const;

export const primaryColors = {
  fade: colors.blue50,
  light: colors.blue100,
  main: colors.blue400,
  medium: colors.blue500,
  dark: colors.blue600,
};

export const neutralColors = {
  light: colors.gray100,
  main: colors.gray300,
  medium: colors.gray400,
  dark: colors.gray850,
  neutral200: colors.neutral200,
  neutral850: colors.neutral850,
};

export const alternateColors = {
  light: colors.cyan100,
  main: colors.cyan300,
  medium: colors.cyan400,
  dark: colors.cyan500,
};

export const dangerColors = {
  light: colors.red100,
  main: colors.red300,
  medium: colors.red400,
  dark: colors.red500,
};
