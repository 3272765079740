import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useMediaQuery } from 'react-responsive';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import { Drawer } from 'modules/common-ui';
import debounce from 'utils/debounce';

import { RichButton } from './Button';

import { Checkbox } from 'modules/common-ui';
import { usePositionedElement } from 'modules/common-ui/hooks/usePositionedElement';
import { TextInput } from '../Form';
import type { RichMultiselectOption } from './RichMultiselectOption.type';
import {
  Action,
  ActionBar,
  ActionWrapper,
  Container,
  Label,
  List,
  ListHelperText,
  ListRow,
  Menu,
  MenuTitle,
  NoResultsHelperText,
  Row,
  Separator,
} from './index.css';

export type RichMultiselectProps = {
  alignRight?: boolean;
  marginAlignRight?: number;
  label?: string;
  onUpdate: (value: any) => void;
  options: RichMultiselectOption[];
  placeholder?: string;
  searchPlaceholder?: string;
  values: [] | RichMultiselectOption[];
  customButtonRenderer?: (open: () => void) => React.ReactNode;
  menuHeight?: number;
  menuWidth?: any;
  inputBackgroundColor?: string;
  menuTitle?: string;
};

// eslint-disable-next-line max-statements
export const RichMultiselect = ({
  options,
  onUpdate,
  placeholder = '',
  searchPlaceholder = '',
  values,
  alignRight = false,
  label,
  customButtonRenderer,
  marginAlignRight = 8,
  menuHeight,
  menuWidth,
  inputBackgroundColor,
  menuTitle = '',
}: RichMultiselectProps) => {
  const [display, setDisplay] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [orderedOptions, setOrderedOptions] =
    useState<RichMultiselectOption[]>(options);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });

  const referenceElement = useRef<HTMLDivElement | null>(null);
  const menuReference = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation('commonUi');

  // update internal state when options change (can happen if option are lazy loaded for instance)
  useEffect(() => {
    if (options?.length) {
      setFilteredOptions(options);
    }
  }, [options]);

  useEffect(() => {
    const orderedList = [...options].sort((a, b) => {
      const aChecked = values.some((val) => val.value === a.value);
      const bChecked = values.some((val) => val.value === b.value);

      if (aChecked && !bChecked) return -1;
      if (!aChecked && bChecked) return 1;
      return 0;
    });

    setOrderedOptions(orderedList);
  }, [options, values]);

  usePositionedElement(
    display,
    referenceElement,
    menuReference,
    alignRight ? 'right' : 'left',
  );

  const onChangeSearch = (value: string) => {
    setSearchText(value);
    filterOptions(value);
  };

  const filterOptions = debounce((value: string) => {
    if (!value || !value.length) {
      setFilteredOptions(orderedOptions);

      return;
    }

    const filteredList = orderedOptions.filter(
      (o) => o.label.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    );

    setFilteredOptions(filteredList);
  }, 700);

  const checkIfChecked = (value: any) => {
    return values.findIndex((item) => item.value === value) !== -1;
  };

  const toggleCheckbox = (value: any) => {
    if (checkIfChecked(value)) {
      onUpdate(values.filter((item) => item.value !== value));

      return;
    }

    const option = options.find((item) => item.value === value);

    onUpdate([...values, option]);
  };

  const openContent = useCallback(() => {
    setDisplay(true);
  }, [setDisplay]);

  const toggleDropdown = () => {
    const displayState = !display;
    if (displayState) {
      setFilteredOptions(orderedOptions);
    }
    setDisplay(displayState);
  };

  const renderButton = () => {
    if (customButtonRenderer) {
      return (
        <div ref={referenceElement}>{customButtonRenderer(openContent)}</div>
      );
    }
    return (
      <div ref={referenceElement} data-testid="rich-multiselect-button">
        <RichButton
          values={values}
          placeholder={placeholder || t('richMultiselect.options.placeholder')}
          onClick={toggleDropdown}
          max={options.length}
          inputBackgroundColor={inputBackgroundColor}
        />
      </div>
    );
  };

  const renderOptions = () => {
    if (isMobile) {
      return (
        <Drawer isOpen={display} onDone={() => setDisplay(false)} title={label}>
          <>
            <ActionWrapper>
              <TextInput
                placeholder={
                  searchPlaceholder || t('richMultiselect.search.placeholder')
                }
                value={searchText}
                onChange={(e) => onChangeSearch(e.target.value)}
                variant="secondary"
                fullWidth={isMobile}
                large
              />
              <ActionBar>
                <Action onClick={() => onUpdate(filteredOptions)}>
                  {t('richMultiselect.selectAll')}
                </Action>
                <Separator>·</Separator>
                <Action onClick={() => onUpdate([])}>
                  {t('richMultiselect.unselectAll')}
                </Action>
              </ActionBar>
            </ActionWrapper>
            <List>
              {filteredOptions.length > 0 && (
                <ListHelperText>
                  {t('richMultiselect.helperText.selectAnOption')}
                </ListHelperText>
              )}
              {searchText?.length && !filteredOptions.length ? (
                <NoResultsHelperText>
                  {t('richMultiselect.helperText.noResults')}
                </NoResultsHelperText>
              ) : (
                <Fragment>
                  {filteredOptions.map((o) => (
                    <Row
                      noRadius
                      key={`row-${o.key}`}
                      onClick={() => {
                        toggleCheckbox(o.value);
                      }}
                    >
                      <ListRow>
                        <Checkbox
                          checked={checkIfChecked(o.value)}
                          onChange={() => {
                            toggleCheckbox(o.value);
                          }}
                        />
                        <Label>{o.label}</Label>
                      </ListRow>
                    </Row>
                  ))}
                </Fragment>
              )}
            </List>
          </>
        </Drawer>
      );
    }

    if (!display) {
      return null;
    }

    return (
      <div ref={menuReference} style={{ zIndex: 1 }}>
        <Menu
          noPadding
          alignRight={alignRight}
          marginAlignRight={marginAlignRight}
          menuWidth={menuWidth}
        >
          <ActionWrapper>
            <TextInput
              placeholder={
                searchPlaceholder || t('richMultiselect.search.placeholder')
              }
              value={searchText}
              onChange={(e) => onChangeSearch(e.target.value)}
              variant="secondary"
              fullWidth
            />
            {menuTitle && <MenuTitle>{menuTitle}</MenuTitle>}
            <ActionBar>
              <Action onClick={() => onUpdate(filteredOptions)}>
                {t('richMultiselect.selectAll')}
              </Action>
              <Separator>·</Separator>
              <Action onClick={() => onUpdate([])}>
                {t('richMultiselect.unselectAll')}
              </Action>
            </ActionBar>
          </ActionWrapper>
          <List menuHeight={menuHeight}>
            {!menuTitle && filteredOptions.length > 0 && (
              <ListHelperText>
                {t('richMultiselect.helperText.selectAnOption')}
              </ListHelperText>
            )}
            {searchText?.length && !filteredOptions.length ? (
              <NoResultsHelperText>
                {t('richMultiselect.helperText.noResults')}
              </NoResultsHelperText>
            ) : (
              <Fragment>
                {filteredOptions.map((o) => (
                  <Row
                    noRadius
                    key={`row-${o.key}`}
                    onClick={() => {
                      toggleCheckbox(o.value);
                    }}
                    data-testid="rich-multiselect-option"
                  >
                    <ListRow>
                      <Checkbox
                        checked={checkIfChecked(o.value)}
                        onChange={() => toggleCheckbox(o.value)}
                      />
                      <Label>{o.label}</Label>
                    </ListRow>
                  </Row>
                ))}
              </Fragment>
            )}
          </List>
        </Menu>
      </div>
    );
  };

  return (
    <Container>
      <OutsideClickHandler
        onOutsideClick={() => setDisplay(false)}
        useCapture={false}
      >
        {renderButton()}
        {renderOptions()}
      </OutsideClickHandler>
    </Container>
  );
};
