import colors from 'constants/colors';
import { TYPEFORM_FREEMIUM_LINKS } from 'constants/externalLinks';
import type { GraphQLUser } from 'modules/types/graphql';
import React from 'react';
import { useSelector } from 'react-redux';
import type { Store } from 'reducers';

import { ArrowUpIconFreemium } from 'modules/common-ui';
import { StyledButton } from 'modules/common-ui/components/Button/index.css';

import i18n from 'i18n';
import {
  FreemiumTooltipDescription,
  FreemiumTooltipTitle,
  FreemiumTooltipWrapper,
} from './index.css';

export type FreemiumTooltipProps = {
  id?: string;
  className?: string;
  freemiumTooltipTitle?: string;
  freemiumTooltipDescription?: string;
  freemiumTooltipButtonLabel?: string;
};

export const FreemiumTooltip = React.forwardRef(
  (props: FreemiumTooltipProps, ref: React.Ref<any>) => {
    const {
      id,
      className,
      freemiumTooltipTitle,
      freemiumTooltipDescription,
      freemiumTooltipButtonLabel,
    } = props;

    const userLanguage = i18n.resolvedLanguage;
    const pricingLinkConstant =
      userLanguage === 'fr'
        ? TYPEFORM_FREEMIUM_LINKS.FREEMIUM_GET_IN_TOUCH_FR
        : TYPEFORM_FREEMIUM_LINKS.FREEMIUM_GET_IN_TOUCH_EN;
    const user = useSelector<Store>((state) => state.user) as GraphQLUser & {
      organizationName: string;
    };
    let pricingLink;

    if (user && pricingLinkConstant) {
      pricingLink = `${pricingLinkConstant}firstname=${user.firstName}&email=${user.email}&organization=${user.organizationName}`;
    }

    return (
      <>
        <FreemiumTooltipWrapper className={className}>
          <FreemiumTooltipTitle>{freemiumTooltipTitle}</FreemiumTooltipTitle>
          <FreemiumTooltipDescription>
            {freemiumTooltipDescription}
          </FreemiumTooltipDescription>
          <a href={pricingLink} target="_blank" rel="noopener noreferrer">
            <StyledButton
              id={`${id}-freemium`}
              block={false}
              disabled={false}
              ref={ref}
              size={'sm'}
              type={'button'}
              variant={'outline-freemium'}
              color={colors.purple500}
            >
              <ArrowUpIconFreemium />

              {freemiumTooltipButtonLabel}
            </StyledButton>
          </a>
        </FreemiumTooltipWrapper>
      </>
    );
  },
);

FreemiumTooltip.displayName = 'Button';

export default FreemiumTooltip;
