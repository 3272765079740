import styled from 'styled-components';

import { BREAKPOINT_MOBILE_END } from 'constants/breakpoints';
import colors from 'constants/colors';

export const PaginationWrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  padding-right: 12px;
  padding-left: 12px;

  @media screen and (max-width: ${BREAKPOINT_MOBILE_END}px) {
    justify-content: space-between;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

export const Right = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-left: 8px;
`;

export const RowsPerPage = styled.select`
  padding: 4px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: 400;
  outline-width: 0;
`;

export const PaginationNavigationButton = styled.div<{ disabled?: boolean }>`
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  color: ${(props) => (props.disabled ? colors.gray300 : colors.gray850)};
  background: ${colors.white};
  border: 0.5px solid ${colors.gray300};
  border-radius: 5px;
  ${(props) => !props.disabled && 'cursor: pointer'};
`;

export const PaginationNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 16px;
  ${PaginationNavigationButton} + ${PaginationNavigationButton} {
    margin-left: 10px;
  }
`;

export const PageInfo = styled.div``;

export const CurrentPageInput = styled.input`
  background: ${colors.white};
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 400;
  line-height: 1;
  outline-width: 0;
  padding: 6px 0;
  text-align: center;
  width: 40px;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`;

export const TotalPages = styled.span`
  line-height: 1;
  white-space: nowrap;
`;
