import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import colors from 'constants/colors';
import {
  DEFAULT_SPACING,
  PAGE_LATERAL_MARGIN,
  PAGE_TOP_MARGIN,
} from 'modules/layout';
import styled from 'styled-components';

type PageTitleProps = {
  noPaddingRight?: boolean;
  noPaddingLeft?: boolean;
  noPaddingTop?: boolean;
  withPaddingBottom?: boolean;
  style?: React.CSSProperties;
};

export const PageTitleWrapper = styled.div<PageTitleProps>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  min-height: 36px;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #373530;
  ${(props) =>
    props.noPaddingRight ? '' : `padding-right: ${PAGE_LATERAL_MARGIN}`};
  ${(props) =>
    props.noPaddingLeft ? '' : `padding-left: ${PAGE_LATERAL_MARGIN}`};
  ${(props) => (props.noPaddingTop ? '' : `margin-top:${PAGE_TOP_MARGIN}`)};
  ${(props) =>
    props.withPaddingBottom ? `margin-bottom:${DEFAULT_SPACING}` : ''};

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    font-size: 16px;
    line-height: 22px;
    color: ${colors.gray850};
    font-weight: 500;
    margin: 0 16px;
    min-height: 22px;
    padding: 0;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    flex-direction: row;
    align-items: center;
  }
`;
