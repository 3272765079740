import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Tooltip } from 'modules/common-ui';
import { DiagnosticThinxtraOyster } from 'modules/public/DiagnosticThinxtraOyster';
import { ProcoreConfiguration, ProcoreSignin } from 'modules/public/Procore';
import { SignupPages } from 'modules/signup';
import { AcceptInvitationPage, Login, ResetPassword } from 'modules/user';

import * as Sentry from '@sentry/browser';
import './charts';
import { Main } from './components/Main';

import './charts';
import { ToastContainer } from 'modules/common-ui/components/ToastContainer';
import config from './config';

if (config.sentryTracking && config.SENTRY_DSN) {
  try {
    Sentry.init({
      dsn: config.SENTRY_DSN,
    });
  } catch (err) {
    console.error('Error initializing Sentry:', err);
  }
}

export const RouterWrapper = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/diagnostic/thinxtra/oyster"
            element={<DiagnosticThinxtraOyster />}
          />
          <Route path="/procore/configure" element={<ProcoreConfiguration />} />
          <Route path="/procore/signin" element={<ProcoreSignin />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signup/*" element={<SignupPages />} />
          <Route path="/accept-invitation" element={<AcceptInvitationPage />} />
          <Route path="*" element={<Main />} />
        </Routes>
        {/* important to have the ToastContainer inside the BrowserRouter so we can use Link component
            inside toast notifications */}
        <ToastContainer />
      </BrowserRouter>
      <Tooltip id="hiboo-tooltip" />
    </>
  );
};
