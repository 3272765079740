import colors from 'constants/colors';
import styled from 'styled-components';

export const SelectedIconContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 8px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 20px;
`;

export const WorkspaceRowName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.gray850};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 16px;
  margin-bottom: -2px;
  margin-top: 2px;
`;

export const WorkspaceRowContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
  position: relative;
  cursor: pointer;

  ${WorkspaceRowName} {
    font-weight: ${(props) => (props.selected ? '600' : '400')};
  }
`;

export const WorkspaceSharedContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SharingTag = styled.div`
  font-size: 11px;

  background: red;
  border-radius: 3px;
  display: flex;
  background: ${colors.blue50};
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  svg {
    margin-right: 4px;
  }
`;

export const WorkspaceRowNbAssets = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: ${colors.gray500};
`;
