import colors from 'constants/colors';
import hexToRgba from 'hex-to-rgba';
import type { Theme } from 'react-select';
import { hiboo } from '../theme';

export function buildCommonStyle(hasError = false) {
  return {
    control: (provided: any, state: any) => ({
      ...provided,
      background: hiboo.colors.neutral.neutral50,
      border: `0.5px solid ${
        hasError
          ? colors.magenta
          : state.isFocused
            ? colors.blue100
            : colors.gray300
      }`,
      borderRadius: '5px',
      boxShadow:
        hasError || state.isFocused
          ? `inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 0 0.15rem ${hexToRgba(
            hasError ? colors.magenta : colors.blue400,
            0.25,
          )}`
          : 0,
      cursor: 'pointer',
      minHeight: '32px',
      transition:
        'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      '&:hover': {
        border: `0.5px solid ${
          hasError
            ? colors.magenta
            : state.isFocused
              ? colors.blue100
              : colors.gray300
        }`,
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: colors.gray500,
      fontSize: '14px',
      lineHeight: '18px',
      overflow: 'hidden',
      paddingRight: '16px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '0 4px',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      paddingTop: '4px',
      paddingBottom: '4px',
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      paddingTop: '4px',
      paddingBottom: '4px',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      '&:active': {
        backgroundColor: colors.gray300Hover,
      },
    }),
  };
}

export const getCommonTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: colors.gray100,
    primary: colors.gray50,
  },
});
