import colors from 'constants/colors';
import type React from 'react';

export type ArrowRightIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
};

export const ArrowRightIcon = ({
  size = 16,
  color = colors.black,
  style,
}: ArrowRightIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={'0 0 16 16'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      aria-hidden={true}
    >
      <rect
        x="2.875"
        y="8.83331"
        width="1.66667"
        height="8.4375"
        rx="0.833333"
        transform="rotate(-90 2.875 8.83331)"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7529 7.29289C13.0824 7.68342 13.0824 8.31658 12.7529 8.70711L9.37787 12.7071C9.04837 13.0976 8.51413 13.0976 8.18463 12.7071C7.85512 12.3166 7.85512 11.6834 8.18463 11.2929L10.963 8L8.18463 4.70711C7.85512 4.31658 7.85512 3.68342 8.18463 3.29289C8.51413 2.90237 9.04837 2.90237 9.37787 3.29289L12.7529 7.29289Z"
        fill={color}
      />
    </svg>
  );
};
