import { DownArrow } from 'modules/common-ui/components/Icons';
import { type DropdownIndicatorProps, components } from 'react-select';
import styled from 'styled-components';
import type { SingleSelectOption } from '../SingleSelectOption.type';

const RoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.gray900};
  }
`;

export const DropdownIndicator = <T extends SingleSelectOption>(
  props: DropdownIndicatorProps<T>,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <RoundContainer>
        <DownArrow />
      </RoundContainer>
    </components.DropdownIndicator>
  );
};
