import { diagnosticApi } from './diagnostic/index';
import { flagApi } from './flags/index';
import { oauthApi } from './oauth/index';
import { LoginError, userApi } from './user/index';

import { analyticsApi } from './analytics';
import { createFetchApi } from './client';
import { fleetApi } from './fleet';
import { integrationApi } from './integration';

export const apiClient = {
  fleet: fleetApi(createFetchApi()),
  user: userApi(createFetchApi()),
  diagnostic: diagnosticApi(createFetchApi()),
  oauth: oauthApi(createFetchApi()),
  flag: flagApi(createFetchApi()),
  integration: integrationApi(createFetchApi()),
  analytics: analyticsApi(createFetchApi()),
};

export { LoginError };
export * from './user/types';
