import type React from 'react';

import {
  CheckIcon,
  Dropdown,
  type DropdownOption,
  type DropdownProps,
} from 'modules/common-ui';

import colors from 'constants/colors';
import {
  CheckIconWrapper,
  CustomLink,
  HelperText,
  OptionTextWrapper,
  OptionWrapper,
  RemoveMemberOption,
  RolePickerLabel,
  Wrapper,
} from './index.css';

export type RoleValueType = 'viewer' | 'admin';

type RolePickerProps = {
  value: RoleValueType;
  placeholder?: string;
  onChange: any;
  onDelete?: any;
  roles: DropdownOption[];
  menuWidth?: DropdownProps['menuWidth'];
  align?: DropdownProps['align'];
  inputType?: DropdownProps['inputType'];
  light?: DropdownProps['light'];
  menuStyle?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  disabled?: boolean;
};

export const RolePicker = ({
  value,
  placeholder = '',
  onChange,
  onDelete,
  roles,
  menuWidth,
  align,
  inputType = false,
  light = false,
  menuStyle = {},
  selectStyle = {},
  disabled = false,
}: RolePickerProps) => {
  const renderOption = (option: any): JSX.Element => {
    const isRoleSelected = value === option.value;
    if (option.value === 'delete') {
      return (
        <OptionWrapper data-testid="role-select-option-delete">
          <RemoveMemberOption>{option.label}</RemoveMemberOption>
        </OptionWrapper>
      );
    }
    return (
      <OptionWrapper>
        <OptionTextWrapper data-testid={`role-select-option-${option.value}`}>
          <RolePickerLabel>{option.heading}</RolePickerLabel>
          <HelperText>
            {option.helperText}{' '}
            {option.helpLink && (
              <CustomLink
                href={option.helpLink.uri}
                target="_blank"
                rel="noreferrer"
              >
                {option.helpLink.text}
              </CustomLink>
            )}
          </HelperText>
        </OptionTextWrapper>
        <CheckIconWrapper visible={isRoleSelected}>
          <CheckIcon color={colors.gray850} />
        </CheckIconWrapper>
      </OptionWrapper>
    );
  };

  const roleOptions: DropdownOption[] = roles.map((role) => {
    if (!role.renderOption) {
      role.renderOption = (option: any) => renderOption(option);
    }
    role.selected = role.value === value;

    return role;
  });

  return (
    <Wrapper data-testid="role-select">
      <Dropdown
        value={value}
        options={roleOptions}
        className="user-role-picker"
        placeholder={placeholder}
        onChange={(role) =>
          onDelete && role === 'delete' ? onDelete(role) : onChange(role)
        }
        menuStyle={{ marginTop: '4px', padding: '8px 0', ...menuStyle }}
        selectStyle={selectStyle}
        menuWidth={menuWidth}
        align={align}
        inputType={inputType}
        light={light}
        disabled={disabled}
      />
    </Wrapper>
  );
};
