import { clearStorage, loadState, saveState } from './storage';

const storage = sessionStorage;
const STORAGE_KEY = 'filters';

export function loadFromStorage(id) {
  if (!id) {
    return undefined;
  }

  const savedState = loadState(storage, STORAGE_KEY, id);

  if (!savedState || !savedState.filters) {
    return undefined;
  }

  return savedState.filters;
}

export function saveToStorage(id, filters) {
  if (!id) {
    return undefined;
  }

  const allSavedFilters = loadState(storage, STORAGE_KEY) || {};

  const newState = allSavedFilters?.[id]
    ? { ...allSavedFilters, [id]: { ...allSavedFilters[id], filters } }
    : { ...allSavedFilters, [id]: { filters } };

  // hiboo__filters {
  //   [id1]: {
  //     filters: [...]
  //   },
  //   [id2]: {
  //     filters: [...]
  //   },
  // }
  saveState(storage, STORAGE_KEY, newState);
}

export function removeFromStorage() {
  clearStorage(storage, STORAGE_KEY);
}
