import colors from 'constants/colors';
import styled from 'styled-components';

export const ItemLabelSeparator = styled.span`
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 4px;
    background: ${colors.gray500};
    vertical-align: middle;
`;

export const ItemLabel = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.gray850};
    display: inline;
`;

export const ItemDate = styled.span`
    font-size: 11px;
    font-weight: 400;
    color: ${colors.gray500};
`;
