import colors from 'constants/colors';
import hexToRgba from 'hex-to-rgba';
import type { CategoryType } from './Category.type';

export function buildCustomStyle(hasError = false) {
  return {
    control: (provided: any, state: any) => ({
      ...provided,
      background: colors.gray50,
      border: `0.5px solid ${
        hasError
          ? colors.magenta
          : state.isFocused
            ? colors.blue100
            : colors.gray300
      }`,
      boxShadow:
        hasError || state.isFocused
          ? `inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 0 0.15rem ${hexToRgba(
            hasError ? colors.magenta : colors.blue400,
            0.25,
          )}`
          : 0,
      cursor: 'text',
      height: '32px',
      minHeight: '32px',
      transition:
        'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',

      '&:hover': {
        border: `0.5px solid ${
          hasError
            ? colors.magenta
            : state.isFocused
              ? colors.blue100
              : colors.gray300
        }`,
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: colors.gray500,
      fontSize: '14px',
      lineHeight: '18px',
      overflow: 'hidden',
      paddingRight: '16px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '0 4px',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      paddingTop: '4px',
      paddingBottom: '4px',
    }),
  };
}

export function buildCategoryOptions(categories: CategoryType[]) {
  const categoryOptions = categories.map((category) => {
    return {
      ...category,
      value: category.id,
      label: category.name,
      key: `${category.id}`,
    };
  });

  return categoryOptions;
}
