import { type IndicatorsContainerProps, components } from 'react-select';
import styled from 'styled-components';
import type { SingleSelectOption } from '../SingleSelectOption.type';

const IndicatorsContainerStyled = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

export const IndicatorsContainer = <T extends SingleSelectOption>(
  props: IndicatorsContainerProps<T>,
) => {
  return (
    <components.IndicatorsContainer {...props}>
      <IndicatorsContainerStyled>{props.children}</IndicatorsContainerStyled>
    </components.IndicatorsContainer>
  );
};
