import { Dot, StatusWrapper, Tag } from './SuccessFailureTag.css';
import type { SuccessFailurePropsType } from './types';

export const SuccessTag = ({
  label,
  nok,
  noPadding = false,
  noColor = false,
  smallFont = false,
  noMargin = false,
}: SuccessFailurePropsType) => {
  return (
    <Tag>
      <Dot green noMargin={noMargin} />
      <span style={{ fontSize: '14px' }}>{nok}</span>
      <StatusWrapper
        noPadding={noPadding}
        noColor={noColor}
        smallFont={smallFont}
      >
        {label}
      </StatusWrapper>
    </Tag>
  );
};
