import colors from 'constants/colors';

export type AnalyticsIconProps = {
  size?: number;
  color?: string;
};

export const AnalyticsIcon = ({
  size = 16,
  color = colors.gray500,
}: AnalyticsIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.5" y="1.5" width="13" height="13" rx="2.125" stroke={color} />
    <path
      d="M1.5 7.99999C2.66667 6.16666 5.6 3.59999 8 7.99999C10.4 12.4 13.3333 9.83333 14.5 7.99999"
      stroke={color}
    />
  </svg>
);
