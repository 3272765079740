import { Portal } from '@mui/material';
import { useCallback, useState } from 'react';
import { usePopper } from 'react-popper';

export const usePortal = (renderChildren: () => React.ReactNode) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'left-end',
  });
  const renderPortal = useCallback(() => {
    return (
      <Portal>
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: 1201 }}
          {...attributes.popper}
        >
          {renderChildren()}
        </div>
      </Portal>
    );
  }, [setPopperElement, styles, attributes, renderChildren]);

  return {
    renderPortal,
    setReferenceElement,
  };
};
