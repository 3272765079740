import moment, { type Moment } from 'moment';

export type DateRangePeriodType = {
  label: string;
  value: string;
  since: Moment | null;
  until: Moment | null;
};

export type DateRangePeriodType2 = {
  label: string;
  value: string;
  since: string | null;
  until: string | null;
};

export type PeriodKeys =
  | 'last7d'
  | 'last15d'
  | 'last30d'
  | 'last90d'
  | 'lastWeek'
  | 'lastMonth'
  | 'startOfWeek'
  | 'startOfMonth'
  | 'startOfYear'
  | 'currentWholeYear'
  | 'custom';

export const PeriodOptions: { [k in PeriodKeys]: DateRangePeriodType } = {
  last7d: {
    label: 'translation:common.periods.last7days',
    value: 'last7d',
    since: moment().utcOffset('+0000', true).subtract(7, 'days'),
    until: moment().utcOffset('+0000', true).subtract(1, 'days'),
  },
  last15d: {
    label: 'translation:common.periods.last15days',
    value: 'last15d',
    since: moment().utcOffset('+0000', true).subtract(15, 'days'),
    until: moment().utcOffset('+0000', true).subtract(1, 'days'),
  },
  last30d: {
    label: 'translation:common.periods.last30days',
    value: 'last30d',
    since: moment().utcOffset('+0000', true).subtract(30, 'days'),
    until: moment().utcOffset('+0000', true).subtract(1, 'days'),
  },
  last90d: {
    label: 'translation:common.periods.last90days',
    value: 'last90d',
    since: moment().utcOffset('+0000', true).subtract(90, 'days'),
    until: moment().utcOffset('+0000', true).subtract(1, 'days'),
  },
  lastWeek: {
    label: 'translation:common.periods.lastWeek',
    value: 'lastWeek',
    since: moment()
      .utcOffset('+0000', true)
      .subtract(1, 'weeks')
      .startOf('week'),
    until: moment().utcOffset('+0000', true).subtract(1, 'weeks').endOf('week'),
  },
  lastMonth: {
    label: 'translation:common.periods.lastMonth',
    value: 'lastMonth',
    since: moment()
      .utcOffset('+0000', true)
      .subtract(1, 'months')
      .startOf('month'),
    until: moment()
      .utcOffset('+0000', true)
      .subtract(1, 'months')
      .endOf('month'),
  },
  startOfWeek: {
    label: 'translation:common.periods.sinceWeekStart',
    value: 'startOfWeek',
    since: moment().utcOffset('+0000', true).startOf('week'),
    until: moment().utcOffset('+0000', true).subtract(1, 'days'),
  },
  startOfMonth: {
    label: 'translation:common.periods.sinceMonthStart',
    value: 'startOfMonth',
    since: moment().utcOffset('+0000', true).startOf('month'),
    until: moment().utcOffset('+0000', true).subtract(1, 'days'),
  },
  startOfYear: {
    label: 'translation:common.periods.sinceYearStart',
    value: 'startOfYear',
    since: moment().utcOffset('+0000', true).startOf('year'),
    until: moment().utcOffset('+0000', true).subtract(1, 'days'),
  },
  currentWholeYear: {
    label: 'translation:common.periods.currentWholeYear',
    value: 'currentWholeYear',
    since: moment().utcOffset('+0000', true).startOf('year'),
    until: moment().utcOffset('+0000', true).endOf('year'),
  },
  custom: {
    label: 'translation:common.periods.custom',
    value: 'custom',
    since: null,
    until: null,
  },
};

export const PeriodOptionsList: DateRangePeriodType[] =
  Object.values(PeriodOptions);
