import { isHibooUser } from 'modules/utils';
import { EventTypes } from 'redux-segment';

export const simpleActions = {
  chooseLanguage: (
    language: string,
    userId: number | null,
    email: string | null,
  ) => {
    const analytics = [] as any[];
    if (email && !isHibooUser(email)) {
      analytics.push({
        eventType: EventTypes.identify,
        eventPayload: {
          userId: `${userId}`,
          traits: {
            id: `${userId}`,
            language,
          },
        },
      });
    }

    return {
      type: 'CHOOSE_LANGUAGE' as const,
      language,
      meta: {
        analytics,
      },
    };
  },
};
