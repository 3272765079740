import { ASSET_CHRONOLOGY, type AssetChronologyAction } from '../actions';
import type {
  AssetZoneDateType,
  CategoryType,
  EnrichedAssetZoneDateType,
} from '../types';

export type AssetChronologyStateType = {
  allAddressCategories: CategoryType[];
  assetZoneDate: AssetZoneDateType[];
  enrichedAssetZoneDate: EnrichedAssetZoneDateType[];
  filters: { [key: string]: any };
  visibleColumns: string[];
};

const initialState: AssetChronologyStateType = {
  allAddressCategories: [],
  assetZoneDate: [],
  enrichedAssetZoneDate: [],
  filters: {},
  visibleColumns: [],
};

export default function assetChronologyReducer(
  // biome-ignore lint/style/useDefaultParameterLast: legacy
  state = initialState,
  action: AssetChronologyAction,
) {
  switch (action.type) {
    case ASSET_CHRONOLOGY.SET_VISIBLE_COLUMNS: {
      const { columns } = action;

      return { ...state, visibleColumns: columns };
    }

    case ASSET_CHRONOLOGY.FILTER: {
      const { filters } = action;

      return { ...state, filters };
    }

    default:
      return state;
  }
}
