import PropTypes from 'prop-types';
import { Component } from 'react';

import { Container, StyledLink } from './index.css';

class PageAction extends Component {
  renderIcon() {
    const { icon } = this.props;
    if (!icon) return null;
    return <this.props.icon style={{ marginRight: '8px', height: '18px' }} />;
  }

  render() {
    const { link, label, onClick, dataTestId, trackingLabel } = this.props;
    if (link?.length) {
      return (
        <StyledLink to={link} data-testid={dataTestId}>
          <Container>
            {this.renderIcon()}
            <span>{label}</span>
          </Container>
        </StyledLink>
      );
    }

    return (
      <Container
        data-testid={dataTestId}
        onClick={(e) => {
          analytics?.track(trackingLabel || label);
          onClick(e);
        }}
      >
        {this.renderIcon()}
        <span>{label}</span>
      </Container>
    );
  }
}

PageAction.propTypes = {
  label: PropTypes.string.isRequired,
  trackingLabel: PropTypes.string,
  icon: PropTypes.func,
  link: PropTypes.string,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
};

PageAction.defaultProps = {
  trackingLabel: '',
  icon: null,
  link: null,
  onClick: null,
  dataTestId: '',
};

export default PageAction;
