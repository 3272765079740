import type React from 'react';
import { useTranslation } from 'react-i18next';

import { ArchiveIcon, Category, ShareIcon, TruckIcon } from 'modules/common-ui';

import colors from 'constants/colors';
import type { TFunction } from 'i18next';
import type { AssetFound } from 'modules/global-search/models/AssetFound.model';
import {
  CategoryContainer,
  Label,
  LabelContainer,
  LogoContainer,
  ResultItemContainer,
  StyledLink,
  Subtitle,
} from './index.css';

export type AssetResultItemProps = {
  active?: boolean;
  data: AssetFound;
  id?: string;
  onClose: () => void;
  onMouseEnter?: () => void;
};

const getAssetIcon = (assetFound: AssetFound): JSX.Element => {
  if (assetFound.isArchived()) {
    return <ArchiveIcon size={17} />;
  }
  const { sharingInfos } = assetFound;
  if (sharingInfos?.isShared()) {
    return <ShareIcon size={17} color={colors.gray850} />;
  }
  return <TruckIcon size={17} />;
};

const getAssetSubtitleBase = (
  assetFound: AssetFound,
  t: TFunction<'globalSearch'>,
): string => {
  if (assetFound.isArchived()) {
    return t('results.archived');
  }

  const { sharingInfos } = assetFound;
  if (sharingInfos) {
    if (sharingInfos.isSharedByItsOwner()) {
      return t('results.sharedBy', {
        organizationName: sharingInfos.getOwnerOrganizationName(),
      });
    }
    if (sharingInfos.isSharedWithSomeBeneficiaries()) {
      return t('results.sharedWith', {
        organizationNames: sharingInfos.getBeneficiariesOrganizationNames(),
      });
    }
  }

  return t('results.types.asset');
};

export const AssetResultItem: React.FC<
  React.PropsWithChildren<AssetResultItemProps>
> = ({ active = false, data, id, onClose, onMouseEnter }) => {
  const { t } = useTranslation('globalSearch');

  let subtitle = getAssetSubtitleBase(data, t);
  if (data.make || data.model) {
    subtitle = `${subtitle} - ${data.make ?? ''} ${data.model ?? ''}`.trim();
  }

  return (
    <StyledLink
      id={id}
      onClick={onClose}
      onMouseEnter={onMouseEnter}
      to={`/assets/${data.id}/overview`}
    >
      <ResultItemContainer active={active}>
        <LogoContainer>{getAssetIcon(data)}</LogoContainer>
        <LabelContainer>
          <Label>{data.name}</Label>
          <Subtitle>{subtitle}</Subtitle>
        </LabelContainer>
        <CategoryContainer>
          {data.category ? (
            <Category
              category={{
                color: data.category.color!,
                name: data.category.name!,
                id: data.category.id!,
              }}
            />
          ) : null}
        </CategoryContainer>
      </ResultItemContainer>
    </StyledLink>
  );
};
