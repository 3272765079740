import colors from 'constants/colors';
import {
  AdministrationIcon,
  AppStoreIcon,
  DestinationsIcon,
  HelpIcon,
  IntegrationsIcon,
  LogoutIcon,
  ProfileIcon,
} from 'modules/common-ui';
import { disconnectUser } from 'modules/user/actions';
import { buildContext } from 'modules/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { Store } from 'reducers';
import { NavItem } from '../NavItem';
import { NavItemIcon } from '../NavItem/index.css';
import { NavigationToSharingCenter } from './NavigationToSharingCenter';
import { ExternalLink, Line, Logout, NavigationGroup } from './index.css';

export const BottomNavigation = ({
  displayDestinations,
}: {
  displayDestinations: boolean;
}) => {
  const [t] = useTranslation('sidenav');
  const navigate = useNavigate();
  const user = useSelector((state: Store) => state.user);
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(disconnectUser());

    analytics.track(
      'Signed Out',
      {
        first_name: user.firstName,
        last_name: user.lastName,
      },
      {
        context: buildContext(user),
      },
    );

    navigate('/login');
  };

  const isOrgAdmin = user?.adminOfOrganization;

  return (
    <>
      <NavigationGroup id="third-nav-part-container">
        <NavigationToSharingCenter />
        <NavItem
          label={t('sidenav.footer.integrations')}
          link="/integrations"
          testId="navigation-integrations"
          id="navigation-integrations"
          icon={IntegrationsIcon}
        />
        <NavItem
          label={t('sidenav.footer.catalog')}
          link="/catalog"
          testId="navigation-catalog"
          id="navigation-catalog"
          icon={AppStoreIcon}
        />
        <NavItem
          label={t('sidenav.footer.destinations')}
          link="/destinations"
          testId="navigation-destinations"
          id="navigation-destinations"
          icon={DestinationsIcon}
          display={displayDestinations}
        />
      </NavigationGroup>
      <NavigationGroup id="fourth-nav-part-container">
        <NavItem
          label={t('sidenav.footer.administration')}
          link="/administration/members"
          display={!!isOrgAdmin}
          testId="navigation-administration"
          id="navigation-administration"
          icon={AdministrationIcon}
        />
        <NavItem
          label={t('sidenav.footer.profile')}
          link="/profile"
          testId="navigation-profile"
          id="navigation-profile"
          icon={ProfileIcon}
        />
        <ExternalLink
          href={t('sidenav.footer.linkHelp')}
          target="_blank"
          data-testid="navigation-help"
        >
          <Line id="navigation-help">
            <NavItemIcon>
              <HelpIcon color={colors.gray850} size={18} />
            </NavItemIcon>
            {t('sidenav.footer.help')}
          </Line>
        </ExternalLink>
        <Logout data-testid="logout" onClick={logout}>
          <NavItemIcon>
            <LogoutIcon color={colors.gray850} size={18} />
          </NavItemIcon>
          {t('sidenav.footer.logout')}
        </Logout>
      </NavigationGroup>
    </>
  );
};
