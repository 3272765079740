import colors from 'constants/colors';
import { buildCommonStyle } from 'modules/common-ui/styles/Select/commonStyle';
import styled from 'styled-components';

type DropdownProps = {
  maxWidth?: number;
};

export const DropdownContainer = styled.div<DropdownProps>`
  margin-bottom: 12px;
  max-width: ${(props) => props.maxWidth}px;
`;

export const CheckIconWrapper = styled.div<{ visible?: boolean }>`
  position: absolute;
  right: 20px;
  ${(props) => props.visible === false && 'visibility: hidden'}
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OptionTextWrapper = styled.div`
  width: 100%;
`;

export const OptionLabel = styled.div<{ isDanger?: boolean }>`
  font-size: ${({ theme }) => theme.typology.font.size.s};
  line-height: ${({ theme }) => theme.typology.lineHeight.base};
`;

export const OptionSubline = styled.div`
  font-size: ${({ theme }) => theme.typology.font.size.xs};
  line-height: ${({ theme }) => theme.typology.lineHeight.small};
  color: ${colors.gray500};
`;

export const RoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.gray900};
  }
`;

type CustomStyleProps = {
  hasError?: boolean;
  maxMenuHeight?: string;
};

export function buildCustomStyle({
  hasError = false,
  maxMenuHeight = '400px',
}: CustomStyleProps) {
  const commonStyle = buildCommonStyle(hasError);
  return {
    ...commonStyle,
    menu: (provided: any) => ({
      ...provided,
      zIndex: 6,
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: maxMenuHeight,
    }),
    option: (provided: any, state: any) => {
      return {
        ...provided,
        ...commonStyle.option(provided, state),
        color: colors.gray850,
        fontSize: '14px',
        lineHeight: '18px',
        cursor: 'pointer',
      };
    },
    multiValue: (provided: any) => ({
      ...provided,
      fontSize: '12px',
      lineHeight: '14px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      cursor: 'text',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };
}
