import { useTranslation } from 'react-i18next';

import {
  Dropdown,
  type DropdownOption,
  RichMultiselect,
} from 'modules/common-ui';

import type { RichMultiselectOption } from '../../RichMultiselect/RichMultiselectOption.type';
import type { FilterOption } from '../FilterObject.type';
import { Header, HeaderAction, HeaderTitle, Wrapper } from './index.css';

type RowFilterObject = {
  type: string;
  value?: any;
  options?: FilterOption[];
  multiselect?: boolean;
};

type FilterRowMobileProps = {
  typeOptions: DropdownOption[];
  filter: RowFilterObject;
  onUpdate: (value: any) => void;
};

export const FilterRowMobile = ({
  filter,
  onUpdate,
  typeOptions,
}: FilterRowMobileProps) => {
  const { t } = useTranslation('commonUi');

  if (!filter || !filter.type) {
    return null;
  }

  const type = typeOptions.find((o) => o.value === filter.type);

  if (!type) {
    return null;
  }

  const { label } = type;
  const { value } = filter;

  const renderFilter = () => {
    const options: RichMultiselectOption[] = filter.options
      ? filter.options.map((option, index) => ({ key: `${index}`, ...option }))
      : [];

    return filter.multiselect ? (
      <RichMultiselect
        alignRight={false}
        options={options}
        onUpdate={onUpdate}
        values={value || []}
        label={label}
      />
    ) : (
      <Dropdown
        options={options}
        placeholder={t('filter.row.dropdownFilterPlaceholder')}
        onChange={onUpdate}
        value={value || ''}
        ellipsis={true}
      />
    );
  };

  const renderResetButton = () => {
    if (value?.length) {
      return <HeaderAction onClick={() => onUpdate([])}>Reset</HeaderAction>;
    }

    return null;
  };

  return (
    <Wrapper>
      <Header>
        <HeaderTitle>{label}</HeaderTitle>
        {renderResetButton()}
      </Header>
      <div>{renderFilter()}</div>
    </Wrapper>
  );
};
