import type React from 'react';

import colors from 'constants/colors';

export type FatCrossProps = {
  color?: string;
  size?: number;
};

export const FatCrossIcon: React.FC<React.PropsWithChildren<FatCrossProps>> = ({
  color = colors.gray850,
  size = 6,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.13727 3L5.84301 1.29426C6.05233 1.08494 6.05233 0.745568 5.84301 0.53608L5.46392 0.156989C5.2546 -0.0523295 4.91523 -0.0523295 4.70574 0.156989L3 1.86273L1.29426 0.156989C1.08494 -0.0523295 0.745568 -0.0523295 0.53608 0.156989L0.156989 0.53608C-0.0523295 0.745398 -0.0523295 1.08477 0.156989 1.29426L1.86273 3L0.156989 4.70574C-0.0523295 4.91506 -0.0523295 5.25443 0.156989 5.46392L0.53608 5.84301C0.745398 6.05233 1.08494 6.05233 1.29426 5.84301L3 4.13727L4.70574 5.84301C4.91506 6.05233 5.2546 6.05233 5.46392 5.84301L5.84301 5.46392C6.05233 5.2546 6.05233 4.91523 5.84301 4.70574L4.13727 3Z"
      fill={color}
    />
  </svg>
);
