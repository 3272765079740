import colors from 'constants/colors';
import { CheckIcon, EllipsisToolTip, Tooltip } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import {
  SelectedIconContainer,
  WorkspaceRowContainer,
  WorkspaceRowName,
  WorkspaceRowNbAssets,
} from './WorkspaceRow.css';

type WorkspaceRowProps = {
  id: number;
  name: string;
  nbAssets: number;
  selected?: boolean;
};
export const WorkspaceRow = ({
  id,
  name,
  nbAssets,
  selected,
}: WorkspaceRowProps) => {
  const [t] = useTranslation('sidenav');

  return (
    <WorkspaceRowContainer selected={selected}>
      <div id={`workspace-row-${id}`}>
        <EllipsisToolTip>
          <WorkspaceRowName
            data-tip={name}
            data-for={`workspace-row-name-tooltip-${id}`}
          >
            {name}
          </WorkspaceRowName>
        </EllipsisToolTip>
        <Tooltip id={`workspace-row-name-tooltip-${id}`} place="right" />
      </div>

      <WorkspaceRowNbAssets>
        {t('sidenav.picker.nbAssets', { count: nbAssets })}
      </WorkspaceRowNbAssets>
      {selected && (
        <SelectedIconContainer>
          <CheckIcon color={colors.gray850} />
        </SelectedIconContainer>
      )}
    </WorkspaceRowContainer>
  );
};
