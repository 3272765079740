import classNames from 'classnames';
import { FreemiumTooltip, FreemiumTooltip2, Tooltip } from 'modules/common-ui';
import React from 'react';
import type { ButtonProps } from './Button.props';
import {
  Container,
  ContainerWithButtonMargin,
  IconLeftWrapper,
  IconRightWrapper,
  StyledButton,
} from './index.css';

// remove useFreemiumTooltipV2 once migration is ok
export const Button = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<any>) => {
    const {
      id,
      dataTestId,
      block = false,
      className,
      disabled = false,
      iconLeft,
      iconRight,
      onClick,
      size = 'sm',
      useFreemiumTooltipV2 = false,
      freemiumTooltip,
      freemiumTooltipPlace = 'topRight',
      freemiumTooltipTitle,
      freemiumTooltipDescription,
      freemiumTooltipButtonLabel,
      tooltipId,
      tooltip,
      tooltipDelay,
      tooltipEffect,
      tooltipPlace,
      tooltipSmall,
      tooltipType,
      type = 'button',
      variant = 'outline-neutral',
    } = props;

    const classes = classNames(
      `button-${variant}`,
      block && 'button-block',
      className,
      disabled && 'button-disabled',
      size && `button-${size}`,
    );

    const renderButton = () => {
      return (
        <StyledButton
          id={id}
          block={block}
          className={classes}
          data-tip={tooltip}
          data-for={`react-tooltip-${tooltipId}`}
          disabled={disabled}
          onClick={onClick}
          ref={ref}
          size={size}
          type={type}
          variant={variant}
          data-testid={dataTestId}
          {...props}
        >
          {iconLeft && <IconLeftWrapper>{iconLeft}</IconLeftWrapper>}
          {props.children}
          {iconRight && <IconRightWrapper>{iconRight}</IconRightWrapper>}
        </StyledButton>
      );
    };

    if (freemiumTooltip && useFreemiumTooltipV2) {
      return (
        <ContainerWithButtonMargin>
          {renderButton()}
          <FreemiumTooltip2
            className="freemium-tooltip"
            place={freemiumTooltipPlace}
            title={freemiumTooltipTitle}
            description={freemiumTooltipDescription}
            buttonLabel={freemiumTooltipButtonLabel}
          />
        </ContainerWithButtonMargin>
      );
    }

    if (!!tooltip || freemiumTooltip) {
      return (
        <Container>
          {renderButton()}

          {tooltip && (
            <Tooltip
              id={`react-tooltip-${tooltipId}`}
              delayShow={tooltipDelay}
              effect={tooltipEffect}
              place={tooltipPlace}
              small={tooltipSmall}
              type={tooltipType}
            />
          )}
          {freemiumTooltip && !useFreemiumTooltipV2 && (
            <FreemiumTooltip
              className="freemium-tooltip"
              freemiumTooltipTitle={freemiumTooltipTitle}
              freemiumTooltipDescription={freemiumTooltipDescription}
              freemiumTooltipButtonLabel={freemiumTooltipButtonLabel}
            />
          )}
        </Container>
      );
    }

    return renderButton();
  },
);

Button.displayName = 'Button';

export default Button;
