import styled from 'styled-components';

type CardHeaderProps = {
  noPadding?: boolean;
  noMargin?: boolean;
};
export const CardHeader = styled.div<CardHeaderProps>`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  ${(props) => (props.noPadding ? 'padding: 0px;' : '')};
  ${(props) => (props.noMargin ? 'margin: 0px;' : '')};
`;
