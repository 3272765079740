import colors from 'constants/colors';

export type ProfileIconProps = {
  size?: number;
  color?: string;
};

export const ProfileIcon = ({
  size = 16,
  color = colors.gray500,
}: ProfileIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="5" r="2" stroke={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.39 12.5C12.8804 10.2178 10.6609 8.5 8 8.5C5.33915 8.5 3.11962 10.2178 2.61002 12.5C2.53787 12.8231 2.5 13.1575 2.5 13.5H3.6C3.6 11.2909 5.56995 9.5 8 9.5C10.4301 9.5 12.4 11.2909 12.4 13.5H13.5C13.5 13.1575 13.4621 12.8231 13.39 12.5Z"
      fill={color}
      stroke="none"
    />
    <line x1="3" y1="13" x2="13" y2="13" stroke={color} />
  </svg>
);
