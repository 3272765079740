import colors from 'constants/colors';
import { resizeAnimation } from 'modules/common-ui';
import { useState } from 'react';
import styled from 'styled-components';
import debounce from 'utils/debounce';
import { SearchAndLoadOptionsActions } from './SearchAndLoadOptionsActions';
import { SearchAndLoadOptionsList } from './SearchAndLoadOptionsList';
import { SearchAndLoadOptionsShowMore } from './SearchAndLoadOptionsShowMore';
import type { SearchAndLoadMultiselectOption } from './types';

const OptionsContainer = styled.div`
  margin-top: 2px;
  position: absolute;
  background: ${colors.white};
  padding: 8px;
  min-width: 225px;
  border: 1px solid ${colors.gray300};
  border-radius: 5px;
  padding: 0 0 8px 0;
  box-shadow: 0 9px 24px rgba(15, 15, 15, 0.2), 0 3px 6px rgba(15, 15, 15, 0.1),
    0 0 0 rgba(15, 15, 15, 0.05);
  animation-name: ${() => resizeAnimation};
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in;
  right: 8px;
  z-index: 2;
`;

type SearchAndLoadOptionsProps = {
  onUpdate: (value: any) => void;
  options: SearchAndLoadMultiselectOption[];
  values: SearchAndLoadMultiselectOption[];
};

export const SearchAndLoadOptions = ({
  options,
  values,
  onUpdate,
}: SearchAndLoadOptionsProps) => {
  const LIST_DEFAULT_SIZE = 9;
  const [searchText, setSearchText] = useState('');
  const [userHasInteractedAtLeastOnce, setUserHasInteractedAtLeastOnce] =
    useState(false);
  const [listSize, setListSize] = useState(LIST_DEFAULT_SIZE);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const showSelection =
    userHasInteractedAtLeastOnce === false && values.length > 0;

  const onChangeSearch = (value: string) => {
    setSearchText(value);
    filterOptions(value);
    setListSize(LIST_DEFAULT_SIZE);
    setUserHasInteractedAtLeastOnce(true);
  };

  const _onUpdate = (value: any) => {
    setUserHasInteractedAtLeastOnce(true);
    return onUpdate(value);
  };

  const filterOptions = debounce((value: string) => {
    if (!value || !value.length) {
      setFilteredOptions(options);

      return;
    }
    const filteredOptions = options.filter(
      (o) => o.label.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    );

    setFilteredOptions(filteredOptions);
  }, 200);

  return (
    <OptionsContainer>
      <>
        <SearchAndLoadOptionsActions
          showSelection={showSelection}
          nbSelected={values.length}
          searchText={searchText}
          options={filteredOptions}
          onUpdate={_onUpdate}
          onSearch={onChangeSearch}
        />
        <SearchAndLoadOptionsList
          listOptions={showSelection ? values : filteredOptions}
          selectedOptions={values}
          listSize={listSize}
          onUpdate={onUpdate}
          setUserHasInteractedAtLeastOnce={setUserHasInteractedAtLeastOnce}
        />
        <SearchAndLoadOptionsShowMore
          listOptions={showSelection ? values : filteredOptions}
          listDefaultSize={LIST_DEFAULT_SIZE}
          listSize={listSize}
          setListSize={setListSize}
        />
      </>
    </OptionsContainer>
  );
};
