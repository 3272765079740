import type React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Content,
  Header,
  HeaderLeft,
  HeaderRight,
  HeaderTitle,
  Wrapper,
} from './index.css';

type DrawerPropTypes = {
  children: React.ReactNode;
  customHeader?: React.ReactNode;
  fixedHeader?: boolean;
  headerStyle?: React.CSSProperties;
  isOpen?: boolean;
  onDone?: () => void;
  title?: string;
};

export const Drawer = ({
  children,
  customHeader,
  fixedHeader = false,
  headerStyle,
  isOpen = false,
  onDone,
  title,
}: DrawerPropTypes) => {
  const { t } = useTranslation('commonUi');

  const renderHeader = () => {
    if (customHeader) {
      return customHeader;
    }

    return (
      <Header isFixed={fixedHeader} style={headerStyle}>
        <HeaderLeft>
          <HeaderTitle>{title}</HeaderTitle>
        </HeaderLeft>
        <HeaderRight onClick={onDone}>
          {t('translation:common.done')}
        </HeaderRight>
      </Header>
    );
  };

  return (
    <Wrapper className={isOpen ? 'open' : ''}>
      {renderHeader()}
      <Content withHeaderPadding={fixedHeader}>{children}</Content>
    </Wrapper>
  );
};
