import type React from 'react';
import ReactModal from 'react-modal';

import colors from 'constants/colors';

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSubTitle,
} from './index.css';
import './override.css';

// Type declarations
type DefaultStyleType = {
  content?: React.CSSProperties;
  overlay?: React.CSSProperties;
};

type HModalProps = {
  children: React.ReactNode;
  closeTimeoutMS?: number;
  contentLabel?: string;
  isOpen: boolean;
  onAfterOpen?: () => void;
  onRequestClose?: () => void;
  overlay?: boolean;
  shouldCloseOnOverlayClick?: () => void;
  style?: React.CSSProperties;
};

// Default style for modal
const defaultStyle: DefaultStyleType = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    background: colors.white,
    border: '0.5px solid #E3E8EE',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    padding: 'none',
    minWidth: '356px',
    overflowWrap: 'anywhere',
  },
};

export const HModal = (props: HModalProps) => {
  const {
    isOpen,
    onAfterOpen,
    onRequestClose,
    overlay = true,
    style,
    contentLabel = 'Default',
    children,
    closeTimeoutMS = 100,
  } = props;

  let { content } = defaultStyle;

  if (style) {
    content = { ...content, ...style };
  }

  const xstyle = {
    overlay: overlay
      ? defaultStyle.overlay
      : { backgroundColor: 'transparent' },
    content,
  };

  return (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={closeTimeoutMS}
      contentLabel={contentLabel}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      style={xstyle}
    >
      {children}
    </ReactModal>
  );
};

HModal.Header = ModalHeader;
HModal.SubTitle = ModalSubTitle;
HModal.Body = ModalBody;
HModal.Footer = ModalFooter;

export default HModal;
