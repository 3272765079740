import colors from 'constants/colors';
import styled, { css, keyframes } from 'styled-components';

export const Tag = styled.div<{
  bgColor: string;
  big?: boolean;
  fullSize?: boolean;
  disabled?: boolean;
  selectable?: boolean;
  dashedColor?: string;
}>`
  grid-row: 1;
  grid-column: 1;
  width: min-content;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 3px;
  color: ${colors.gray850};
  display: flex;
  line-height: ${(props) => (props.big ? '26px' : '14px')};
  max-width: ${(props) => (props.fullSize ? '100%' : '180px')};
  padding: 2px 6px;
  position: relative;
  user-select: none;

  ${(props) => (props.disabled ? 'opacity: 0.5;' : '')}
  ${(props) => props.selectable && 'cursor: pointer;'}
  ${(props) =>
    props.dashedColor &&
    `
    border: 1px dashed ${props.dashedColor};
    &:hover {
      border: 1px solid ${props.dashedColor};
    }
  `}
`;

const baseText = css`
  font-weight: 400;
  max-width: inherit;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Text = styled.div<{ big?: boolean; showTransition?: boolean }>`
  font-size: ${(props) => (props.big ? '14px' : '12px')};
  ${(props) =>
    props.showTransition
      ? css`
          animation: ${fadeIn} 0.3s ease-in;
        `
      : ''}
  ${baseText}
`;

export const OptionText = styled.option<{ big: boolean }>`
  font-size: ${(props) => (props.big ? '14px' : '12px')};
  ${baseText}
`;
