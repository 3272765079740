import Menu from '@mui/material/Menu';
import colors from 'constants/colors';
import styled from 'styled-components';

export const StyledMenu = styled(Menu).attrs({
  elevation: 0,
})`
  .MuiPaper-root {
    background: ${colors.white};
    border: 1px solid ${colors.gray300};
    border-radius: 5px;
    box-shadow: 0 9px 24px rgba(15, 15, 15, 0.2), 0 3px 6px rgba(15, 15, 15, 0.1),
      0 0 0 rgba(15, 15, 15, 0.05);

    .MuiMenu-list {
      padding: 8px 0px;
      min-width: 200px;
    }

    .MuiMenuItem-root {
      cursor: pointer;
      
      padding: 8px 5px;
      
      font-family: Inter;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: ${colors.gray850};

      transition: background 80ms ease-in 0s;
      :hover {
        background: rgba(45, 47, 48, 0.08);
      }
      :active {
        background: ${colors.grayHover};
      }
    }
  }
`;
