import colors from 'constants/colors';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
`;

export const SideDrawerControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const SideDrawerActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ActionRow = styled.div<{ isSticky: boolean }>`
  background-color: ${colors.offWhite};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: sticky;
  top: 0;
  z-index: 2;
    ${(props) =>
      props.isSticky &&
      `
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
        background-color: white;
      `}
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${colors.gray850};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
`;
