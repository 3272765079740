import * as Sentry from '@sentry/browser';
import type { TFunction } from 'i18next';
import type { Notification } from '../models/Notification';
import { buildExportCompletedContent } from './exportNotificationContent';

type ParsedNotification = {
  id: number;
  title: React.ReactNode;
  content: React.ReactNode;
};

type NotificationParserFunc = (
  notification: Notification,
  t: TFunction,
) => ParsedNotification;

const notificationTypeMap: Record<string, NotificationParserFunc> = {
  EXPORT_COMPLETED: (notification, t) => ({
    id: notification.id,
    title: t('notifications:export_completed.title'),
    content: buildExportCompletedContent(notification.message),
  }),
  EXPORT_FAILED: (notification, t) => ({
    id: notification.id,
    title: t('notifications:export_failed.title'),
    content: t('notifications:export_failed.content'),
  }),
};

export const parseNotification = (notification: Notification, t: TFunction) => {
  const builder = notificationTypeMap[notification.type];

  if (!builder) {
    const error = new Error(
      `Unknown notification builder for type: ${notification.type}`,
    );
    console.error(error);
    Sentry.captureException(error);
    return null;
  }

  return builder(notification, t);
};
