import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MdClose from 'react-icons/lib/md/close';

import {
  Dropdown,
  type DropdownOption,
  RichMultiselect,
  SearchAndLoadMultiselect,
} from 'modules/common-ui';

import type { RichMultiselectOption } from '../RichMultiselect/RichMultiselectOption.type';
import type { FilterOption } from './FilterObject.type';
import {
  ColumnLeft,
  ColumnRight,
  DeleteColumn,
  DeleteIconWrapper,
  FilterColumns,
  Row,
} from './index.css';

type RowFilterObject = {
  type: string;
  value?: any;
  options?: FilterOption[];
  multiselect?: boolean;
};

type FilterRowProps = {
  typeOptions: DropdownOption[];
  filter: RowFilterObject;
  onUpdateFilterType: (type: string) => void;
  onUpdate: (value: any) => void;
  deleteFilter: () => void;
  tooltipId?: string;
  alignRight?: boolean;
  horizontalPadding?: boolean;
};

export const FilterRow = ({
  typeOptions,
  filter,
  onUpdateFilterType,
  onUpdate,
  deleteFilter,
  tooltipId,
  alignRight = false,
  horizontalPadding = true,
}: FilterRowProps) => {
  const canCloseFilter = filter?.type;

  const { t } = useTranslation('commonUi');

  const options: RichMultiselectOption[] = useMemo(() => {
    return filter.options
      ? filter.options.map((option, index) => ({
          key: `${index}`,
          ...option,
        }))
      : [];
  }, [filter.options]);

  const renderFilter = () => {
    if (!filter.type) {
      return;
    }

    if (filter.type === 'assetNames') {
      return (
        <SearchAndLoadMultiselect
          values={filter.value || []}
          options={options}
          onUpdate={onUpdate}
        />
      );
    }

    return filter.multiselect ? (
      <RichMultiselect
        alignRight={alignRight}
        options={options}
        onUpdate={onUpdate}
        values={filter.value || []}
      />
    ) : (
      <Dropdown
        options={options}
        placeholder={t('filter.row.dropdownFilterPlaceholder')}
        onChange={onUpdate}
        value={filter.value || ''}
        ellipsis={true}
      />
    );
  };

  return (
    <Row>
      <FilterColumns>
        <ColumnLeft horizontalPadding={horizontalPadding}>
          {typeOptions.length && (
            <Dropdown
              options={typeOptions}
              placeholder={t('filter.row.options.placeholder')}
              onChange={onUpdateFilterType}
              value={filter.type}
              ellipsis={true}
              menuStyle={{ maxHeight: '405px' }}
            />
          )}
        </ColumnLeft>
        <ColumnRight>{renderFilter()}</ColumnRight>
      </FilterColumns>
      {canCloseFilter && (
        <DeleteColumn>
          <DeleteIconWrapper
            data-for={tooltipId}
            data-tip={t('filter.row.delete.tooltip')}
            onClick={deleteFilter}
          >
            <MdClose />
          </DeleteIconWrapper>
        </DeleteColumn>
      )}
    </Row>
  );
};
