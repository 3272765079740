import styled from 'styled-components';

export type CardSectionProps = {
  bigPaddingLeft?: boolean;
  noPadding?: boolean;
  noBorder?: boolean;
};

export const CardSection = styled.div<CardSectionProps>`
  ${(props) => (props.noBorder ? '' : 'border-top: 1px solid #d5d8db;')};
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  ${(props) =>
    props.bigPaddingLeft ? 'padding-left: 22px;' : 'padding-left: 16px;'};
  ${(props) => (props.noPadding ? 'padding: 0px;' : '')};
  gap: 6px;
`;
