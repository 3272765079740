import type { AssetFilterOptions } from 'modules/analytics/components/AssetFilter/types';
import type { FleetSegment } from 'modules/common-ui/models/FleetSegment';
import type { UserStore } from 'modules/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Beta } from '../Badge';
import { Drawer } from '../Drawer';
import { Header, HeaderRight } from '../Drawer/index.css';
import { TabList } from '../Tabs';
import type { FilterObject, FilterOption } from './FilterObject.type';
import { FilterRowMobile } from './FilterRowMobile';
import { SegmentList } from './SegmentList';
import type { SelectedFilter } from './SelectedFilter.type';
import {
  BetaLabelled,
  CustomHeaderLeftMobile,
  CustomHeaderTitleMobile,
  FilterButton,
  FilterRowMobileWrapper,
  FiltersToolbar,
  MobileHeaderResetAllAction,
  TabItem,
} from './index.css';

const TabbedHeader = ({
  visibleTabIndex,
  onTabChange,
}: {
  visibleTabIndex: number | false;
  onTabChange: (newVisibleTabIndex: number) => void;
}) => {
  const { t } = useTranslation('commonUi');
  return (
    <Header isFixed>
      <TabList
        visibleTabIndex={visibleTabIndex}
        onTabChange={onTabChange}
        tabLabels={[
          <TabItem>
            <BetaLabelled>
              {t('filter.tab.segments')} <Beta />
            </BetaLabelled>
          </TabItem>,
          <TabItem>{t('filter.tab.filters')}</TabItem>,
        ]}
      />
    </Header>
  );
};

const TabbedFilterMobile = ({
  display,
  setDisplay,
  selectedFilters,
  updateFilterValue,
  computeFilterObject,
  computeFilterTypeOptions,
  resetFilters,
  segments,
  activeSegment,
  applySegment,
}: {
  display: boolean;
  setDisplay: (display: boolean) => void;
  selectedFilters: SelectedFilter[];
  updateFilterValue: (index: number, value: any) => void;
  computeFilterObject: (selectedFilter: any) => any;
  computeFilterTypeOptions: (selectedFilter: SelectedFilter) => FilterOption[];
  resetFilters: () => void;
  segments: FleetSegment[];
  activeSegment: FleetSegment | null;
  applySegment: (segment: FleetSegment) => void;
}) => {
  const { t } = useTranslation('commonUi');
  const [visibleTabIndex, setVisibleTabIndex] = useState<number | false>(false);

  useEffect(() => {
    // User already changed the tab index
    if (visibleTabIndex !== false) {
      return;
    }

    if (!segments || segments.length === 0) {
      return;
    }

    const hasCustomSegment =
      segments.filter((segment) => !segment.isHiboo).length > 0;

    setVisibleTabIndex(hasCustomSegment ? 0 : 1);
  }, [segments, visibleTabIndex]);

  const userIsAdmin = useSelector((state: { user: UserStore }) => {
    const { user } = state;
    return user && (user.admin || user.adminOfOrganization);
  });

  return (
    <Drawer
      customHeader={
        <TabbedHeader
          visibleTabIndex={visibleTabIndex}
          onTabChange={setVisibleTabIndex}
        />
      }
      fixedHeader
      isOpen={display}
    >
      <div role="tabpanel" hidden={visibleTabIndex !== 0}>
        <SegmentList
          activeSegment={activeSegment}
          segments={segments}
          applySegment={(segment) => {
            applySegment(segment);
            setDisplay(false);
          }}
          userIsAdmin={userIsAdmin}
          isMobile={true}
          limitHeight={false}
        />
      </div>

      <div role="tabpanel" hidden={visibleTabIndex !== 1}>
        <FiltersToolbar>
          <FilterButton onClick={() => resetFilters()}>
            {t('translation:common.resetAll')}
          </FilterButton>
          <FilterButton onClick={() => setDisplay(false)}>
            {t('translation:common.done')}
          </FilterButton>
        </FiltersToolbar>
        <FilterRowMobileWrapper>
          {selectedFilters.map((filter, index) => (
            <FilterRowMobile
              key={`Filter-${filter.type}-mobile`}
              onUpdate={(value: any) => {
                updateFilterValue(index, value);
              }}
              filter={computeFilterObject(filter)}
              typeOptions={computeFilterTypeOptions(filter)}
            />
          ))}
        </FilterRowMobileWrapper>
      </div>
    </Drawer>
  );
};

type FilterMobileProps = {
  display: boolean;
  setDisplay: (display: boolean) => void;
  filters: FilterObject[];
  appliedFilters: AssetFilterOptions;
  selectedFilters: SelectedFilter[];
  setSelectedFilters: (filters: SelectedFilter[]) => void;
  resetFilters: () => void;
  updateFilterValue: (index: number, value: any) => void;
  computeFilterTypeOptions: (selectedFilter: SelectedFilter) => FilterOption[];
  computeFilterObject: (selectedFilter: any) => any;
  segments: FleetSegment[] | undefined;
  activeSegment: FleetSegment | null;
  applySegment: (segment: FleetSegment) => void;
};
export const FilterMobile = ({
  display,
  setDisplay,
  filters,
  appliedFilters,
  selectedFilters,
  setSelectedFilters,
  resetFilters,
  updateFilterValue,
  computeFilterObject,
  computeFilterTypeOptions,
  segments,
  activeSegment,
  applySegment,
}: FilterMobileProps) => {
  const { t } = useTranslation('commonUi');

  useEffect(() => {
    // for reset purpose, set appliedFilters to {}
    if (appliedFilters && !Object.keys(appliedFilters).length) {
      setSelectedFilters([{ type: '' }]);
    }

    const filtersMap = filters.map(({ type }) => {
      if (appliedFilters[type as keyof AssetFilterOptions]) {
        return {
          type,
          value: appliedFilters[type as keyof AssetFilterOptions],
        };
      }

      return {
        type,
        value: '',
      };
    });

    setSelectedFilters(filtersMap);
  }, [appliedFilters, filters, setSelectedFilters]);

  if (!segments) {
    const renderMobileHeader = () => {
      return (
        <Header isFixed>
          <CustomHeaderLeftMobile>
            <MobileHeaderResetAllAction onClick={() => resetFilters()}>
              {t('translation:common.resetAll')}
            </MobileHeaderResetAllAction>
            <CustomHeaderTitleMobile>
              {t('filter.button.labelWithCount', { count: 0 })}
            </CustomHeaderTitleMobile>
          </CustomHeaderLeftMobile>
          <HeaderRight onClick={() => setDisplay(false)}>
            {t('translation:common.done')}
          </HeaderRight>
        </Header>
      );
    };

    return (
      <Drawer customHeader={renderMobileHeader()} fixedHeader isOpen={display}>
        <FilterRowMobileWrapper>
          {selectedFilters.map((filter, index) => (
            <FilterRowMobile
              key={`Filter-${filter.type}-mobile`}
              onUpdate={(value: any) => {
                updateFilterValue(index, value);
              }}
              filter={computeFilterObject(filter)}
              typeOptions={computeFilterTypeOptions(filter)}
            />
          ))}
        </FilterRowMobileWrapper>
      </Drawer>
    );
  }
  return (
    <TabbedFilterMobile
      display={display}
      setDisplay={setDisplay}
      selectedFilters={selectedFilters}
      updateFilterValue={updateFilterValue}
      computeFilterTypeOptions={computeFilterTypeOptions}
      computeFilterObject={computeFilterObject}
      resetFilters={resetFilters}
      segments={segments}
      activeSegment={activeSegment}
      applySegment={applySegment}
    />
  );
};
