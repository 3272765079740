import type React from 'react';
import MediaQuery from 'react-responsive';

import {
  BREAKPOINT_DESKTOP_START,
  BREAKPOINT_MOBILE_END,
} from 'constants/breakpoints';

export type ResponsiveProps = {
  desktop?: React.ComponentType<React.PropsWithChildren<any>>;
  mobile?: React.ComponentType<React.PropsWithChildren<any>>;
};

export const Responsive = <P extends object>({
  desktop,
  mobile,
  ...props
}: P & ResponsiveProps) => {
  const DesktopComponent = desktop;
  const MobileComponent = mobile;

  return (
    <>
      {DesktopComponent && (
        <MediaQuery minWidth={BREAKPOINT_DESKTOP_START}>
          <DesktopComponent {...props} />
        </MediaQuery>
      )}
      {MobileComponent && (
        <MediaQuery maxWidth={BREAKPOINT_MOBILE_END}>
          <MobileComponent {...props} />
        </MediaQuery>
      )}
    </>
  );
};
