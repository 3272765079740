import { useCallback, useState } from 'react';
import { GlobalHotKeys, configure } from 'react-hotkeys';

import { useTranslation } from 'react-i18next';

import { SearchIcon } from 'modules/common-ui';
import { GlobalSearchModal } from '../GlobalSearchModal';
import { Container } from './index.css';

configure({
  /**
   * The HTML tags that React HotKeys should ignore key events from. This only works
   * if you are using the default ignoreEventsCondition function.
   * @type {String[]}
   */
  ignoreTags: [
    // 'input',
    'select',
    'textarea',
  ],
});

export const GlobalSearchButton = () => {
  const { t } = useTranslation('globalSearch');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const activate = useCallback((event: any) => {
    event.preventDefault();
    setIsModalOpen(true);
  }, []);

  return (
    <GlobalHotKeys
      keyMap={{ GLOBAL_SEARCH: ['command+k', 'ctrl+k'] }}
      handlers={{ GLOBAL_SEARCH: (e) => activate(e) }}
    >
      <Container onClick={toggleModal} id="global-search">
        <SearchIcon size={16} />
        {t('button.label')}
      </Container>
      <GlobalSearchModal isOpen={isModalOpen} onClose={closeModal} />
    </GlobalHotKeys>
  );
};
