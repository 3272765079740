import type React from 'react';

export type ImportIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
};

export const ImportIcon = ({ size, color, style }: ImportIconProps) => (
  <svg
    fill={color || 'currentColor'}
    height={size || 16}
    style={style}
    viewBox="0 0 16 16"
    width={size || 16}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="7.25" y="3" width="1.25" height="6.25" rx="0.625" stroke="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.40533 10.3169C8.11244 10.561 7.63756 10.561 7.34467 10.3169L4.34467 7.81694C4.05178 7.57286 4.05178 7.17714 4.34467 6.93306C4.63756 6.68898 5.11244 6.68898 5.40533 6.93306L7.875 8.99112L10.3447 6.93306C10.6376 6.68898 11.1124 6.68898 11.4053 6.93306C11.6982 7.17714 11.6982 7.57286 11.4053 7.81694L8.40533 10.3169Z"
      stroke="none"
    />
    <rect
      x="3.5"
      y="11.75"
      width="8.75"
      height="1.25"
      rx="0.625"
      stroke="none"
    />
  </svg>
);
