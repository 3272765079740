import colors from 'constants/colors';
import styled from 'styled-components';

export const MetricContainer = styled.div<{ size?: 'small' | 'big' }>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.size === 'big' &&
    `
    ${MetricTitle} {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
    }
    ${MetricSubtitle} {
      font-size: 12px;
      line-height: 14px;
    }
  `}
  ${(props) =>
    props.size === 'small' &&
    `
    ${MetricSubtitle} {
      font-size: 12px;
      line-height: 14px;
    }
  `}
`;

export const MetricTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;

export const MetricWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const MetricSubtitle = styled.div`
  color: ${colors.gray500};
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
`;
