import colors from 'constants/colors';

export type RefreshIconProps = {
  size?: number;
  color?: string;
};

export const RefreshIcon = ({
  size = 16,
  color = colors.gray500,
}: RefreshIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 7.5C3.5 7.22386 3.27614 7 3 7C2.72386 7 2.5 7.22386 2.5 7.5H3.5ZM13.5 7.5C13.5 10.2614 11.2614 12.5 8.5 12.5V13.5C11.8137 13.5 14.5 10.8137 14.5 7.5H13.5ZM8.5 12.5C5.73858 12.5 3.5 10.2614 3.5 7.5H2.5C2.5 10.8137 5.18629 13.5 8.5 13.5V12.5ZM8.5 2.5C11.2614 2.5 13.5 4.73858 13.5 7.5H14.5C14.5 4.18629 11.8137 1.5 8.5 1.5V2.5ZM4.06295 5.19263C4.89747 3.59146 6.57169 2.5 8.5 2.5V1.5C6.18447 1.5 4.17616 2.8118 3.17617 4.73045L4.06295 5.19263Z"
      fill={color}
      stroke="none"
    />
    <path
      d="M3.5 2.3999C3.5 2.12376 3.27614 1.8999 3 1.8999C2.72386 1.8999 2.5 2.12376 2.5 2.3999H3.5ZM3 5.3999H2.5V5.8999H3V5.3999ZM6 5.8999C6.27614 5.8999 6.5 5.67604 6.5 5.3999C6.5 5.12376 6.27614 4.8999 6 4.8999V5.8999ZM2.5 2.3999V5.3999H3.5V2.3999H2.5ZM3 5.8999H6V4.8999H3V5.8999Z"
      fill={color}
      stroke="none"
    />
  </svg>
);
