import type { PeriodKeys } from 'modules/common-ui';
import moment from 'moment';
import type React from 'react';
import { createContext, useCallback, useContext, useState } from 'react';

export type ReportPeriod = {
  periodShortcutValue: PeriodKeys;
  period: {
    since: Date;
    until: Date;
  };
};

const defaultReportPeriod: ReportPeriod = {
  periodShortcutValue: 'last7d',
  period: {
    since: moment().subtract(7, 'days').startOf('day').toDate(),
    until: moment().subtract(1, 'day').endOf('day').toDate(),
  },
};

type ReportPeriodContextType = {
  getReportPeriod: () => ReportPeriod;
  setReportPeriod: (reportPeriod: ReportPeriod) => void;
};

const initialPeriods: ReportPeriodContextType = {
  getReportPeriod: () => defaultReportPeriod,
  setReportPeriod: (_reportPeriod: ReportPeriod) => {},
};

const PersistentReportPeriodContext = createContext(initialPeriods);

export const PersistentReportPeriodProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [reportPeriod, setReportPeriod] =
    useState<ReportPeriod>(defaultReportPeriod);

  const getReportPeriod = useCallback(() => {
    return reportPeriod;
  }, [reportPeriod]);

  return (
    <PersistentReportPeriodContext.Provider
      value={{ getReportPeriod, setReportPeriod }}
    >
      {children}
    </PersistentReportPeriodContext.Provider>
  );
};

export const usePersistedReportPeriod = () => {
  return useContext(PersistentReportPeriodContext);
};
