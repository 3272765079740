import colors from 'constants/colors';

export type ZonesIconProps = {
  size?: number;
  color?: string;
};

export const ZonesIcon = ({
  size = 16,
  color = colors.gray500,
}: ZonesIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="5" height="5" stroke={color} />
    <rect x="2" y="9" width="5" height="5" stroke={color} />
    <rect x="9" y="2" width="5" height="5" stroke={color} />
    <rect x="9" y="9" width="5" height="5" stroke={color} />
  </svg>
);
