import colors from 'constants/colors';

export type AlertIconProps = {
  size?: number;
  color?: string;
};

export const AlertIcon = ({
  size = 16,
  color = colors.gray500,
}: AlertIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 7.10183V3.59728L8 1.05098L13.5 3.59728V7.10183C13.5 9.74429 12.1615 12.2069 9.94408 13.6441L8 14.9042L6.05592 13.6441C3.8385 12.2069 2.5 9.74429 2.5 7.10183Z"
      stroke={color}
    />
    <path d="M8 4V9" stroke={color} />
    <path d="M8 10V11" stroke={color} />
  </svg>
);
