import type { GraphQLUser } from './user';

// copied from api Integration model
export const INTEGRATION_STATUS = {
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  DATA_NOT_FLOWING: 'DATA_NOT_FLOWING',
  DATA_FLOWING: 'DATA_FLOWING',
  ERROR: 'ERROR', // Error, data not flowing
  ERROR_CREDENTIALS: 'ERROR_CREDENTIALS', // Invalid credentials
  ERROR_BUT_DATA_FLOWING: 'ERROR_BUT_DATA_FLOWING', // Some trouble in the air but data flowing
} as const;

// copied from api IntegrationRun model
export const INTEGRATION_RUN_STATUS = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  ERROR_CREDENTIALS: 'ERROR_CREDENTIALS',
} as const;

export type GraphQLIntegrationSchedule = {
  id: number;
  intervalMinutes: number;
};

export type GraphQLIntegration = {
  active: boolean;
  assetsCount: number;
  author: GraphQLUser;
  createdAt: Date;
  credential: { [key: string]: any };
  dataSource: GraphQLDataSource;
  id: number;
  lastUpdatedAt: Date | null;
  name: string;
  runStatus: keyof typeof INTEGRATION_RUN_STATUS;
  schedule: GraphQLIntegrationSchedule;
  status: keyof typeof INTEGRATION_STATUS;
  workspaceId: number;
};

export type GraphQLIntegrations = {
  viewer: {
    id: string;
    group: {
      id: string;
      name: string;
      integrations: GraphQLIntegration[];
    };
  };
};

export type GraphQLDataSource = {
  code: string;
  fields?: string[];
  category?: string;
  description?: string;
  id: number;
  label: string;
  deprecated: boolean;
};
