import { REDUX_PERSIST_STATE_KEY, STORAGE_KEY_PREFIX } from 'config/constants';

function computeStorageKey(key) {
  return key === REDUX_PERSIST_STATE_KEY
    ? REDUX_PERSIST_STATE_KEY
    : `${STORAGE_KEY_PREFIX}${key}`;
}

export const loadState = (storage, key, stateName) => {
  if (!storage || !key) {
    return undefined;
  }

  const storageKey = computeStorageKey(key);

  try {
    const serializedState = storage.getItem(storageKey);

    if (serializedState === undefined || serializedState === null) {
      return undefined;
    }

    const state = JSON.parse(serializedState);

    if (!stateName) {
      return state;
    }

    return state[stateName];
  } catch (err) {
    console.error('Error loading state from storage', err);
    return undefined;
  }
};

export const saveState = (storage, key, state) => {
  if (!storage || !key) {
    return undefined;
  }

  const storageKey = computeStorageKey(key);

  try {
    const serializedState = JSON.stringify(state);

    storage.setItem(storageKey, serializedState);
  } catch (err) {
    console.error('Error saving state to storage', err);
  }
};

export const clearStorage = (storage, key) => {
  if (!storage) {
    return;
  }

  if (!key) {
    storage.clear();
  }

  const storageKey = computeStorageKey(key);

  storage.removeItem(storageKey);
};
