import { useTranslation } from 'react-i18next';
import FaAngleDown from 'react-icons/lib/fa/angle-down';

import colors from 'constants/colors';
import styled from 'styled-components';
import type { SearchAndLoadMultiselectOption } from './types';

const Button = styled.div`
  border-radius: 5px;
  background: #ffffff;
  height: 100%;
  display: flex;
  color: #0a0a0c;
  user-select: none;

  cursor: pointer;
  align-items: center;
  transition: background 120ms ease-in 0s;
  box-shadow: #d5d8db 0px 0px 0px 0.5px inset;
  margin-right: 8px;

  :hover {
    background: #eff2f5;
  }

  :active {
    background: #d5d8db;
  }
`;

const ButtonLeft = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 8px 7px 8px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 5px 0 0 5px;
  white-space: nowrap;
  width: 100%;
`;

const ButtonRight = styled.div`
  border-radius: 0 5px 5px 0;
  display: flex;
  align-items: center;
  padding: 7px 8px 7px 8px;
  width: 32px;
  user-select: none;
`;

const TagList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: -4px;
  display: flex;
`;

const Tag = styled.li`
  height: 18px;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.gray850};
  padding: 2px 6px;
  background: ${colors.gray100};
  border-radius: 3px;
  align-items: center;
  margin-right: 2px;
  margin-bottom: 4px;
  justify-content: center;
  white-space: nowrap;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type SearchAndLoadButtonProps = {
  values: SearchAndLoadMultiselectOption[];
  onClick: () => void;
  maxItemDisplayed: number;
  optionsLength: number;
  placeholder?: string;
};

export const SearchAndLoadButton = ({
  values,
  onClick,
  optionsLength,
  maxItemDisplayed,
  placeholder = '',
}: SearchAndLoadButtonProps) => {
  const { t } = useTranslation('commonUi');
  const noneSelected = !values || !values.length;

  const renderTagList = () => {
    const allOptionsAreSelected =
      optionsLength > 0 && values.length === optionsLength;

    if (allOptionsAreSelected) {
      return (
        <Tag key="all">
          {t('searchAndLoadMultiselect.button.all', { count: optionsLength })}
        </Tag>
      );
    }

    const notDisplayedCount = values.length - maxItemDisplayed;
    const displayedValues = values.slice(0, maxItemDisplayed);

    return (
      <>
        {displayedValues.map((v) => (
          <Tag key={v.key}>{v.label}</Tag>
        ))}
        {notDisplayedCount > 0 && (
          <span
            style={{ background: 'none' }}
            key={'notDisplayedCount'}
          >{`+${notDisplayedCount}`}</span>
        )}
      </>
    );
  };

  return (
    <Button onClick={onClick}>
      <ButtonLeft>
        {noneSelected ? (
          placeholder || t('richMultiselect.richButton.placeholder')
        ) : (
          <TagList>{renderTagList()}</TagList>
        )}
      </ButtonLeft>
      <ButtonRight>
        <FaAngleDown />
      </ButtonRight>
    </Button>
  );
};
