import React from 'react';
import type { TooltipProps } from './Tooltip.props';
import { StyledReactTooltip } from './index.css';

export class Tooltip extends React.Component<TooltipProps> {
  static rebuild() {
    StyledReactTooltip.rebuild();
  }

  static hide(element?: Element) {
    StyledReactTooltip.hide(element);
  }

  static show(element: Element) {
    StyledReactTooltip.show(element);
  }

  render() {
    const {
      id,
      place = 'top',
      type = 'dark',
      effect = 'solid',
      small = true,
      children,
      multilineHtml,
      extraMargin,
      background,
      padding,
      width,
      ...others
    } = this.props;

    return (
      <StyledReactTooltip
        id={id}
        small={small}
        place={place}
        type={type}
        effect={effect}
        multilineHtml={multilineHtml}
        extraMargin={extraMargin}
        background={background}
        padding={padding}
        width={width}
        // overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
        //   const d = document.documentElement;
        //   left = Math.min(d.clientWidth - node.clientWidth, left);
        //   top = Math.min(d.clientHeight - node.clientHeight, top);

        //   left = Math.max(0, left) - 232;
        //   top = Math.max(0, top) ? Math.max(0, top) - 155 : Math.max(0, top);

        //   return { top, left };
        // }}
        {...others}
      >
        {children}
      </StyledReactTooltip>
    );
  }
}
