export type DoubleChevronRightProps = {
  size?: number;
  color?: string;
  onClick?: () => void;
};

export const DoubleChevronRightIcon = ({
  size = 17,
  color,
  onClick,
}: DoubleChevronRightProps) => (
  <svg
    width={`${size}`}
    height={`${size}`}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    onKeyDown={onClick}
  >
    <title>Double Chevron Right Icon</title>
    <path
      d="M4.5 5L8.49999 8.99999L4.5 13"
      stroke={color || '#2D2F30'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 5L13.5 8.99999L9.5 13"
      stroke={`${color || '#2D2F30'}`}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
