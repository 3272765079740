import colors from 'constants/colors';
import styled from 'styled-components';
import { FatCrossIcon } from '../Icons';

const CloseContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;

  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: ${colors.neutral200};
  }
  &:active {
    background: ${colors.neutral300};
  }    
  transition: background 120ms ease-in 0s;
  

  svg {
    padding: 8px;
    margin: auto;
    path {
      fill: ${colors.neutral850};
    }
  }
`;

export const CloseButton: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  return (
    <CloseContainer onClick={onClick}>
      <FatCrossIcon size={24} />
    </CloseContainer>
  );
};
