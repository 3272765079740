import { gql } from '@apollo/client';
import type { AssetSearchFragment } from 'modules/global-search/hooks/getGlobalSearch/gql.generated';
import type { InventoryAssetFragment } from 'modules/inventory_v2/all/gql.generated';
import { AssetSharingInfos } from './AssetSharingInfos.model';
import type {
  AssetSharingInformationsFragment,
  Co2AssetSharingInfosFragment,
  HourlyConsumptionAssetSharingInfosFragment,
  IdleRateAssetSharingInfosFragment,
  UsageHoursAssetSharingInfosFragment,
} from './gql.generated';

export const assetSharingInformationsFragment = gql`
  fragment AssetSharingInformations on Asset {
    sharingInformations {
      sharedBy {
        id
        organizationName
        iconUrl
      }
      sharedWith {
        id
        organizationName
      }
      isSharingInProgress
    }
  }
`;

export const usageHoursAssetPerformanceSharingInfosFragment = gql`
  fragment UsageHoursAssetSharingInfos on UsageHoursAssetPerformance {
    sharingInformations {
      sharedBy {
        id
        organizationName
      }
      sharedWith {
        id
        organizationName
      }
      isSharingInProgress
    }
  }
`;
export const co2AssetPerformanceSharingInfosFragment = gql`
  fragment Co2AssetSharingInfos on Co2AssetPerformance {
    sharingInformations {
      sharedBy {
        id
        organizationName
      }
      sharedWith {
        id
        organizationName
      }
      isSharingInProgress
    }
  }
`;
export const idleRateAssetPerformanceSharingInfosFragment = gql`
  fragment IdleRateAssetSharingInfos on IdleRateAssetPerformanceRow {
    sharingInformations {
      sharedBy {
        id
        organizationName
      }
      sharedWith {
        id
        organizationName
      }
      isSharingInProgress
    }
  }
`;
export const hourlyConsumptionAssetPerformanceSharingInfosFragment = gql`
  fragment HourlyConsumptionAssetSharingInfos on HourlyConsumptionAssetPerformanceRow {
    sharingInformations {
      sharedBy {
        id
        organizationName
      }
      sharedWith {
        id
        organizationName
      }
      isSharingInProgress
    }
  }
`;

const buildSharingInfoFromAssetSharingInformationsFragment = (
  sharingInformations: AssetSharingInformationsFragment['sharingInformations'],
  defaultAssetSharingInfos: AssetSharingInfos,
) => {
  if (!sharingInformations) {
    return defaultAssetSharingInfos;
  }
  const sharingInformationsSharedWith = sharingInformations?.sharedWith || [];
  const sharingInformationsSharedBy = sharingInformations?.sharedBy || null;
  return new AssetSharingInfos({
    sharedBy: sharingInformationsSharedBy
      ? {
          id: sharingInformationsSharedBy.id,
          organizationName: sharingInformationsSharedBy.organizationName,
          iconUrl: sharingInformationsSharedBy.iconUrl || null,
        }
      : null,
    sharedWith: sharingInformationsSharedWith,
    isSharingInProgress: sharingInformations.isSharingInProgress,
  });
};

export const buildAssetSharingInfos = (
  dataWithFragment:
    | AssetSearchFragment
    | AssetSharingInformationsFragment
    | Co2AssetSharingInfosFragment
    | HourlyConsumptionAssetSharingInfosFragment
    | InventoryAssetFragment
    | IdleRateAssetSharingInfosFragment
    | UsageHoursAssetSharingInfosFragment,
): AssetSharingInfos => {
  const defaultAssetSharingInfos = new AssetSharingInfos({
    sharedBy: null,
    sharedWith: [],
    isSharingInProgress: false,
  });

  if ('sharingInformations' in dataWithFragment) {
    return buildSharingInfoFromAssetSharingInformationsFragment(
      dataWithFragment.sharingInformations,
      defaultAssetSharingInfos,
    );
  }

  return defaultAssetSharingInfos;
};
