import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MdAdd from 'react-icons/lib/md/add';
import { CallOut } from '../CallOut';
import { Tooltip } from '../Tooltip';
import type { FilterObject } from './FilterObject.type';
import { FilterRow } from './FilterRow';
import type { SelectedFilter } from './SelectedFilter.type';
import {
  AddFilter,
  AddFilterIconWrapper,
  AddFilterRow,
  FilterSelectorCallOutWrapper,
  NewFilter,
} from './index.css';

export type CalloutOnFilter = {
  filterType: string;
  text: string;
};

type FilterSelectorProps = {
  filters: FilterObject[];
  selectedFilters: SelectedFilter[];
  deleteFilter: (index: number) => void;
  updateFilterType: (index: number, type: string) => void;
  updateFilterValue: (index: number, value: any) => void;
  addFilter: () => void;
  alignRight?: boolean;
  horizontalPadding?: boolean;
  calloutOnFilter?: CalloutOnFilter;
};

const computeFilterTypeOptions = (
  filters: FilterObject[],
  selectedFilter: SelectedFilter,
  selectedFilters: SelectedFilter[],
) => {
  return filters
    .filter((filterOption) => {
      const notSelected =
        selectedFilters.findIndex((sf) => sf.type === filterOption.type) === -1;

      return notSelected || filterOption.type === selectedFilter.type;
    })
    .map((filter) => ({
      label: filter.label,
      value: filter.type,
    }));
};

const computeFilterObject = (
  filters: FilterObject[],
  selectedFilter: SelectedFilter,
) => {
  if (!selectedFilter.type) {
    return { type: '' };
  }

  const filter = filters.find(
    (filterOption) => filterOption.type === selectedFilter.type,
  );

  if (!filter) {
    return {
      type: selectedFilter.type,
      options: [],
    };
  }
  return {
    type: selectedFilter.type,
    value: selectedFilter.value,
    options: filter.options,
    multiselect: filter.multiselect,
  };
};

const canAddFilter = (
  selectedFilters: SelectedFilter[],
  filters: FilterObject[],
): boolean => {
  const selectedFiltersCount = selectedFilters.length;

  return Boolean(
    !selectedFiltersCount ||
      (selectedFilters[selectedFiltersCount - 1].type &&
        selectedFiltersCount !== filters.length),
  );
};

export const FilterSelector = ({
  filters,
  selectedFilters,
  deleteFilter,
  updateFilterType,
  updateFilterValue,
  addFilter,
  alignRight = false,
  horizontalPadding = true,
  calloutOnFilter,
}: FilterSelectorProps) => {
  const { t } = useTranslation('commonUi');
  const [filterTooltipId] = useState(
    `hiboo-filter-tooltip-${Math.random().toString(36).slice(2)}`,
  );
  const canAddNew = canAddFilter(selectedFilters, filters);
  const isCalloutEnabled =
    calloutOnFilter &&
    selectedFilters.some(
      (filter) => filter.type === calloutOnFilter.filterType,
    );
  return (
    <>
      <NewFilter>
        {isCalloutEnabled && (
          <FilterSelectorCallOutWrapper>
            <CallOut text={calloutOnFilter.text} />
          </FilterSelectorCallOutWrapper>
        )}
        {selectedFilters.map((currentFilter, index) => {
          return (
            <FilterRow
              alignRight={alignRight}
              horizontalPadding={horizontalPadding}
              deleteFilter={() => {
                deleteFilter(index);
              }}
              key={`Filter-${currentFilter.type}`}
              onUpdateFilterType={(type: string) => {
                updateFilterType(index, type);
              }}
              onUpdate={(value: any) => {
                updateFilterValue(index, value);
              }}
              filter={computeFilterObject(filters, currentFilter)}
              tooltipId={filterTooltipId}
              typeOptions={computeFilterTypeOptions(
                filters,
                currentFilter,
                selectedFilters,
              )}
            />
          );
        })}
      </NewFilter>
      <AddFilterRow id="add-filter-row">
        <AddFilter
          disabled={!canAddNew}
          onClick={() => canAddNew && addFilter()}
          onKeyDown={addFilter}
          role="button"
          tabIndex={0}
        >
          <AddFilterIconWrapper>
            <MdAdd />
          </AddFilterIconWrapper>
          {t('filter.new.label')}
        </AddFilter>
      </AddFilterRow>
      <Tooltip id={filterTooltipId} />
    </>
  );
};
