import type React from 'react';
import styled from 'styled-components';

import colors from 'constants/colors';
import type { FreemiumButtonProps } from './FreemiumButton.props';

type StyledButtonProps = {
  'data-tip'?: string;
  block?: FreemiumButtonProps['block'];
  className?: string;
  ref?: React.Ref<any>;
  iconOnly?: boolean;
  dataTestId?: string;
};

export const FreemiumButtonWrapper = styled.div`
  a,
  a:hover,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: none;
  }
`;

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  align-self: center;
  padding: 5px 8px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 5px;
  transition: background 120ms ease-in 0s;
  border: 1px solid ${colors.purple400};
  background: ${colors.purple50};
  color: ${colors.purple400};

  :hover, :focus {
    background: ${colors.purple100};
  }

  :active,
  :focus {
    outline: none;
  }

  :hover + .freemium-tooltip {
    visibility: visible !important;
  }

  :hover {
    cursor: pointer;
    outline: none;
  }
    
  ${(props) => props.block && 'display: block;width:100%;'}

  ${(props) => (props.iconOnly ? 'padding: 0; min-width: 0;' : '')}

  a,
  a:hover,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: none;
  }
`;
