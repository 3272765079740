import type React from 'react';
import { useState } from 'react';

import ReactModal from 'react-modal';
import { CardContainer, CardImageContainer } from './index.css';

type CardProps = {
  video: {
    caption: string;
    src: string;
    thumbnail: string;
  };
};

export const Card: React.FC<CardProps> = ({ video }) => {
  const { caption, src, thumbnail } = video;
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            padding: 'none',
            width: '790px',
            height: ' 444px',
          },
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={src}
          title={caption}
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </ReactModal>
      <CardContainer>
        <CardImageContainer onClick={toggleModal}>
          <img className="thumbnail" src={thumbnail} alt={caption} />
          <img
            className="play-button"
            src="/images/play-button.png"
            alt="Play"
          />
        </CardImageContainer>
        <p>{caption}</p>
      </CardContainer>
    </>
  );
};
