import colors from 'constants/colors';
import styled from 'styled-components';

export const ToastContainerWrapper = styled.div`
  :root {
    --toastify-toast-width: auto;
    --toastify-toast-min-height: auto;
    --toastify-toast-padding: 16px;
  }

  .Toastify__toast {
    background-color: ${colors.blue};
    border-radius: $border-radius;
    box-shadow: 0px 2px 5px 0px #dbdbde;
    text-align: center;
    color: white;
    padding-left: 22px;
    padding-right: 22px;
  }

  .Toastify__toast.hiboo-notification {
    background-color: ${colors.offWhite};
    color: ${colors.black};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid ${colors.blue100};
    border-radius: $border-radius;
    padding: 16px;
    width: 400px;
  }

  .Toastify__toast-icon {
    margin-inline-end: 0;
  }

  .Toastify__toast--error {
    background-color: ${colors.red400};
  }
`;
