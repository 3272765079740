import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView, treeItemClasses } from '@mui/lab';
import { type ReactNode, useCallback, useEffect, useState } from 'react';
import { SearchBar } from '../SearchBar';
import { CustomTreeItem } from './CustomTreeItem';
import {
  SearchBreadCrumbContainer,
  SearchContainer,
  SearchResultItem,
  SearchResultRow,
} from './TreeView.css';
import type { TreeInputList, TreeNode } from './types';
import { createNodesFromList, findParentNodes, makeTree } from './utils';

// eslint-disable-next-line max-statements
export function Tree<T>(props: {
  list: TreeInputList<T>;
  renderItem: (data: T, searchRendering: boolean) => ReactNode;
  onClick?: (data: T) => void;
  selectedNodesId?: string[];
  highlightSelecteds?: boolean;
  searchPlaceholder?: string;
}) {
  const [search, setSearch] = useState('');
  const [tree, setTree] = useState<TreeNode<T>[]>(makeTree<T>(props.list));
  const [filteredNodes, setFilteredNodes] = useState<TreeNode<T>[]>([]);

  // by default first level is always expanded
  let expanded = tree.map((el) => `${el.id}`);

  if (props.selectedNodesId) {
    for (const selectedNodeId of props.selectedNodesId) {
      // expand all tree to selectedNodeId
      const expandToNodeId = findParentNodes(selectedNodeId, props.list);
      if (expandToNodeId.length) {
        expanded = expanded.concat(
          expandToNodeId.filter((a) => a).map((node) => `${node.id}`),
        );
      }
    }
  }

  useEffect(() => {
    setTree(makeTree(props.list));
  }, [props.list]);

  useEffect(() => {
    if (!search || !search.length) {
      setFilteredNodes(createNodesFromList(props.list));
      return;
    }
    const matchingResults = props.list.filter((node) => {
      return node.label?.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredNodes(createNodesFromList(matchingResults));
  }, [search, props.list]);

  const shouldBeHighlighted = (elem: TreeNode<T>) => {
    return (
      !!props.highlightSelecteds &&
      !!props.selectedNodesId &&
      props.selectedNodesId.includes(elem.id.toString())
    );
  };

  const clickWrapper = useCallback(
    (elem: TreeNode<T>, children: ReactNode) => {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            props.onClick?.(elem.data);
          }}
        >
          {children}
        </div>
      );
    },
    [props],
  );

  const renderElem = (elem: TreeNode<T>) => {
    return (
      <CustomTreeItem
        key={`${elem.id}`}
        nodeId={`${elem.id}`}
        label={clickWrapper(elem, props.renderItem(elem.data, false))}
        level={elem.level}
        withToggle={elem.children !== undefined && elem.children.length > 0}
        highlighted={shouldBeHighlighted(elem)}
        sx={[
          {
            [`.${treeItemClasses.label}`]: {
              paddingLeft: '2px',
            },
          },
          {
            [`.${treeItemClasses.group} .${treeItemClasses.content}`]: {
              pl: `${20 + 20 * elem.level + 8}px`,
            },
          },
          {
            [`.${treeItemClasses.iconContainer}`]: {
              width: `${elem.level === 0 ? '32' : '24'}px !important`,
            },
          },
        ]}
      >
        {elem.children?.map((child) => renderElem(child))}
      </CustomTreeItem>
    );
  };

  const renderTree = () => {
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={expanded}
      >
        {tree.map((elem) => renderElem(elem))}
      </TreeView>
    );
  };

  const getBreadcrumb = (node: TreeNode<T>) => {
    const parents = findParentNodes(`${node.id}`, props.list);
    if (!parents.length) {
      return null;
    }
    if (parents.length === 1) {
      return `${parents[0].label} /`;
    }
    if (parents.length === 2) {
      return `${parents[1].label} / ${parents[0].label}`;
    }
    return `${parents[parents.length - 1].label} / ... / ${parents[0].label}`;
  };

  const renderSearchElem = (elem: TreeNode<T>) => {
    const breadcrumb = getBreadcrumb(elem);
    const highlighted = shouldBeHighlighted(elem);
    return (
      <div key={elem.id}>
        <SearchResultRow
          onClick={(e) => {
            e.stopPropagation();
            props.onClick?.(elem.data);
          }}
          highlighted={highlighted}
        >
          {breadcrumb && (
            <SearchBreadCrumbContainer>{breadcrumb}</SearchBreadCrumbContainer>
          )}
          <SearchResultItem
            highlighted={highlighted}
            hasBreadcrumb={!!breadcrumb}
          >
            {props.renderItem(elem.data, !!breadcrumb)}
          </SearchResultItem>
        </SearchResultRow>
      </div>
    );
  };

  const renderSearchResults = () => {
    return <div>{filteredNodes.map((elem) => renderSearchElem(elem))}</div>;
  };

  return (
    <>
      <SearchContainer>
        <SearchBar
          debounceMs={1}
          onSearch={(text) => setSearch(text)}
          variant="secondary"
          autoFocus
          placeholder={props.searchPlaceholder}
        />
      </SearchContainer>
      {search?.length ? renderSearchResults() : renderTree()}
    </>
  );
}
