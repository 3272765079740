import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CustomDownloadLink = styled.a`
  text-decoration: underline;
  color: inherit;

  :hover,:visited,:active {
    text-decoration: underline;
    color: inherit;
  }
`;

const CustomNavigationLink = styled(Link)`
  text-decoration: underline;
  color: inherit;

  :hover,:visited,:active {
    text-decoration: underline;
    color: inherit;
  }
`;

export const buildExportCompletedContent = (link: string) => {
  return (
    <Trans
      i18nKey="notifications:export_completed.content"
      components={{
        ExportDownloadLink: <CustomDownloadLink href={link} target="_blank" />,
        ExportLink: <CustomNavigationLink to="/exports" />,
      }}
    />
  );
};
