import colors from 'constants/colors';
import type { TFunction } from 'i18next';
import { MetricStatus } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { format } from 'utils/number';
import type { FaultCountBySeverity } from '../../../assets/alerts/types';
import {
  FaultsCountSection,
  FaultsCountSectionDivider,
  FaultsCountWrapper,
} from './styles.css';

const DEFAULT_SIZE = 'small';
type CounterSize = 'small' | 'big';

type AssetFaultsCounterProps = Partial<
  FaultCountBySeverity & { size: CounterSize; assets: number }
>;

const renderCounter = (
  t: TFunction<'translation'>,
  label: string,
  size: CounterSize,
  count?: number,
  bulletColor?: string,
): JSX.Element | null => {
  if (count === undefined || (size === 'small' && !count)) return null;

  return (
    <MetricStatus
      color={bulletColor}
      title={format(
        count,
        t('translation:common.thousandSeparator'),
        t('translation:common.decimalSeparator'),
      )}
      subtitle={label.toLowerCase()}
      size={size}
    />
  );
};

export const AssetFaultsCounter: React.FC<AssetFaultsCounterProps> = (
  props: AssetFaultsCounterProps,
) => {
  const [t] = useTranslation('alerts');
  const {
    total,
    critical,
    high,
    medium,
    low,
    unknown,
    assets,
    size = DEFAULT_SIZE,
  } = props;

  return (
    <FaultsCountWrapper $size={size}>
      {size === 'big' && (
        <>
          <FaultsCountSection $size={size}>
            {renderCounter(
              t,
              t('alerts.configuration.common.fields.assets.title'),
              size,
              assets,
            )}
            {renderCounter(
              t,
              t('alerts.configuration.fault.fields.severity.counter'),
              size,
              total,
            )}
          </FaultsCountSection>
          <FaultsCountSectionDivider />
        </>
      )}
      <FaultsCountSection $size={size}>
        {renderCounter(
          t,
          t('alerts.configuration.fault.fields.severity.options.critical'),
          size,
          critical,
          colors.red200,
        )}
        {renderCounter(
          t,
          t('alerts.configuration.fault.fields.severity.options.high'),
          size,
          high,
          colors.red100,
        )}
        {renderCounter(
          t,
          t('alerts.configuration.fault.fields.severity.options.medium'),
          size,
          medium,
          colors.orange100,
        )}
        {renderCounter(
          t,
          t('alerts.configuration.fault.fields.severity.options.low'),
          size,
          low,
          colors.purple100,
        )}
        {renderCounter(
          t,
          t('alerts.configuration.fault.fields.severity.options.unknown'),
          size,
          unknown,
          colors.neutral200,
        )}
      </FaultsCountSection>
    </FaultsCountWrapper>
  );
};
