import type React from 'react';
import { Left, PageTitleWrapper } from './index.css';

type PageTitleProps = {
  noPaddingLeft?: boolean;
  noPaddingTop?: boolean;
  noPaddingRight?: boolean;
  withPaddingBottom?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export const PageTitle = (props: PageTitleProps) => {
  return (
    <PageTitleWrapper role="heading" aria-level={1} {...props}>
      {props.children}
    </PageTitleWrapper>
  );
};

PageTitle.Left = Left;

export default PageTitle;
