import colors from 'constants/colors';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { CallOut } from '../CallOut';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 16px;
  background-color: ${colors.offWhite};
  border: 1px solid ${colors.neutral200};
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0p rgba(0, 0, 0, 0.08);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`;

const MediaContainer = styled.div<{ reverse?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: ${(props) => (props.reverse ? 'flex-end' : 'flex-start')};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  font-size: 16px;
  line-height: 24px;
  flex:1;
  color: ${colors.gray850};
`;

const Title = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-transform: uppercase;
`;

const Subtitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: ${colors.gray850};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  max-width: 370px;
  color: ${colors.gray850};
`;

const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export type FeatureSpotlightProps = {
  media?: ReactNode;
  content: {
    callOut?: string;
    title: string;
    subtitle: string;
    description: string;
    footer: ReactNode;
  };
  reverse?: boolean;
};

export const FeatureSpotlight = ({
  media,
  content,
  reverse,
}: FeatureSpotlightProps) => {
  const renderCallOut = () => {
    if (!content.callOut) {
      return null;
    }

    return <CallOut text={content.callOut} fullWidth />;
  };

  const renderMedia = () => {
    if (!media) return null;
    return <MediaContainer reverse={reverse}>{media}</MediaContainer>;
  };

  const renderText = () => {
    return (
      <TextContainer>
        <Title>{content.title}</Title>
        <Subtitle>{content.subtitle}</Subtitle>
        <Description>{content.description}</Description>
        <Footer>{content.footer}</Footer>
      </TextContainer>
    );
  };

  const renderContent = () => {
    return (
      <ContentContainer>
        {reverse ? (
          <>
            {renderMedia()}
            {renderText()}
          </>
        ) : (
          <>
            {renderText()}
            {renderMedia()}
          </>
        )}
      </ContentContainer>
    );
  };

  return (
    <Container>
      {renderCallOut()}
      {renderContent()}
    </Container>
  );
};
