/* eslint-disable max-statements */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

import { SEGMENT_SUPPORTED_FILTERS } from 'constants/filter';
import { PRICING_PLANS } from 'constants/pricingPlans';
import startsWith from 'lodash/startsWith';
import type { AssetFilterOptions } from 'modules/analytics/components/AssetFilter/types';
import { FreemiumButton } from 'modules/common-ui';
import type { FleetSegment } from 'modules/common-ui/models/FleetSegment';
import type { UserStore } from 'modules/user';
import { useSelector } from 'react-redux';
import type { Store } from 'reducers';
import { Beta } from '../Badge';
import { Button } from '../Button';
import { CircularSpinner } from '../CircularSpinner';
import { Tabs } from '../Tabs';
import { Tooltip } from '../Tooltip';
import type { FilterObject } from './FilterObject.type';
import { type CalloutOnFilter, FilterSelector } from './FilterSelector';
import { SegmentList } from './SegmentList';
import type { SelectedFilter } from './SelectedFilter.type';
import {
  BetaLabelled,
  Container,
  FilterMask,
  FreemiumButtonWrapper,
  LoadingOverlay,
  Main,
  SegmentListNew,
  TabItem,
} from './index.css';
import { appliedFiltersToSelectedFilters } from './utils';

type StateType = {
  pricingPlan: string | null;
};

type FilterDesktopProps = {
  loading: boolean;
  display: boolean;
  setDisplay: (display: boolean) => void;
  appliedFilters: AssetFilterOptions;
  selectedFilters: SelectedFilter[];
  filters: FilterObject[];
  segments: FleetSegment[] | undefined;
  activeSegment: FleetSegment | null;
  applySegment: (segment: FleetSegment) => void;
  setSelectedFilters: (filters: SelectedFilter[]) => void;
  addFilter: () => void;
  deleteFilter: (index: number) => void;
  updateFilterType: (index: number, type: string) => void;
  updateFilterValue: (index: number, value: any) => void;
  alignRight: boolean;
  triggerCreateSegment?: () => void;
  triggerEditSegment?: (fleetSegment: FleetSegment) => void;
  triggerDeleteSegment?: (fleetSegment: FleetSegment) => void;
  calloutOnFilter?: CalloutOnFilter;
};
export const FilterDesktop = ({
  loading,
  display,
  setDisplay,
  appliedFilters,
  selectedFilters,
  filters,
  segments,
  activeSegment,
  applySegment,
  setSelectedFilters,
  addFilter,
  deleteFilter,
  updateFilterType,
  updateFilterValue,
  alignRight,
  triggerCreateSegment = () => {},
  triggerEditSegment = () => {},
  triggerDeleteSegment = () => {},
  calloutOnFilter,
}: FilterDesktopProps) => {
  const { t } = useTranslation('commonUi');

  const userIsAdmin = useSelector((state: { user: UserStore }) => {
    const { user } = state;
    return user && (user.admin || user.adminOfOrganization);
  });

  useEffect(() => {
    const selectedFilters = appliedFiltersToSelectedFilters(appliedFilters);
    setSelectedFilters(selectedFilters);
  }, [appliedFilters, setSelectedFilters]);

  useEffect(() => {
    Tooltip.rebuild();
  }, [selectedFilters]);

  const renderFilterSelector = () => {
    return (
      <FilterSelector
        addFilter={addFilter}
        deleteFilter={deleteFilter}
        filters={filters}
        selectedFilters={selectedFilters}
        updateFilterType={updateFilterType}
        updateFilterValue={updateFilterValue}
        alignRight={alignRight}
        calloutOnFilter={calloutOnFilter}
      />
    );
  };

  const { pricingPlan } = useSelector<Store, StateType>((state) => {
    return {
      pricingPlan: state.user.organizationPlan,
    };
  });

  if (!display) {
    return null;
  }

  const selectedFiltersWithValues = selectedFilters.filter(
    (f) => f.value && f.value.length > 0,
  );

  const unsupportedFiltersForSegmentCreation = selectedFiltersWithValues.filter(
    (filter) =>
      !SEGMENT_SUPPORTED_FILTERS.some((supportedFilter) => {
        return startsWith(filter.type, supportedFilter);
      }),
  );

  const canCreateSegment = unsupportedFiltersForSegmentCreation.length === 0;

  const renderCreateSegment = (userIsAdmin: boolean) => {
    if (pricingPlan === PRICING_PLANS.freemium) {
      return (
        <FreemiumButtonWrapper>
          <FreemiumButton
            id="upgrade"
            freemiumTooltipButtonLabel={t('fleetSegment:freemiumButtonLabel')}
            style={{ width: '100%' }}
          />
        </FreemiumButtonWrapper>
      );
    }

    return (
      userIsAdmin && (
        <SegmentListNew>
          <Button
            disabled={!canCreateSegment}
            tooltip={
              canCreateSegment
                ? ''
                : t('fleetSegment:modal.create.filtersNotSupported', {
                    count: unsupportedFiltersForSegmentCreation.length,
                    name: unsupportedFiltersForSegmentCreation
                      .map((f) => t(`filter:filter.filterType.${f.type}`))
                      .join(', '),
                  })
            }
            variant="outline-primary"
            onClick={triggerCreateSegment}
          >
            + {t('fleetSegment:createSegment')}
          </Button>
        </SegmentListNew>
      )
    );
  };

  // Use to display filter tab by default if there is no custom segment
  const hasCustomSegment = segments?.some((segment) => !segment.isHiboo);

  return (
    <>
      <FilterMask />
      <OutsideClickHandler
        onOutsideClick={(event) => {
          setDisplay(false);
        }}
      >
        <Container alignRight={alignRight}>
          {loading && (
            <LoadingOverlay>
              <span>
                {t('loader.loading')} <CircularSpinner />
              </span>
            </LoadingOverlay>
          )}
          {!segments ? (
            <Main>{renderFilterSelector()}</Main>
          ) : (
            <>
              <Tabs
                tabList={[
                  {
                    label: (
                      <TabItem>
                        <BetaLabelled>
                          {t('filter.tab.segments')} <Beta />
                        </BetaLabelled>
                      </TabItem>
                    ),
                    content: (
                      <SegmentList
                        activeSegment={activeSegment}
                        segments={segments}
                        applySegment={applySegment}
                        triggerEditSegment={triggerEditSegment}
                        triggerDeleteSegment={triggerDeleteSegment}
                        userIsAdmin={userIsAdmin}
                        isMobile={false}
                      />
                    ),
                  },
                  {
                    label: <TabItem>{t('filter.tab.filters')}</TabItem>,
                    content: <Main>{renderFilterSelector()}</Main>,
                  },
                ]}
                initialTabIndex={hasCustomSegment ? 0 : 1}
              />
              {renderCreateSegment(userIsAdmin)}
            </>
          )}
        </Container>
      </OutsideClickHandler>
    </>
  );
};
