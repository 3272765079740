import type React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CircularSpinner, HModal } from 'modules/common-ui';

import { Message, ModalBody } from './index.css';

export type DeleteConfirmModalProps = {
  cancelLabel?: string;
  isModalOpen: boolean;
  submitLabel: string;
  message: string | React.ReactNode;
  onCancel: () => void;
  onSubmit: () => void;
  loading?: boolean;
  style?: React.CSSProperties;
};

export const DeleteConfirmModal = ({
  cancelLabel = '',
  isModalOpen,
  submitLabel,
  message,
  onCancel,
  onSubmit,
  loading = false,
  style = { width: '282px' },
}: DeleteConfirmModalProps) => {
  const [t] = useTranslation('translation');

  return (
    <HModal onRequestClose={onCancel} isOpen={isModalOpen} style={style}>
      <ModalBody>
        <Message>{message}</Message>
        <Button
          onClick={onSubmit}
          variant="outline-danger"
          iconLeft={loading ? <CircularSpinner /> : null}
          disabled={loading}
          style={{ width: '100%' }}
        >
          {submitLabel}
        </Button>
        <Button block onClick={onCancel} disabled={loading}>
          {cancelLabel || t('common.cancel')}
        </Button>
      </ModalBody>
    </HModal>
  );
};
