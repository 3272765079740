import {
  ToastContainer as BaseToastContainer,
  type ToastContainerProps,
} from 'react-toastify';
import { Fragment } from 'react/jsx-runtime';
import { CheckIcon, WarningIcon } from '../Icons';
import { ToastContainerWrapper } from './index.css';

export const ToastContainer = (props: ToastContainerProps) => (
  <ToastContainerWrapper>
    <BaseToastContainer
      hideProgressBar
      closeButton={<Fragment />}
      closeOnClick={true}
      icon={({ type }) => {
        switch (type) {
          case 'success':
            return <CheckIcon color="white" />;
          case 'error':
            return <WarningIcon color="white" />;
          default:
            return null;
        }
      }}
      {...props}
    />
  </ToastContainerWrapper>
);
