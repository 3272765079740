import type { FieldPolicy } from '@apollo/client';

import type { Reference } from '@apollo/client/utilities';
import type { TypedTypePolicies } from 'graphql/apollo-helpers';

type PaginatedList<T> = {
  total: number;
  rows: T[];
};

const mergeOffsetBasedPagination = <T>(
  existing: Readonly<PaginatedList<T>> | undefined,
  incoming: Readonly<PaginatedList<T>>,
  args: Record<string, any> | null,
) => {
  const merged =
    existing && Array.isArray(existing.rows) ? existing.rows.slice(0) : [];
  if (incoming && Array.isArray(incoming.rows)) {
    if (args) {
      // Assume an offset of 0 if args.offset is omitted.
      // The offset could be in a pagination object or as a separate argument
      const { offset = 0 } = args.pagination || args;
      for (let i = 0; i < incoming.rows.length; ++i) {
        merged[offset + i] = incoming.rows[i];
      }
    } else {
      merged.push(...incoming.rows);
    }
  }

  return {
    ...incoming,
    ...existing,
    rows: merged,
  };
};

function paginatedAssetOffsetLimitPagination<T = Reference>(
  keyArgs: FieldPolicy<T>['keyArgs'] = false,
): FieldPolicy<PaginatedList<T>> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      return mergeOffsetBasedPagination<T>(existing, incoming, args);
    },
  };
}

function inventoryV2PaginationRules<T = Reference>(
  keyArgs: FieldPolicy<T>['keyArgs'] = false,
): FieldPolicy<{ total: number; rows: T[] }> {
  return {
    keyArgs,
    merge(existing, incoming, options) {
      if (options.field?.alias?.value !== 'infiniteScrollInventoryV2') {
        return incoming;
      }
      return mergeOffsetBasedPagination<T>(existing, incoming, options.args);
    },
  };
}

export const typePolicies: TypedTypePolicies = {
  AssetCustomFieldType: {
    keyFields: ['id', 'value'],
  },
  FeatureManagement: {
    keyFields: [],
  },
  WorkspaceTyreSnapshot: {
    merge: true,
  },
  Co2Report: {
    keyFields: [],
  },
  UsageHoursReport: {
    keyFields: [],
  },
  FleetData: {
    keyFields: [],
  },
  Sharing: {
    keyFields: [],
    fields: {
      shareableAssets: paginatedAssetOffsetLimitPagination([
        'assetFilters',
        'search',
      ]),
    },
  },
  IssuedSharing: {
    fields: {
      sharedAssets: paginatedAssetOffsetLimitPagination(),
    },
  },
  ReceivedSharing: {
    fields: {
      sharedAssets: paginatedAssetOffsetLimitPagination(),
    },
  },
  Group: {
    fields: {
      listAssets: paginatedAssetOffsetLimitPagination([
        'assetFilters',
        'search',
      ]),
      inventoryV2: inventoryV2PaginationRules([
        'assetFilters',
        'search',
        'sort',
      ]),
    },
  },
  AssetSharingDataFilters: {
    keyFields: [],
  },
  IssuedSharingDataFilters: {
    keyFields: [],
  },
  SharingInfos: {
    keyFields: [],
  },
};
