import colors from 'constants/colors';
import type { ReactNode } from 'react';
import styled from 'styled-components';

const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

const Icon = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    margin-right: 4px;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: ${colors.gray850};
`;

export const ContextualHelpHeader = ({
  title,
  icon,
}: {
  title: string;
  icon: ReactNode;
}) => (
  <Header>
    <Icon>{icon}</Icon>
    <Title>{title}</Title>
  </Header>
);
