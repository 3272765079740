import type { categoryColors } from 'constants/colors';

type Category = {
  id: number;
  name: string;
  color: keyof typeof categoryColors;
};

type ZoneFoundFields = {
  id: string;
  name: string;
  category: Category | null;
};

export class ZoneFound {
  public readonly id: ZoneFoundFields['id'];
  public readonly name: ZoneFoundFields['name'];
  public readonly category: ZoneFoundFields['category'];

  constructor(fields: ZoneFoundFields) {
    this.id = fields.id;
    this.name = fields.name;
    this.category = fields.category;
  }

  toJSON(): ZoneFoundFields & { type: 'zone' } {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      type: 'zone',
    };
  }
}
