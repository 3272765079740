export type AssetSharingInfosModelFields = {
  sharedBy?: {
    id: number;
    organizationName: string;
    iconUrl: string | null;
  } | null;
  sharedWith: {
    id: number;
    organizationName: string;
  }[];
  isSharingInProgress: boolean;
};

export class AssetSharingInfos {
  public readonly sharedBy: {
    id: number;
    organizationName: string;
    iconUrl: string | null;
  } | null;
  public readonly sharedWith: AssetSharingInfosModelFields['sharedWith'];
  public readonly isSharingInProgress: boolean;

  constructor(props: AssetSharingInfosModelFields) {
    this.sharedBy = props.sharedBy
      ? {
          ...props.sharedBy,
        }
      : null;
    this.sharedWith = props.sharedWith;
    this.isSharingInProgress = props.isSharingInProgress;
  }

  isShared(): boolean {
    return this.isSharedWithSomeBeneficiaries() || this.isSharedByItsOwner();
  }

  isSharedWithSomeBeneficiaries(): boolean {
    return this.sharedWith.length > 0;
  }

  isSharedByItsOwner(): boolean {
    return Boolean(this.sharedBy);
  }

  getOwnerOrganizationName(): string {
    return this.sharedBy?.organizationName || '';
  }

  getOwnerOrganizationIconUrl(): string | null {
    return this.sharedBy?.iconUrl || null;
  }

  getBeneficiariesOrganizationNames(): string[] {
    return this.sharedWith.map((beneficiary) => beneficiary.organizationName);
  }
}
