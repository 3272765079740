import colors from 'constants/colors';

export type MapIconProps = {
  size?: number;
  color?: string;
};

export const MapIcon = ({
  size = 16,
  color = colors.gray500,
}: MapIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14.8056L7.68765 15.196L8 15.4459L8.31235 15.196L8 14.8056ZM12.5 5.91669C12.5 7.17908 11.986 8.75911 11.1063 10.3175C10.2306 11.8687 9.02034 13.349 7.68765 14.4151L8.31235 15.196C9.75744 14.0399 11.0471 12.4565 11.9771 10.8091C12.9029 9.16902 13.5 7.41571 13.5 5.91669H12.5ZM8.31235 14.4151C6.97966 13.349 5.76936 11.8687 4.89375 10.3175C4.01402 8.75911 3.5 7.17908 3.5 5.91669H2.5C2.5 7.41571 3.09709 9.16902 4.02292 10.8091C4.95287 12.4565 6.24256 14.0399 7.68765 15.196L8.31235 14.4151ZM3.5 5.91669C3.5 3.43141 5.51472 1.41669 8 1.41669V0.416687C4.96243 0.416687 2.5 2.87912 2.5 5.91669H3.5ZM8 1.41669C10.4853 1.41669 12.5 3.43141 12.5 5.91669H13.5C13.5 2.87912 11.0376 0.416687 8 0.416687V1.41669Z"
      fill={color}
    />
    <circle
      cx="8.00006"
      cy="5.91673"
      r="1.66667"
      fill="transparent"
      stroke={color}
    />
  </svg>
);
