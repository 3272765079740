import colors from 'constants/colors';
import { ShareIcon, Tooltip } from 'modules/common-ui';
import type { TooltipProps } from 'modules/common-ui/components/Tooltip/Tooltip.props';
import type { AssetSharingInfos } from 'modules/sharing/models/AssetSharingInfos/AssetSharingInfos.model';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { SharingIconContainer } from './styles.css';

export type SharingMarkerProps = {
  sharingInfos: AssetSharingInfos;
  iconSize?: number;
  tooltipPlace?: TooltipProps['place'];
  displayCustomOrganizationLogo?: boolean;
};

export const SharingMarker = ({
  sharingInfos,
  iconSize = 24,
  tooltipPlace = 'top',
  displayCustomOrganizationLogo = false,
}: SharingMarkerProps) => {
  const { t } = useTranslation('sharing');
  const shareTooltipId = useId();

  if (!sharingInfos.isShared()) {
    return null;
  }
  const tip = sharingInfos.isSharedByItsOwner()
    ? t('common.tooltip.sharedBy', {
        organizationName: sharingInfos.getOwnerOrganizationName(),
      })
    : t('common.tooltip.sharedWith', {
        organizationNames: sharingInfos.getBeneficiariesOrganizationNames(),
      });

  const getSharingIcon = () => {
    const ownerIconUrl = sharingInfos.getOwnerOrganizationIconUrl();
    if (displayCustomOrganizationLogo && ownerIconUrl) {
      return (
        <img
          alt={sharingInfos.getOwnerOrganizationName()}
          src={ownerIconUrl}
          height={iconSize}
        />
      );
    }
    const isSharingInProgress = sharingInfos.isSharingInProgress;
    return (
      <ShareIcon
        size={iconSize}
        color={isSharingInProgress ? colors.blue500 : colors.gray500}
      />
    );
  };

  return (
    <SharingIconContainer data-for={shareTooltipId} data-tip={tip}>
      <Tooltip id={shareTooltipId} place={tooltipPlace} multilineHtml />
      {getSharingIcon()}
    </SharingIconContainer>
  );
};
