import colors from 'constants/colors';
import type React from 'react';

export type ArrowUpIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
};

export const ArrowUpIcon = ({
  size = 16,
  color = colors.black,
  style,
}: ArrowUpIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    fill={color}
    height={size}
    width={size}
    style={style}
    viewBox="0 0 40 40"
  >
    <g>
      <path d="m6.6 20l13.4-13.4 13.4 13.4-2.5 2.3-9.3-9.3v20.4h-3.2v-20.4l-9.4 9.3z" />
    </g>
  </svg>
);
