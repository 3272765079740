import styled from 'styled-components';

import colors from 'constants/colors';
import { resizeAnimation } from 'modules/common-ui';

const height = '32px';
const padding = '4px 8px';

export const Container = styled.div`
  position: relative;
`;

export const Select = styled.div`
  background-color: ${colors.white};
  border-radius: 0.2rem;
  border: 0.5px solid ${colors.gray300};
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: ${colors.gray850};
  display: flex;
  font-size: 0.7875rem;
  height: ${height};
  line-height: 1.5;

  :hover {
    cursor: pointer;
  }
`;

export const Left = styled.div<{ isPlaceholder?: boolean }>`
  align-items: center;
  color: ${(props) => (props.isPlaceholder ? '#6c757c' : '#495057')};
  display: flex;
  flex: 1;
  justify-content: ${(props) => (props.isPlaceholder ? 'flex-start' : 'center')};
  padding: ${(props) => (props.isPlaceholder ? padding : '4px')};
  padding-right: 2px;
  user-select: none;
`;

export const Right = styled.div`
  padding: ${padding};
  padding-left: 2px;
  user-select: none;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1;

  background: ${colors.white};
  border: 1px solid ${colors.gray300};
  border-radius: 5px;
  padding: 8px 0;
  box-shadow: 0 9px 24px rgba(15, 15, 15, 0.2), 0 3px 6px rgba(15, 15, 15, 0.1),
    0 0 0 rgba(15, 15, 15, 0.05);
  animation-name: ${() => resizeAnimation};
  animation-duration: 200ms;
  animation-timing-function: ease-in;
`;

export const Option = styled.div<{ selected?: boolean }>`
  align-items: center;
  background: ${(props) => (props.selected ? colors.gray100 : colors.white)};
  border-bottom: 1px solid ${colors.gray50};
  color: ${colors.gray850};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  height: 28px;
  justify-content: center;
  line-height: 18px;
  min-height: 28px;
  padding: 1px 4px;
  transition: background 120ms ease-in 0s;
  user-select: none;
  white-space: pre;

  :hover {
    background: rgba(45, 47, 48, 0.08);
  }

  :active {
    background: ${colors.grayHover};
  }

  &:first-child {
    padding-top: 3px;
  }

  &:last-child {
    padding-bottom: 3px;
  }
`;

export const Color = styled.div<{ badge?: boolean }>`
  align-items: center;
  background: ${(props) => props.color || colors.shadyGrey};
  border-radius: ${(props) => (props.badge ? '3px' : '8px')};
  color: ${colors.gray850};
  display: flex;
  height: ${(props) => (props.badge ? '100%' : '16px')};
  justify-content: space-between;
  padding: ${(props) => (props.badge ? padding : 0)};
  position: relative;
  width: ${(props) => (props.badge ? '100%' : '16px')};
`;
