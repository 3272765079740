import colors from 'constants/colors';

export type IntegrationsIconProps = {
  size?: number;
  color?: string;
};

export const IntegrationsIcon = ({
  size = 16,
  color = colors.gray500,
}: IntegrationsIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="5" height="5" stroke={color} />
    <path d="M9 9L14 14" stroke={color} strokeLinecap="round" />
    <path d="M9 14L14 9" stroke={color} strokeLinecap="round" />
    <circle cx="12" cy="4.5" r="2.5" stroke={color} />
    <path d="M2.30902 14L4.5 9.61803L6.69098 14H2.30902Z" stroke={color} />
  </svg>
);
