import { useCallback } from 'react';
import { useMarkNotificationAsReadMutation } from '../gql/gql.generated';
import type { Notification } from '../models/Notification';

export const useMarkNotificationAsRead = () => {
  const [mutation] = useMarkNotificationAsReadMutation();

  return useCallback(
    async (notification: Notification) => {
      await mutation({
        variables: {
          notificationId: notification.id,
        },
      });
      return true;
    },
    [mutation],
  );
};
