import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CellProps } from 'react-table';

import colors from 'constants/colors';
import { apiClient } from 'modules/api-client';
import type { DiagnosticThinxtraOysterItemType } from 'modules/api-client/diagnostic/types';
import { PageTitle } from 'modules/common-ui';
import layout from 'modules/layout/index';
import { Table } from 'modules/tableV7';
import { humanizeDate } from 'utils/time';

export const DiagnosticThinxtraOyster: React.FC = () => {
  const [t] = useTranslation('');
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([] as DiagnosticThinxtraOysterItemType[]);
  useEffect(() => {
    apiClient.diagnostic
      .getThinxtraOyster()
      .then((response) => setRows(response))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      Header: 'Code',
      id: 'serialNumber',
      width: 200,
    },
    // {
    //   Header: 'id',
    //   id: 'id',
    // },
    {
      Header: 'messageDate',
      id: 'messageDate',
      width: 200,
      Cell: ({
        row: { original },
      }: CellProps<DiagnosticThinxtraOysterItemType>) => {
        const lastMessage = original.lastMessage?.length
          ? original.lastMessage[0]
          : { date: null };
        return (
          <span>
            {humanizeDate(
              lastMessage.date,
              t('translation:common.notAvailable'),
            )}
          </span>
        );
      },
    },
    {
      Header: 'status',
      id: 'status',
      width: 200,
      Cell: ({
        row: { original },
      }: CellProps<DiagnosticThinxtraOysterItemType>) => {
        const lastMessage = original.lastMessage?.length
          ? original.lastMessage[0]
          : { date: null };
        const status = lastMessage?.date ? 'OK' : 'KO';
        return (
          <span
            style={{
              fontWeight: 500,
              color: status === 'OK' ? colors.green400 : colors.red400,
            }}
          >
            {status}
          </span>
        );
      },
    },
  ];

  return (
    <layout.Page>
      <PageTitle>Thinxtra Oyster</PageTitle>
      <layout.Section noFlex>
        <Table
          id="alerts-notification-list"
          data={rows}
          columns={columns}
          loading={loading}
          noDataText={'No data'}
          displaySearch={true}
          displayColumnToggler={false}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
        />
      </layout.Section>
    </layout.Page>
  );
};
