import { useTranslation } from 'react-i18next';

import { BATTERY_INDICATOR_LEVEL } from 'constants/device';

import {
  Container,
  DeviceBatteryLevelDot,
  DeviceBatteryLevelDots,
  DeviceBatteryLevelLabel,
} from './index.css';

export type DeviceBatteryLevelProps = {
  batteryIndicator: 'ORANGE' | 'GREEN' | 'RED';
};

export const DeviceBatteryLevel = ({
  batteryIndicator,
}: DeviceBatteryLevelProps) => {
  const { t } = useTranslation('translation');

  if (!BATTERY_INDICATOR_LEVEL[batteryIndicator.toUpperCase()]) {
    return null;
  }

  /*
    The below commented lines are here for i18next-parser
    t('device.batteryLevelLabels.ORANGE')
    t('device.batteryLevelLabels.GREEN')
    t('device.batteryLevelLabels.RED')
  */
  const label = t(`device.batteryLevelLabels.${batteryIndicator}`);

  return (
    <Container>
      <DeviceBatteryLevelDots>
        <DeviceBatteryLevelDot color={batteryIndicator} />
        <DeviceBatteryLevelDot
          color={
            batteryIndicator === BATTERY_INDICATOR_LEVEL.RED
              ? undefined
              : batteryIndicator
          }
        />
        <DeviceBatteryLevelDot
          color={
            batteryIndicator === BATTERY_INDICATOR_LEVEL.GREEN
              ? BATTERY_INDICATOR_LEVEL.GREEN
              : undefined
          }
        />
      </DeviceBatteryLevelDots>
      <DeviceBatteryLevelLabel
        red={batteryIndicator === BATTERY_INDICATOR_LEVEL.RED}
      >
        {label}
      </DeviceBatteryLevelLabel>
    </Container>
  );
};
