import type { GraphQLDailyAllUsages } from './../types';

export type ActionType = {
  data: GraphQLDailyAllUsages;
  type: string;
};

export const ASSET_USAGE = {
  RECEIVED_DATA: 'ASSET_USAGE_RECEIVED_DATA',
  FILTER: 'ASSET_USAGE_FILTER',
};

export function receivedData(data: GraphQLDailyAllUsages) {
  return {
    type: ASSET_USAGE.RECEIVED_DATA,
    data,
  };
}
