import { BREAKPOINT_TABLET } from 'constants/breakpoints';
import styled from 'styled-components';

export const FaultsCountWrapper = styled.div<{ $size: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
    ${(props) =>
      props.$size === 'small' &&
      `
    margin: 4px;
  `}
`;

export const FaultsCountSection = styled.div<{ $size: string }>`
  display: flex;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  ${(props) => props.$size === 'big' && 'padding: 15px;'}
`;

export const FaultsCountSectionDivider = styled.hr`
  width: 100%;
  margin: 0;
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    display: none;
  }
`;
