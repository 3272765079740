import { useTranslation } from 'react-i18next';

import { Button } from 'modules/common-ui';

import { SharingMarker } from 'modules/sharing/components/SharingMarker/SharingMarker';
import type { AssetSharingInfos } from 'modules/sharing/models/AssetSharingInfos/AssetSharingInfos.model';
import {
  CellSubtitle,
  CellTitle,
  Container,
  IconContainer,
  StyledLink,
} from './index.css';

export type AssetNameCellProps = {
  testId?: string;
  title: string;
  subtitle?: string;
  sharingInfos?: AssetSharingInfos;
  link?: string;
  showLinkOnRowHover?: boolean;
  dataFor?: any;
  dataTip?: any;
  linkTooltip?: string;
  openLinkInNewWindow?: boolean;
  extraUrlParams?: Record<string, string>;
};

export const AssetNameCell = (props: AssetNameCellProps) => {
  const { t } = useTranslation('translation');
  const {
    title,
    subtitle,
    sharingInfos,
    link,
    showLinkOnRowHover = true,
    dataFor = '',
    dataTip = '',
    linkTooltip = '',
    testId = null,
    openLinkInNewWindow = false,
    extraUrlParams,
  } = props;

  let finalUrl = link;
  if (extraUrlParams) {
    const urlSearchParams = new URLSearchParams(extraUrlParams);
    finalUrl = `${link}?${urlSearchParams.toString()}`;
  }

  return (
    <>
      <Container
        withIcon={true}
        data-testid={testId}
        data-tip={dataTip}
        data-for={dataFor}
      >
        {sharingInfos !== undefined && (
          <IconContainer>
            <SharingMarker iconSize={16} sharingInfos={sharingInfos} />
          </IconContainer>
        )}
        <CellTitle>{title}</CellTitle>
        {subtitle && <CellSubtitle>{subtitle}</CellSubtitle>}
        {finalUrl && (
          <StyledLink
            to={finalUrl}
            target={openLinkInNewWindow ? '_blank' : '_self'}
            className={showLinkOnRowHover ? 'visible-on-row-hover' : ''}
          >
            <Button
              tooltipId={title}
              tooltip={linkTooltip}
              variant="outline-neutral"
              size="xs"
            >
              {t('common.open')}
            </Button>
          </StyledLink>
        )}
      </Container>
    </>
  );
};
