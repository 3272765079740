import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { ReceivedSharingSummaryByOrganization } from './gql';

export const getColumns = (
  t: TFunction,
): Column<ReceivedSharingSummaryByOrganization>[] => {
  return [
    {
      Header: t('onboarding:sharing.table.fields.organizationName'),
      minWidth: 250,
      maxWidth: 250,
      id: 'organizationName',
      accessor: (
        receivedSharingSummaryByOrganization: ReceivedSharingSummaryByOrganization,
      ) => receivedSharingSummaryByOrganization.organizationName,
    },
    {
      Header: t('onboarding:sharing.table.fields.countSharings'),
      minWidth: 150,
      maxWidth: 150,
      id: 'countSharings',
      accessor: (
        receivedSharingSummaryByOrganization: ReceivedSharingSummaryByOrganization,
      ) => receivedSharingSummaryByOrganization.countSharings,
    },
    {
      Header: t('onboarding:sharing.table.fields.countAssets'),
      minWidth: 150,
      maxWidth: 150,
      id: 'countAssets',
      accessor: (
        receivedSharingSummaryByOrganization: ReceivedSharingSummaryByOrganization,
      ) => receivedSharingSummaryByOrganization.countSharedAssets,
    },
  ];
};
