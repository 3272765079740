import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import type { FetchApi } from '../client';

type SortBy = {
  field: string;
  order: string;
};

type ExportFleetDataParams = {
  since: Date | string;
  until: Date | string;
  assetFilters: AssetFilterGQL;
  sortBy: SortBy;
  fileType: string;
  search: string;
  columns: string[];
};

type ExportDailyUsageHoursParams = {
  since: Date | string;
  until: Date | string;
  fileType: string;
};

export const analyticsApi = (fetchApi: FetchApi) => ({
  // IDLE RATE REPORT
  requestExportIdleRateAssetsPerformance(
    token: string,
    params: any,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/idlerate/assetsperformance/export', { ...params })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },
  requestExportIdleRateEnterprisesPerformance(
    token: string,
    params: any,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/idlerate/enterprisesperformance/export', {
        ...params,
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },
  async requestExportIdleRateAssetList(
    token: string,
    params: any,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/idlerate/assetlist/export', {
        ...params,
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },

  // HOURLY CONSUMPTION REPORT
  requestExportHourlyConsumptionAssetsPerformance(
    token: string,
    params: any,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/hourlyconsumption/assetsperformance/export', {
        ...params,
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },

  requestExportHourlyConsumptionEnterprisesPerformance(
    token: string,
    params: any,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/hourlyConsumption/enterprisesperformance/export', {
        ...params,
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },

  requestExportHourlyConsumptionAssetList(
    token: string,
    params: any,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/hourlyConsumption/assetlist/export', {
        ...params,
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },

  requestExportCarbonEmissionsAssetsPerformance(
    token: string,
    params: any,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/co2emission/assetsperformance/export', {
        ...params,
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },

  requestExportCarbonEmissionsAssetList(
    token: string,
    params: any,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/co2emission/assetlist/export', {
        ...params,
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },

  requestExportUsageHoursAssetsPerformance(
    token: string,
    params: any,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/usagehours/assetsperformance/export', {
        ...params,
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },

  requestExportUsageHoursAssetList(token: string, params: any): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/usagehours/assetlist/export', {
        ...params,
      })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },

  requestExportFaultsDistributed(
    token: string,
    params: Partial<{
      since: Date | string;
      until: Date | string;
      assetFilters: AssetFilterGQL;
      severities: number[];
      search: string;
      columns: string[];
      sortBy: SortBy;
    }> & { fileType: string },
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/faults/export_distributed', params)
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },

  requestExportFaultsGroupByAssetDistributed(
    token: string,
    params: Partial<{
      since: Date | string;
      until: Date | string;
      assetFilters: AssetFilterGQL;
      severities: number[];
      search: string;
      columns: string[];
      sortBy: SortBy;
    }> & { fileType: string },
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/analytics/faults/export_group_by_asset_distributed', params)
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occurred while requesting your export. Please try again later',
        );
      });
  },

  async requestExportCumulativeFleetDataDistributed(
    token: string,
    params: ExportFleetDataParams,
    exportBIMode: boolean,
  ): Promise<void> {
    const url = exportBIMode
      ? '/app/analytics/fleetdata/cumulative/export/bi'
      : '/app/analytics/fleetdata/cumulative/export_distributed';
    try {
      return await fetchApi.setToken(token).post(url, params);
    } catch (errorMessage) {
      throw new Error(
        (typeof errorMessage === 'string' && errorMessage) ||
          'An error occurred while requesting your export. Please try again later',
      );
    }
  },

  async requestExportHistoryFleetDataDistributed(
    token: string,
    params: ExportFleetDataParams,
    exportBIMode: boolean,
  ): Promise<void> {
    const url = exportBIMode
      ? '/app/analytics/fleetdata/history/export/bi'
      : '/app/analytics/fleetdata/history/export_distributed';
    try {
      return await fetchApi.setToken(token).post(url, params);
    } catch (errorMessage) {
      throw new Error(
        (typeof errorMessage === 'string' && errorMessage) ||
          'An error occurred while requesting your export. Please try again later',
      );
    }
  },

  async requestExportDailyUsageHours(
    token: string,
    params: ExportDailyUsageHoursParams,
  ): Promise<void> {
    try {
      return await fetchApi
        .setToken(token)
        .post('/app/analytics/daily_usage_hours/export', params);
    } catch (errorMessage) {
      throw new Error(
        (typeof errorMessage === 'string' && errorMessage) ||
          'An error occurred while requesting your export. Please try again later',
      );
    }
  },
});
