import colors from 'constants/colors';

export type StarIconProps = {
  size?: number;
  color?: string;
};

export const StarIcon = ({
  size = 16,
  color = colors.gray500,
}: StarIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.76066 6.04055C1.76075 6.04063 1.76085 6.04072 1.76094 6.0408L1.76066 6.04055L1.50948 6.31927L1.76066 6.04055ZM12.5803 13.9497L12.5803 13.9496L11.6779 9.69503L14.9088 6.7834C15.2624 6.46474 15.0717 5.87783 14.5983 5.82787L10.2731 5.37137L8.50235 1.39889C8.30855 0.96412 7.69145 0.964117 7.49765 1.39888L5.72691 5.37137L1.40168 5.82787C0.928298 5.87783 0.737601 6.46474 1.0912 6.7834L4.32207 9.69503L3.41966 13.9496C3.32089 14.4153 3.82014 14.778 4.23248 14.5402L3.98267 14.1071L4.23248 14.5402L8 12.3672L11.7675 14.5402L12.0173 14.1071L11.7675 14.5402C12.1799 14.778 12.6791 14.4153 12.5803 13.9497Z"
      stroke={color}
    />
  </svg>
);
