import { CloseButton } from 'modules/common-ui/components/CloseButton';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { type CloseButtonProps, toast } from 'react-toastify';
import {
  NotificationCloseButtonWrapper,
  NotificationContainer,
  NotificationContent,
  NotificationCoreContent,
  NotificationTimeAgo,
  NotificationTitle,
} from './notification.css';

type NotificationProps = {
  id: number;
  title: React.ReactNode;
  content: React.ReactNode;
  onClick?: () => void;
};

const CustomCloseButton = ({
  closeToast,
}: Pick<CloseButtonProps, 'closeToast'>) => (
  <NotificationCloseButtonWrapper onClick={closeToast}>
    <CloseButton onClick={closeToast} />
  </NotificationCloseButtonWrapper>
);

const CustomComponent: React.FC<{
  title: React.ReactNode;
  content: React.ReactNode;
}> = ({ title, content }) => {
  const createdAt = useRef(new Date());
  const [timeAgo, setTimeAgo] = useState<string>(
    moment(createdAt.current).fromNow(),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(moment(createdAt.current).fromNow());
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <NotificationContainer>
      <NotificationCoreContent>
        <NotificationTitle>{title}</NotificationTitle>
        <NotificationContent>{content}</NotificationContent>
      </NotificationCoreContent>
      <NotificationTimeAgo>{timeAgo}</NotificationTimeAgo>
    </NotificationContainer>
  );
};

export const useDisplayNotification = () => {
  const displayNotification = useCallback(
    ({ id, title, content, onClick }: NotificationProps) => {
      // don't display the notification if already visible
      if (toast.isActive(id)) {
        return;
      }

      return toast(<CustomComponent content={content} title={title} />, {
        autoClose: false,
        closeOnClick: true,
        onClick,
        className: 'hiboo-notification',
        closeButton: CustomCloseButton,
        toastId: id,
      });
    },
    [],
  );

  return displayNotification;
};
