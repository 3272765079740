import { primaryColors } from 'constants/colors';

export type ArrowUpIconFreemiumProps = {
  color?: string;
  size?: number;
};

export const ArrowUpIconFreemium = ({
  size = 16,
  color,
}: ArrowUpIconFreemiumProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    fill={color || primaryColors.main}
    height={size}
    width={size}
    style={{
      marginBottom: '-2px',
      marginRight: '6px',
      marginLeft: '6px',
      border: `1px solid ${color || primaryColors.main}`,
      borderRadius: '50%',
    }}
    viewBox="0 0 40 40"
  >
    <g>
      <path d="m6.6 20l13.4-13.4 13.4 13.4-2.5 2.3-9.3-9.3v20.4h-3.2v-20.4l-9.4 9.3z" />
    </g>
  </svg>
);
