import colors from 'constants/colors';

export type HelpIconProps = {
  size?: number;
  color?: string;
};

export const HelpIcon = ({
  size = 16,
  color = colors.gray500,
}: HelpIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={'0 0 16 16'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7" stroke={color} />
    <path
      d="M7.31473 9.41492C7.32213 8.97472 7.37207 8.627 7.46455 8.37176C7.55703 8.11652 7.74568 7.83354 8.03052 7.52281L8.7574 6.77374C9.06813 6.42232 9.22349 6.04501 9.22349 5.6418C9.22349 5.25339 9.12176 4.95006 8.91831 4.73181C8.71486 4.50986 8.41893 4.39889 8.03052 4.39889C7.65321 4.39889 7.34988 4.49877 7.12053 4.69852C6.89118 4.89827 6.77651 5.16646 6.77651 5.50308H5.75C5.7574 4.90382 5.9701 4.42109 6.3881 4.05487C6.8098 3.68496 7.35728 3.5 8.03052 3.5C8.72965 3.5 9.27343 3.68866 9.66184 4.06597C10.0539 4.43958 10.25 4.95376 10.25 5.60851C10.25 6.25586 9.95037 6.89396 9.35111 7.52281L8.7463 8.12207C8.47626 8.4217 8.34125 8.85265 8.34125 9.41492H7.31473ZM7.27035 11.1739C7.27035 11.0074 7.32028 10.8687 7.42016 10.7577C7.52374 10.643 7.6754 10.5857 7.87515 10.5857C8.07491 10.5857 8.22657 10.643 8.33015 10.7577C8.43372 10.8687 8.48551 11.0074 8.48551 11.1739C8.48551 11.3403 8.43372 11.479 8.33015 11.59C8.22657 11.6973 8.07491 11.7509 7.87515 11.7509C7.6754 11.7509 7.52374 11.6973 7.42016 11.59C7.32028 11.479 7.27035 11.3403 7.27035 11.1739Z"
      fill={color}
    />
  </svg>
);
